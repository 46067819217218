"use strict";

var app = angular.module("analytics");

app.controller("PermissionsPanelCtrl", [
  "$scope",
  "permissionScope",
  "productFactory",
  "categoryFactory",
  "organizationFactory",
  "reportFactory",
  "permissionsFactory",
  "clientsFactory",
  "roleMappingFactory",
  "roleFactory",
  "genericFactory",
  "localizationFactory",
  function (
    $scope,
    permissionScope,
    productFactory,
    categoryFactory,
    organizationFactory,
    reportFactory,
    permissionsFactory,
    clientsFactory,
    roleMappingFactory,
    roleFactory,
    genericFactory,
    localizationFactory
  ) {
    var self = this;

    // Translate text
    $scope.localizationFactory = localizationFactory;

    $scope.selected = permissionsFactory.getSelected();
    var parentScope = permissionsFactory.getParentScope();
    $scope.permissionScope = permissionScope;

    $scope.isAdmin = clientsFactory.isAdmin();
    $scope.isOrgAdmin = clientsFactory.isOrgAdmin();
    $scope.isCPAdmin = clientsFactory.isCPAdmin();
    $scope.isVFXEduAdmin = clientsFactory.isVFXEduAdmin();

    $scope.searchTerm = {};
    $scope.mappingSearchTerm = {};
    $scope.new = {};
    $scope.selectedUser = null;

    $scope.selectedOrganization = {};
    $scope.schoolYears = [];

    // LEAN GAME
    $scope.leanGameTeams = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

    $scope.categoryData = categoryFactory.data;
    $scope.reportData = reportFactory.data;
    $scope.userReports = parentScope.userReports;
    $scope.modules = parentScope.moduleData;
    $scope.userModules = parentScope.userModules;
    if ($scope.userModules == null) {
      $scope.userModules = [];
    }
    if (parentScope.selectedRow && parentScope.selectedRow.userModulesPermissions != null) {
      $scope.userModulesPermissions = parentScope.selectedRow.userModulesPermissions;
    } else {
      $scope.userModulesPermissions = {};
    }

    $scope.modStructure = parentScope.getModStructure();
    $scope.new.email = "";
    $scope.new.institutionalMail = "";
    $scope.new.password = "";
    $scope.new.name = "";
    $scope.new.surname = "";
    $scope.new.organization = "";
    $scope.new.profile = "";

    $scope.firstSelectedYear;
    $scope.firstSelectedSchoolYear;
    $scope.firstSelectedCluster;
    $scope.firstSelectedSchool;

    //CP Fields loaded flags
    $scope.loadedClusters = false;
    $scope.loadedSchools = false;
    $scope.loadedCourseTypes = false;
    $scope.loadedCourses = false;
    $scope.loadedEducationLevels = false;
    $scope.loadedSchoolYears = false;
    $scope.loadedModules = false;

    //VFX Edu loaded flags
    $scope.loadedVFXClusters = false;

    //VFX IPSS Companies loaded flags
    $scope.loadedVFXIPSSCompanies = false;

    //CMS Edu loaded flags
    $scope.loadedCMSClusters = false;

    $scope.mode = parentScope.mode;

    $scope.clearSearchTerm = function (field) {
      $scope.searchTerm[field] = "";
    };

    $scope.clearMappingSearchTerm = function (field) {
      $scope.mappingSearchTerm[field] = "";
    };

    if (clientsFactory.isAdmin()) {
      $scope.organizationData = organizationFactory.data;
    } else if (clientsFactory.isOrgAdmin() || clientsFactory.isCPAdmin() || clientsFactory.isVFXEduAdmin()) {
      $scope.organizationData = organizationFactory.data.filter(
        (o) => o.id == clientsFactory.getUser().user.organization
      );
    }

    productFactory.getAll().then(function (products) {
      $scope.productData = products;
      if ($scope.isCPAdmin) {
        $scope.filterCPModules();
      } else if ($scope.isOrgAdmin) {
        $scope.filterOrgModules();
      } else if ($scope.isVFXEduAdmin) {
        $scope.filterVFXEduModules();
      }
    });

    $scope.getProName = function (proId) {
      if ($scope.productData) {
        let filteredPro;
        if (Array.isArray(proId)) {
          filteredPro = $scope.productData.filter((e) => proId.indexOf(e.id) != -1);
        } else {
          filteredPro = $scope.productData.filter((e) => e.id === proId);
        }

        if (filteredPro.length) {
          return filteredPro[0].product;
        } else {
          return "";
        }
      }
    };

    $scope.getCatName = function (catId) {
      var filteredCat = $scope.categoryData.filter((e) => e.id === catId);

      if (filteredCat.length) {
        return filteredCat[0].name;
      } else {
        return "";
      }
    };

    $scope.filterCPModules = function () {
      if ($scope.isCPAdmin) {
        for (const product in $scope.modStructure) {
          if ($scope.modStructure.hasOwnProperty(product)) {
            if ($scope.getProName(product) != "Plano de Turma" && $scope.getProName(product) != "Documentos") {
              delete $scope.modStructure[product];
            }
          }
        }
      }
    };

    $scope.filterOrgModules = function () {
      if (
        $scope.isOrgAdmin &&
        $scope.organizationData[0].orgProducts &&
        Array.isArray($scope.organizationData[0].orgProducts)
      ) {
        for (const product in $scope.modStructure) {
          if ($scope.modStructure.hasOwnProperty(product)) {
            if ($scope.organizationData[0].orgProducts.indexOf(product) == -1) {
              delete $scope.modStructure[product];
            }
          }
        }
      } else {
        $scope.modStructure = {};
      }
    };

    $scope.filterVFXEduModules = function () {
      if ($scope.isVFXEduAdmin) {
        for (const product in $scope.modStructure) {
          if ($scope.modStructure.hasOwnProperty(product)) {
            if (
              $scope.getProName(product) != "ETI" &&
              $scope.getProName(product) != "ASE" &&
              $scope.getProName(product) != "Transportes Escolares CMVFX"
            ) {
              delete $scope.modStructure[product];
            }
          }
        }
      }
    };

    $scope.checkModules = function (product, category) {
      for (const cat in $scope.modStructure[product]) {
        if ($scope.modStructure[product].hasOwnProperty(cat)) {
          const modules = $scope.modStructure[product][cat];
          modules.forEach((module) => {
            if (category != null) {
              if (module.value != null && module.category == category) {
                if ($scope.userModules[module.value] == null || $scope.userModules[module.value] == false) {
                  $scope.userModules[module.value] = true;
                }
              }
            } else {
              if (module.value != null) {
                if ($scope.userModules[module.value] == null || $scope.userModules[module.value] == false) {
                  $scope.userModules[module.value] = true;
                }
              }
            }
          });
        }
      }
    };

    if (parentScope.selectedRow) {
      clientsFactory.getByProperty("email", parentScope.selectedRow.userMail).then((selectedUser) => {
        if (selectedUser.length > 1) {
          selectedUser = selectedUser.filter((user) => user.email == parentScope.selectedRow.userMail);
        }
        if (selectedUser.length > 0) {
          $scope.selectedUser = selectedUser[0];
          var currUser = selectedUser[0];
          $scope.new.language = currUser.language;
          $scope.new.name = currUser.name;
          $scope.new.extendedName = currUser.extendedName;
          $scope.new.surname = currUser.surname;
          $scope.new.password = currUser.password;
          $scope.new.organization = currUser.organization;
          $scope.orgChanged("firstLoad");
          $scope.new.email = currUser.email;
          $scope.new.institutionalMail = currUser.institutionalMail;
          $scope.new.profile = currUser.profile;
          //Class Plan fields
          $scope.new.year = currUser.year;
          $scope.new.civilYear = currUser.civilYear;
          $scope.new.courseType = currUser.courseType;
          $scope.new.course = currUser.course;
          $scope.new.schoolYear = currUser.schoolYear;
          $scope.schoolYearChanged();
          $scope.new.cluster = currUser.cluster;
          $scope.new.school = currUser.school;
          $scope.new.subject = currUser.subject;
          $scope.new.module = currUser.module;
          $scope.getClasses();
          $scope.new.class = currUser.class;
          $scope.new.classDirector = currUser.classDirector;
          $scope.new.isKSTK = currUser.isKSTK;
          $scope.new.hasToResetPassword = currUser.hasToResetPassword;
          /* $scope.new.subModClassMapping = currUser.subModClassMapping; */

          $scope.firstSelectedYear = $scope.new.year;
          $scope.firstSelectedCourseType = $scope.new.courseType;
          $scope.firstSelectedCourse = $scope.new.course;
          $scope.firstSelectedSchoolYear = $scope.new.schoolYear;
          $scope.firstSelectedCluster = $scope.new.cluster;
          $scope.firstSelectedSchool = $scope.new.school;

          //Lean game fields
          $scope.new.game = currUser.game;
          $scope.new.role = currUser.role;
          $scope.new.team = currUser.team;
        }
      });
    }

    $scope.orgChanged = function (firstLoadFlag) {
      if (firstLoadFlag != "firstLoad") {
        reportFactory.getByOrganizationId($scope.new.organization).then(function (reportData) {
          $scope.userReports = {};
          reportData.forEach((r) => ($scope.userReports[r.id] = r));
        });
      }
      if ($scope.new.organization != null && $scope.new.organization != "") {
        $scope.selectedOrganization = organizationFactory.data.filter((o) => o.id == $scope.new.organization)[0];
        // LEAN GAME
        if (
          ($scope.isAdmin || $scope.isOrgAdmin) &&
          $scope.selectedOrganization.orgType == "Business" &&
          $scope.selectedOrganization.name == "Lean Game"
        ) {
          genericFactory.setRouteName("Lean_Game_Roles");
          genericFactory.getByProperty("organization", $scope.new.organization).then(function (data) {
            $scope.leanGameRoles = data;
          });
          genericFactory.setRouteName("Lean_Game_Configurations");
          genericFactory.getByProperty("organization", $scope.new.organization).then(function (data) {
            $scope.leanGameConfigurations = data;
          });
        }
        // CLASS PLAN
        genericFactory.setRouteName("Class_Plan_Clusters");
        genericFactory.getByProperty("organization", $scope.new.organization).then(function (data) {
          $scope.loadedClusters = true;
          $scope.clusters = data;
        });
        genericFactory.setRouteName("Class_Plan_Course_Types");
        genericFactory.getByProperty("organization", $scope.new.organization).then(function (data) {
          $scope.loadedCourseTypes = true;
          $scope.courseTypes = data;
        });
        genericFactory.setRouteName("Class_Plan_Courses");
        genericFactory.getByProperty("organization", $scope.new.organization).then(function (data) {
          $scope.loadedCourses = true;
          $scope.courses = data;
        });
        genericFactory.setRouteName("Class_Plan_Education_Levels");
        genericFactory.getByProperty("organization", $scope.new.organization).then(function (data) {
          $scope.loadedEducationLevels = true;
          $scope.educationLevels = data;
        });
        genericFactory.setRouteName("Class_Plan_School_Years");
        genericFactory.getByProperty("organization", $scope.new.organization).then(function (data) {
          $scope.loadedSchoolYears = true;
          $scope.schoolYears = data;
        });
        genericFactory.setRouteName("Class_Plan_Prof_Modules");
        genericFactory.getByProperty("organization", $scope.new.organization).then(function (data) {
          $scope.loadedModules = true;
          $scope.profModules = data;
        });
        /* if (($scope.isAdmin || $scope.isVFXEduAdmin) && $scope.selectedOrganization.orgType == "Education") {
          genericFactory.setRouteName("Clusters");
          genericFactory.getByProperty("organization", $scope.new.organization).then(function (data) {
            $scope.loadedVFXClusters = true;
            $scope.vfxClusters = data;
          });
        } */

        if (($scope.isAdmin || $scope.isOrgAdmin) && $scope.selectedOrganization.id == "58d7fe2f62225f05cbf58c3a") {
          genericFactory.setRouteName("VFX_Meal_Companies");
          genericFactory.getByProperty("organization", $scope.new.organization).then(function (data) {
            $scope.loadedVFXIPSSCompanies = true;
            $scope.vfxIPSSCompanies = data;
          });
        }
        if ($scope.isAdmin && $scope.selectedOrganization.orgType == "Education") {
          genericFactory.setRouteName("Transp_CMS_Clusters");
          genericFactory.getByProperty("organization", $scope.new.organization).then(function (data) {
            $scope.loadedCMSClusters = true;
            $scope.cmsClusters = data;
          });
        }
      }
    };

    $scope.courseTypeChanged = function () {
      if ($scope.selectedOrganization.orgType == "Education") {
        if ($scope.new.courseType != null && $scope.new.courseType != "" && $scope.new.courseType != []) {
          if (JSON.stringify($scope.firstSelectedCourseType) != JSON.stringify($scope.new.courseType)) {
            genericFactory.setRouteName("Class_Plan_Courses");
            $scope.course = null;
            $scope.new.course = null;
            genericFactory.getByProperty("organization", $scope.new.organization).then(function (data) {
              let filteredCourses = data.filter((o) => $scope.new.courseType.indexOf(o.courseType) != -1);
              $scope.courses = filteredCourses;
            });
          }
        } else {
          $scope.course = null;
          $scope.new.course = null;
        }
      }
    };

    /* $scope.getCourses = function () {
      if ($scope.new.courseType != '' && $scope.new.courseType != undefined && $scope.new.courseType != []) {
        if (JSON.stringify($scope.firstSelectedCourseType) != JSON.stringify($scope.new.courseType)) {
          genericFactory.setRouteName("Class_Plan_Courses");
          let map = {};
          map.courseType = $scope.new.courseType;
          $scope.courses = null;
          $scope.new.course = null;
          genericFactory.getByProperties(map, $scope.new.organization).then(function (data) {
            $scope.courses = data;
          })
        }
      } else {
        $scope.courses = null;
        $scope.new.course = null;
      }
    }; */

    $scope.schoolYearChanged = function () {
      if ($scope.new.schoolYear != null && $scope.new.schoolYear != "" && $scope.new.schoolYear != []) {
        if (JSON.stringify($scope.firstSelectedSchoolYear) != JSON.stringify($scope.new.schoolYear)) {
          genericFactory.setRouteName("Class_Plan_Subjects");
          $scope.subjects = null;
          $scope.new.subject = null;
          $scope.new.module = null;
          genericFactory.getByProperty("organization", $scope.new.organization).then(function (data) {
            let filteredSubjects = data.filter((o) => $scope.new.schoolYear.indexOf(o.schoolYear) != -1);
            $scope.subjects = filteredSubjects;
          });
        }
      } else {
        $scope.subjects = null;
        $scope.new.subject = null;
        $scope.new.module = null;
      }
    };

    $scope.getClasses = function () {
      if (
        $scope.new.year != "" &&
        $scope.new.schoolYear != "" &&
        $scope.new.cluster != "" &&
        $scope.new.school != "" &&
        $scope.new.year != undefined &&
        $scope.new.schoolYear != undefined &&
        $scope.new.cluster != undefined &&
        $scope.new.school != undefined &&
        $scope.new.year != [] &&
        $scope.new.schoolYear != [] &&
        $scope.new.cluster != [] &&
        $scope.new.school != []
      ) {
        if (
          JSON.stringify($scope.firstSelectedSchoolYear) != JSON.stringify($scope.new.schoolYear) ||
          JSON.stringify($scope.firstSelectedYear) != JSON.stringify($scope.new.year) ||
          JSON.stringify($scope.firstSelectedCluster) != JSON.stringify($scope.new.cluster) ||
          JSON.stringify($scope.firstSelectedSchool) != JSON.stringify($scope.new.school)
        ) {
          genericFactory.setRouteName("Class_Plan_Classes");
          let map = {};
          map.year = $scope.new.year;
          map.schoolYear = $scope.new.schoolYear;
          map.cluster = $scope.new.cluster;
          map.school = $scope.new.school;
          $scope.classes = null;
          $scope.new.class = null;
          genericFactory.getByProperties(map, $scope.new.organization).then(function (data) {
            $scope.classes = data;
          });
        }
      } else {
        $scope.classes = null;
        $scope.new.class = null;
        $scope.new.classDirector = null;
        $scope.new.classDirectorSchoolYears = null;
      }
    };

    $scope.clusterChanged = function () {
      if ($scope.new.cluster != null && $scope.new.cluster != "") {
        let map = {};
        map.cluster = $scope.new.cluster;
        genericFactory.setRouteName("Class_Plan_Schools");
        genericFactory.getByProperties(map, $scope.new.organization).then(function (data) {
          $scope.loadedSchools = true;
          $scope.schools = data;
        });
      } else {
        $scope.schools = null;
        $scope.new.school = null;
      }
    };

    $scope.cleanSelectedClasses = function (selectedClasses, possibleClasses) {
      if (selectedClasses != null) {
        $scope.new.class = selectedClasses.filter(
          (selectedClass) => JSON.stringify(possibleClasses).match(selectedClass) != null
        );
      }
    };

    $scope.cleanSelectedSubjects = function (selectedSubjects, possibleSubjects) {
      if (selectedSubjects != null) {
        $scope.new.subject = selectedSubjects.filter(
          (selectedSubject) => JSON.stringify(possibleSubjects).match(selectedSubject) != null
        );
      }
    };

    $scope.cleanVFXIPSSFields = function () {
      let ipssFields = ["year", "cluster", "school", "educationLevel", "company"];
      ipssFields.forEach((cpField) => {
        $scope.new[cpField] = null;
      });
    };

    $scope.cleanCPFields = function () {
      let cpFields = [
        "year",
        "cluster",
        "school",
        "courseType",
        "course",
        "schoolYear",
        "subject",
        "module",
        "class",
        "classDirector",
        "classDirectorSchoolYears",
      ];
      cpFields.forEach((cpField) => {
        $scope.new[cpField] = null;
      });
    };

    $scope.cleanOrgAdminFields = function () {
      let orgAdminFields = ["year", "cluster", "school"];
      orgAdminFields.forEach((orgAdminField) => {
        $scope.new[orgAdminField] = null;
      });
    };

    $scope.cleanVfxEduFields = function () {
      let vfxEduFields = ["year", "civilYear", "cluster"];
      vfxEduFields.forEach((vfxEduField) => {
        $scope.new[vfxEduField] = null;
      });
    };

    $scope.subModClassMappingSchoolYearChanged = function (index) {
      delete $scope.new.subModClassMapping[index].subject;
      delete $scope.new.subModClassMapping[index].module;
      delete $scope.new.subModClassMapping[index].class;
    };

    $scope.getReportName = function (repId) {
      var filteredRep = $scope.reportData.filter((e) => e.id === repId);

      if (filteredRep.length) {
        return filteredRep[0].name;
      } else {
        //remove it from the user reports array and expect that the user saves this later
        delete $scope.userReports[repId];
        return;
      }
    };

    $scope.save = function () {
      if ($scope.new.organization == null || $scope.new.organization == "") {
        parentScope.$parent.alertEmptyRequiredFields(["Organização"]);
        return;
      }

      var currReps = [];

      for (var rep in $scope.userReports) {
        if ($scope.userReports[rep]) {
          currReps.push(rep);
        }
      }

      var currModules = [];

      for (var mod in $scope.userModules) {
        if ($scope.userModules[mod]) {
          var currMod = $scope.modules.filter((el) => el.value === mod);
          if (currMod.length > 0) {
            currModules.push(currMod[0].value);
          }
        }
      }

      //Validate CP permissions
      if ($scope.isCPAdmin && $scope.selectedOrganization.orgType == "Education") {
        //Check if a CP profile is selected
        if ($scope.new.profile == null || $scope.new.profile == "") {
          parentScope.$parent.alertPermissionsCPNoProfile();
          return;
        }

        //Validade CP fields by selected profile
        if (!$scope.validateCPFieldsByProfile($scope.new.profile)) {
          return;
        }

        //No module selected
        if (currModules.length == 0 && $scope.new.profile != "userAnalytics") {
          parentScope.$parent.alertPermissionsCPNoModuleSelected();
          return;
        }
      }

      //Validade sub mod class mapping if the cp class plan fields and the subModClassMapping is not empty
      /* if (!$scope.isAdmin && $scope.new.subModClassMapping != null && Array.isArray($scope.new.subModClassMapping) && $scope.new.subModClassMapping.length > 0 && $scope.new.class != null && Array.isArray($scope.new.class) && $scope.new.class.length > 0 && (($scope.new.subject != null && Array.isArray($scope.new.subject) && $scope.new.subject.length > 0) || ($scope.new.module != null && Array.isArray($scope.new.module) && $scope.new.module.length > 0))) {
        if (!$scope.validateSubModClassMapping()) {
          return;
        }
      } */

      //Insert class director school years
      if (
        $scope.new.classDirector != null &&
        $scope.new.classDirector.length > 0 &&
        $scope.classes != null &&
        $scope.classes.length > 0
      ) {
        $scope.new.classDirector.forEach((classDir) => {
          let classe = $scope.classes.filter((el) => el.id === classDir)[0];
          if ($scope.new.classDirectorSchoolYears == null) {
            $scope.new.classDirectorSchoolYears = [];
          }
          if (
            classe.schoolYear != null &&
            classe.schoolYear.length > 0 &&
            $scope.new.classDirectorSchoolYears.indexOf(classe.schoolYear) == -1
          ) {
            $scope.new.classDirectorSchoolYears = $scope.new.classDirectorSchoolYears.concat(classe.schoolYear);
          }
        });
        if ($scope.new.classDirectorSchoolYears != null && $scope.new.classDirectorSchoolYears.length > 0) {
          $scope.new.classDirectorSchoolYears = $scope.new.classDirectorSchoolYears.filter(
            (item, index) => $scope.new.classDirectorSchoolYears.indexOf(item) == index
          );
        }
      }

      parentScope.$parent.showLoader();

      if ($scope.selected && $scope.selected.id) {
        //modify
        $scope.selected.reports = currReps;
        $scope.selected.modules = currModules;

        let userObj = $scope.selectedUser;

        if ($scope.new.extendedName != null && $scope.new.extendedName.indexOf($scope.new.name) == -1) {
          userObj.extendedName = null;
        }
        userObj.name = $scope.new.name;
        userObj.language = $scope.new.language;
        userObj.surname = $scope.new.surname;
        userObj.password = $scope.new.password;
        userObj.organization = $scope.new.organization;
        userObj.email = $scope.new.email;
        userObj.institutionalMail = $scope.new.institutionalMail ? $scope.new.institutionalMail : null;
        userObj.profile = $scope.new.profile;
        //Class Plan fields
        userObj.year = checkIfEmptyArray($scope.new.year);
        userObj.civilYear = checkIfEmptyArray($scope.new.civilYear);
        userObj.cluster = checkIfEmptyArray($scope.new.cluster);
        userObj.school = checkIfEmptyArray($scope.new.school);
        userObj.courseType = checkIfEmptyArray($scope.new.courseType);
        userObj.course = checkIfEmptyArray($scope.new.course);
        userObj.schoolYear = checkIfEmptyArray($scope.new.schoolYear);
        userObj.subject = checkIfEmptyArray($scope.new.subject);
        userObj.module = checkIfEmptyArray($scope.new.module);
        userObj.class = checkIfEmptyArray($scope.new.class);
        userObj.classDirector = checkIfEmptyArray($scope.new.classDirector);
        userObj.classDirectorSchoolYears = checkIfEmptyArray($scope.new.classDirectorSchoolYears);
        userObj.isKSTK = $scope.new.isKSTK;
        userObj.hasToResetPassword = $scope.new.hasToResetPassword;
        /* userObj.subModClassMapping = $scope.new.subModClassMapping; */
        //Lean Game fields
        userObj.game = $scope.new.game;
        userObj.role = $scope.new.role;
        userObj.team = $scope.new.team;

        function checkIfEmptyArray(fieldValue) {
          if (fieldValue != null && Array.isArray(fieldValue) && fieldValue.length > 0) {
            return fieldValue;
          } else {
            return null;
          }
        }

        clientsFactory
          .modify($scope.selectedUser.id, userObj)
          .then(() => {
            // User module permissions
            $scope.selected.userModulesPermissions = $scope.userModulesPermissions;
            permissionsFactory.modify($scope.selected.id, $scope.selected).then(function (modData) {
              parentScope.$parent.hideLoader();
              parentScope.$parent.successfulSave();
              parentScope.closePanel();
            });
          })
          .catch(function () {
            parentScope.$parent.alertPermissionsModifyError();
            parentScope.$parent.hideLoader();
          });

        if (
          userObj.profile === "orgAdmin" ||
          userObj.profile === "cpAdmin" ||
          userObj.profile === "userAnalytics" ||
          userObj.profile === "cpClassDirector" ||
          userObj.profile === "cpSupervisor" ||
          userObj.profile === "vfxEduAdmin"
        ) {
          roleFactory.getAll().then(function (roles) {
            let role = roles.filter((e) => e.name === userObj.profile);

            if (role.length) {
              role = role[0];

              roleMappingFactory.getByProp("principalId", userObj.id).then(function (mappings) {
                let roleMappingToCreateOrModify = {
                  principalType: "USER",
                  principalId: userObj.id,
                  roleId: role.id,
                };
                if (!mappings.length) {
                  roleMappingFactory.create(roleMappingToCreateOrModify);
                } else {
                  roleMappingFactory.modify(mappings[0].id, roleMappingToCreateOrModify);
                }
              });
            }
          });
        } else {
          roleFactory.getAll().then(function (roles) {
            let role = roles.filter(
              (e) =>
                e.name === "orgAdmin" ||
                e.name === "cpAdmin" ||
                e.name === "userAnalytics" ||
                e.name === "cpClassDirector" ||
                e.name === "cpSupervisor" ||
                e.name === "vfxEduAdmin"
            );

            if (role.length) {
              role = role[0];

              roleMappingFactory.getByProp("roleId", role.id).then(function (mappings) {
                mappings = mappings.filter((e) => e.principalId === userObj.id);

                if (mappings.length) {
                  roleMappingFactory.delete(mappings[0].id);
                }
              });
            }
          });
        }
      } else {
        //create
        self.userReports = [];

        for (var r in $scope.userReports) {
          if ($scope.userReports[r] === true) {
            self.userReports.push(r);
          }
        }

        if (self.userReports.length === 0) {
          self.userReports.push("");
        }

        self.userModules = [];

        for (var m in $scope.userModules) {
          if ($scope.userModules[m] === true) {
            var currMod = $scope.modules.filter((el) => el.value === m);
            if (currMod.length > 0) {
              self.userModules.push(currMod[0].value);
            }
          }
        }

        clientsFactory
          .create({
            name: $scope.new.name,
            surname: $scope.new.surname,
            organization: $scope.new.organization,
            email: $scope.new.email,
            institutionalMail: $scope.new.institutionalMail,
            password: $scope.new.password,
            language: $scope.new.language,
            profile: $scope.new.profile == "" || $scope.new.profile == null ? "user" : $scope.new.profile,
            //Class Plan fields
            year: $scope.new.year,
            civilYear: $scope.new.civilYear,
            schoolYear: $scope.new.schoolYear,
            cluster: $scope.new.cluster,
            school: $scope.new.school,
            courseType: $scope.new.courseType,
            course: $scope.new.course,
            subject: $scope.new.subject,
            module: $scope.new.module,
            class: $scope.new.class,
            classDirector: $scope.new.classDirector,
            classDirectorSchoolYears: $scope.new.classDirectorSchoolYears,
            isKSTK: $scope.new.isKSTK,
            hasToResetPassword: true,
            /* subModClassMapping: $scope.new.subModClassMapping, */
            //Lean Game fields
            game: $scope.new.game,
            role: $scope.new.role,
            team: $scope.new.team,
          })
          .then(function (createdClient) {
            if (
              createdClient.profile === "orgAdmin" ||
              createdClient.profile === "cpAdmin" ||
              createdClient.profile === "userAnalytics" ||
              createdClient.profile === "cpClassDirector" ||
              createdClient.profile === "cpSupervisor" ||
              createdClient.profile === "vfxEduAdmin"
            ) {
              roleFactory.getAll().then(function (roles) {
                let role = roles.filter((e) => e.name === createdClient.profile);

                if (role.length) {
                  role = role[0];

                  roleMappingFactory.create({
                    principalType: "USER",
                    principalId: createdClient.id,
                    roleId: role.id,
                  });
                }
              });
            }

            permissionsFactory
              .create({
                user: createdClient.id,
                reports: self.userReports,
                modules: self.userModules,
                userModulesPermissions: $scope.userModulesPermissions,
              })
              .then(function (createdData) {
                parentScope.closePanel();
                parentScope.$parent.hideLoader();
              })
              .catch(function () {
                alert(
                  "Please choose at least one Report for the user " + createdClient.name + " " + createdClient.surname
                );
                clientsFactory.delete(createdClient.id);
              });
          })
          .catch(function () {
            parentScope.$parent.alertPermissionsCreateError();
            parentScope.$parent.hideLoader();
          });
      }
    };

    $scope.selectAllPermissions = function selectAllPermissions() {
      for (var rep in $scope.userReports) {
        $scope.userReports[rep] = true;
      }
    };

    $scope.selectNoPermissions = function selectNoPermissions() {
      for (var rep in $scope.userReports) {
        $scope.userReports[rep] = false;
      }
    };

    $scope.selectAllModules = function selectAllModules() {
      $scope.modules.forEach((el) => {
        $scope.userModules[el.value] = true;
      });
    };

    $scope.selectNoModules = function selectNoModules() {
      $scope.modules.forEach((el) => {
        $scope.userModules[el.value] = false;
      });
      $scope.userModulesPermissions = {};
    };

    //Parse CP fields

    $scope.parseCourseType = function parseCourseType(courseType) {
      if (courseType != null && courseType != "" && $scope.courseTypes != null && $scope.loadedCourseTypes) {
        let filteredCourseType = $scope.courseTypes.filter((cType) => cType.id == courseType)[0];
        if (
          filteredCourseType != null &&
          filteredCourseType.courseType != null &&
          filteredCourseType.courseType != ""
        ) {
          return filteredCourseType.courseType;
        } else {
          return "(vazio)";
        }
      }
    };

    $scope.parseCourse = function parseCourse(course) {
      if (course != null && course != "" && $scope.courses != null && $scope.loadedCourses) {
        let filteredCourse = $scope.courses.filter((cType) => cType.id == course)[0];
        if (filteredCourse != null && filteredCourse.course != null && filteredCourse.course != "") {
          return filteredCourse.course;
        } else {
          return "(vazio)";
        }
      }
    };

    $scope.parseSchoolYear = function parseSchoolYear(schoolYear) {
      if (schoolYear != null && schoolYear != "" && $scope.schoolYears != null && $scope.loadedSchoolYears) {
        if (Array.isArray(schoolYear) && schoolYear.length > 1) {
          let parsedSchoolYears = [];
          schoolYear.forEach((schoYear) => {
            let parsedSY = $scope.schoolYears.filter((sY) => sY.id == schoYear)[0].schoolYear;
            if (parsedSY != null) {
              parsedSchoolYears.push(parsedSY);
            }
          });
          return parsedSchoolYears.sort().toString().replace(/,/g, ", ");
        } else {
          return $scope.schoolYears.filter((sY) => sY.id == schoolYear)[0].schoolYear;
        }
      }
    };

    $scope.parseCluster = function parseCluster(cluster) {
      if (cluster != null && cluster != "" && $scope.clusters != null && $scope.loadedClusters) {
        return $scope.clusters.filter((s) => s.id == cluster)[0].cluster;
      }
    };

    $scope.parseSchool = function parseSchool(school) {
      if (school != null && school != "" && $scope.schools != null && $scope.loadedSchools) {
        return $scope.schools.filter((s) => s.id == school)[0].school;
      }
    };

    $scope.changedCPProfile = function changedCPProfile(educationType) {
      /* if (educationType == "mainstreamEduOpModules") {
        $scope.modules.forEach(el => {
          if (($scope.getProName(el.product) == "Plano de Turma" && (el.name == "AFC Articulação Horizontal" || el.name == "AFC Articulação Vertical" || el.name == "Avaliação" || el.name == "Caracterização de Turma" || el.name == "Grelha de Critérios de Avaliação" || el.name == "Grelha de Medidas de Suporte" || el.name == "Indisciplina" || el.name == "Instrumentos de Avaliação")) || el.name == "Documentos") {
            $scope.userModules[el.value] = true;
          } else {
            $scope.userModules[el.value] = false;
          }
        });
      } else if (educationType == "profEduOpModules") {
        $scope.modules.forEach(el => {
          if (($scope.getProName(el.product) == "Plano de Turma" && (el.name == "AFC Articulação Horizontal" || el.name == "AFC Articulação Vertical" || el.name == "Avaliação Módulo Profissional" || el.name == "Caracterização de Turma" || el.name == "Grelha Critérios Avaliação Profissional" || el.name == "Grelha de Medidas de Suporte" || el.name == "Indisciplina" || el.name == "Instrumentos de Avaliação Profissional")) || el.name == "Documentos") {
            $scope.userModules[el.value] = true;
          } else {
            $scope.userModules[el.value] = false;
          }
        });
      } */

      let cpProfileByEducationType = getProfessorModulesByCourseType(educationType);
      $scope.modules.forEach((el) => {
        if ($scope.getProName(el.product) == "Plano de Turma" || el.value == "myDocuments") {
          if (cpProfileByEducationType.indexOf(el.value) != -1) {
            $scope.userModules[el.value] = true;
          } else {
            $scope.userModules[el.value] = false;
          }
        }
      });
    };

    $scope.addSubModClassMapping = function () {
      if ($scope.new.subModClassMapping == undefined || $scope.new.subModClassMapping == null) {
        $scope.new.subModClassMapping = [];
      }
      $scope.new.subModClassMapping.push({});
    };

    $scope.removeSubModClassMapping = function (elToRemove) {
      for (let i = 0; i < $scope.new.subModClassMapping.length; i++) {
        let el = $scope.new.subModClassMapping[i];
        let flag = true;
        for (const field in el) {
          if (el.hasOwnProperty(field)) {
            const elField = el[field];
            if (elField != elToRemove[field]) {
              flag = false;
            }
          }
          if (!flag) {
            break;
          }
        }
        if (flag) {
          $scope.new.subModClassMapping.splice(i, 1);
        }
      }
    };

    $scope.validateSubModClassMapping = function () {
      let mappingClasses = [];
      let mappingModules = [];
      let mappingSubjects = [];
      let incompleteMappings = [];

      $scope.new.subModClassMapping.forEach((subModClassMapping) => {
        if (subModClassMapping.class != null) {
          mappingClasses.push(subModClassMapping.class);
        }
        if (subModClassMapping.module != null) {
          mappingModules.push(subModClassMapping.module);
        }
        if (subModClassMapping.subject != null) {
          mappingSubjects.push(subModClassMapping.subject);
        }
        if (
          !(
            subModClassMapping.schoolYear &&
            (subModClassMapping.subject || subModClassMapping.module) &&
            subModClassMapping.class
          )
        ) {
          incompleteMappings.push(subModClassMapping);
        }
      });

      incompleteMappings.forEach((emptyMap) => {
        $scope.removeSubModClassMapping(emptyMap);
      });

      //Check if there were only incomplete mappings
      if ($scope.new.subModClassMapping.length > 0) {
        mappingClasses = mappingClasses.filter((item, index) => mappingClasses.indexOf(item) == index);
        mappingModules = mappingModules.filter((item, index) => mappingModules.indexOf(item) == index);
        mappingSubjects = mappingSubjects.filter((item, index) => mappingSubjects.indexOf(item) == index);

        if ($scope.new.class.length != mappingClasses.length) {
          parentScope.$parent.alertPermissionsMappingInvalidSelectedItems("todas as turmas");
          return false;
        } else {
          let classFlag = true;
          $scope.new.class.forEach((cls) => {
            if (mappingClasses.indexOf(cls) == -1) {
              classFlag = false;
            }
          });
          if (!classFlag) {
            parentScope.$parent.alertPermissionsMappingInvalidSelectedItems("todas as turmas");
            return false;
          }
        }

        if ($scope.new.module != null && Array.isArray($scope.new.module) && $scope.new.module.length > 0) {
          if ($scope.new.module.length != mappingModules.length) {
            parentScope.$parent.alertPermissionsMappingInvalidSelectedItems("todos os módulos");
            return false;
          } else {
            let moduleFlag = true;
            $scope.new.module.forEach((mod) => {
              if (mappingModules.indexOf(mod) == -1) {
                moduleFlag = false;
              }
            });
            if (!moduleFlag) {
              parentScope.$parent.alertPermissionsMappingInvalidSelectedItems("todos os módulos");
              return false;
            }
          }
        } else {
          if (mappingModules.length > 0) {
            parentScope.$parent.alertPermissionsMappingInvalidSelectedItems("todos os módulos");
            return false;
          }
        }

        if ($scope.new.subject != null && Array.isArray($scope.new.subject) && $scope.new.subject.length > 0) {
          if ($scope.new.subject.length != mappingSubjects.length) {
            parentScope.$parent.alertPermissionsMappingInvalidSelectedItems("todas as disciplinas");
            return false;
          } else {
            let subjectFlag = true;
            $scope.new.subject.forEach((sub) => {
              if (mappingSubjects.indexOf(sub) == -1) {
                subjectFlag = false;
              }
            });
            if (!subjectFlag) {
              parentScope.$parent.alertPermissionsMappingInvalidSelectedItems("todas as disciplinas");
              return false;
            }
          }
        } else {
          if (mappingSubjects.length > 0) {
            parentScope.$parent.alertPermissionsMappingInvalidSelectedItems("todas as disciplinas");
            return false;
          }
        }
      }

      return true;
    };

    $scope.validateCPFieldsByProfile = function (profile) {
      if (profile == "user") {
        if ($scope.new.classDirector != null && $scope.new.classDirector.length > 0) {
          parentScope.$parent.alertPermissionsNormProfWithClassDir();
          return false;
        }
        let userRequiredFields = ["year", "cluster", "school", "courseType", "course", "schoolYear", "class"];
        let emptyFieldFlag = false;
        userRequiredFields.forEach((cpField) => {
          if ($scope.new[cpField] == null || (Array.isArray($scope.new[cpField]) && $scope.new[cpField].length == 0)) {
            emptyFieldFlag = true;
          }
        });
        if (
          ($scope.new.module == null || (Array.isArray($scope.new.module) && $scope.new.module.length == 0)) &&
          ($scope.new.subject == null || (Array.isArray($scope.new.subject) && $scope.new.subject.length == 0))
        ) {
          emptyFieldFlag = true;
        }
        if (emptyFieldFlag) {
          parentScope.$parent.alertPermissionsEmptyUserRequiredFields();
          return false;
        }
      } else if (profile == "cpClassDirector") {
        /* if ($scope.new.classDirector == null || ($scope.new.classDirector != null && Array.isArray($scope.new.classDirector) && $scope.new.classDirector.length == 0)) {
          parentScope.$parent.alertPermissionsMissingClassDir();
          return false;
        } */
        let classDirectorRequiredFields = [
          "year",
          "cluster",
          "school",
          "courseType",
          "course",
          "schoolYear",
          "class",
          "classDirector",
        ];
        let emptyFieldFlag = false;
        classDirectorRequiredFields.forEach((cpField) => {
          if ($scope.new[cpField] == null || (Array.isArray($scope.new[cpField]) && $scope.new[cpField].length == 0)) {
            emptyFieldFlag = true;
          }
        });
        if (
          ($scope.new.module == null || (Array.isArray($scope.new.module) && $scope.new.module.length == 0)) &&
          ($scope.new.subject == null || (Array.isArray($scope.new.subject) && $scope.new.subject.length == 0))
        ) {
          emptyFieldFlag = true;
        }
        if (emptyFieldFlag) {
          parentScope.$parent.alertPermissionsEmptyClassDirectorRequiredFields();
          return false;
        }
      } else if (profile == "cpSupervisor") {
        let supervisorRequiredFields = ["year", "cluster", "courseType", "course"];
        let emptyFieldFlag = false;
        supervisorRequiredFields.forEach((cpField) => {
          if ($scope.new[cpField] == null || (Array.isArray($scope.new[cpField]) && $scope.new[cpField].length == 0)) {
            emptyFieldFlag = true;
          }
        });
        if (emptyFieldFlag) {
          parentScope.$parent.alertPermissionsEmptySupervisorRequiredFields();
          return false;
        }
      }

      return true;
    };

    $scope.filterOptionsBySchoolYear = function (index, field) {
      return function (element) {
        if (
          $scope.new.subModClassMapping[index].schoolYear != null &&
          $scope.new.subModClassMapping[index].schoolYear != ""
        ) {
          if (element.schoolYear.indexOf($scope.new.subModClassMapping[index].schoolYear) != -1) {
            return true;
          }
          return false;
        }
      };
    };
  },
]);
