"use strict";

var app = angular.module("analytics");

app.controller("OrganizationPanelCtrl", [
  "$scope",
  "organizationFactory",
  "reportingReportFactory",
  "storageFactory",
  "productFactory",
  function ($scope, organizationFactory, reportingReportFactory, storageFactory, productFactory) {
    $scope.selected = organizationFactory.getSelected();
    var parentScope = organizationFactory.getParentScope();

    $scope.uploadedFiles = [];
    $scope.sponsorsLogo;
    $scope.organizationFiles = [];
    $scope.orgFilesToUpload = [];
    $scope.orgLogosToUpload = [];
    $scope.hasUploadedSponsorsLogo = false;
    $scope.hasUploadedOrganizationLogo = false;
    $scope.orgTypes = ["Business", "Education", "Analytics"];


    reportingReportFactory.getAll().then(function (reportingReportsData) {
      $scope.reportingReportsData = reportingReportsData;
      $scope.reportingReportsData.forEach((r) => {
        r.parsedName =
          r.name +
          " - " +
          (r.modifiedAt ? new Date(r.modifiedAt).toLocaleDateString() : new Date(r.createdAt).toLocaleDateString());
      });
    });

    productFactory.getAll().then(function (products) {
      $scope.productsData = products;
    });

    function fetchFiles() {
      if ($scope.selected && $scope.selected.id) {
        storageFactory.listFilesInContainer($scope.selected.id).then((files) => {
          for (let i = 0; i < files.data.length; i++) {
            let file = files.data[i];
            file.ctime =
              new Date(file.ctime).toLocaleDateString() +
              " " +
              new Date(file.ctime).getHours() +
              ":" +
              (new Date(file.ctime).getMinutes() > 9
                ? new Date(file.ctime).getMinutes()
                : "0" + new Date(file.ctime).getMinutes());
            $scope.uploadedFiles.push(file);
          }
        });

        storageFactory.listFilesInContainer($scope.selected.id + "-logos").then((files) => {
          for (let i = 0; i < files.data.length; i++) {
            let file = files.data[i];
            file.ctime =
              new Date(file.ctime).toLocaleDateString() +
              " " +
              new Date(file.ctime).getHours() +
              ":" +
              (new Date(file.ctime).getMinutes() > 9
                ? new Date(file.ctime).getMinutes()
                : "0" + new Date(file.ctime).getMinutes());
            $scope.uploadedFiles.push(file);
          }
        });
      }
    }

    fetchFiles();

    $scope.save = function () {
      for (let j = 0; j < $scope.uploadedFiles.length; j++) {
        let el = $scope.uploadedFiles[j];

        if (el.name == "sponsorsLogo.png") {
          $scope.hasUploadedSponsorsLogo = true;
        }
        if (el.name == "orgLogo.png" || el.name == "orgLogo.svg") {
          $scope.hasUploadedOrganizationLogo = true;
        }
      }
      if ($scope.sponsorsLogo != null) {
        $scope.orgLogosToUpload.push($scope.sponsorsLogo);
        $scope.selected.hasSponsorsLogo = true;
      } else {
        if (!$scope.hasUploadedSponsorsLogo) {
          $scope.selected.hasSponsorsLogo = false;
        }
      }
      if ($scope.organizationLogo != null) {
        $scope.orgLogosToUpload.push($scope.organizationLogo);
        $scope.selected.hasOrganizationLogo = true;
      } else {
        if (!$scope.hasUploadedOrganizationLogo) {
          $scope.selected.hasOrganizationLogo = false;
        }
      }

      if ($scope.organizationFiles) {
        for (let i = 0; i < $scope.organizationFiles.length; i++) {
          let file = $scope.organizationFiles[i];
          $scope.orgFilesToUpload.push(file);
        }
      }

      if ($scope.selected.id) {
        //modify

        if ($scope.orgLogosToUpload.length > 0) {
          storageFactory.uploadFile($scope.selected.id + "-logos", $scope.orgLogosToUpload);
        }
        if ($scope.organizationFiles.length > 0) {
          storageFactory.uploadFile($scope.selected.id, $scope.orgFilesToUpload);
        }

        organizationFactory.modify($scope.selected.id, $scope.selected);
      } else {
        //create
        organizationFactory.create($scope.selected).then((created) => {
          if ($scope.orgLogosToUpload.length > 0) {
            storageFactory.uploadFile($scope.selected.id + "-logos", $scope.orgLogosToUpload);
          }
          if ($scope.organizationFiles.length > 0) {
            storageFactory.uploadFile($scope.selected.id, $scope.orgFilesToUpload);
          }
        });
      }

      parentScope.closePanel();
    };

    $scope.uploadedFile = function (element, fileType) {
      $scope.$apply(function ($scope) {
        if (fileType == "organizationLogo") {
          $scope.organizationLogo = element.files[0];
        }
        if (fileType == "sponsorsLogo") {
          $scope.sponsorsLogo = element.files[0];
        }
        if (fileType == "organizationFiles") {
          $scope.organizationFiles = element.files;
        }
      });
    };

    $scope.deleteFile = function deleteFile(file) {
      storageFactory.deleteFile(file.container, file.name).then(() => {
        fetchFiles();
      });
    };

    $scope.downloadFile = function downloadFile(file) {
      storageFactory.downloadFile(file.container, file.name);
    };
  },
]);
