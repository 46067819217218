"use strict";

var app = angular.module("analytics");

// import { parseData } from "./genericModule-Reporting";

app.controller("PreviewTablePanelCtrl", [
  "$scope",
  "genericFactory",
  "genericScope",
  "data",
  "columnDefs",
  "$timeout",
  "onSaveFunctions",
  "panelName",
  "panelConfig",
  "selected",
  "exportUiGridService",
  "$mdDialog",
  "$mdPanel",
  function (
    $scope,
    genericFactory,
    genericScope,
    data,
    columnDefs,
    $timeout,
    onSaveFunctions,
    panelName,
    panelConfig,
    selected,
    exportUiGridService,
    $mdDialog,
    $mdPanel
  ) {
    $scope.previewGridSelectedRow;
    $scope.exportUiGridService = exportUiGridService;
    $scope.onSaveFunctions = onSaveFunctions;
    if (panelConfig) {
      $scope.panelConfig = panelConfig;
    } else {
      $scope.panelConfig = {};
    }
    $scope.$mdDialog = $mdDialog;

    $scope.selected = selected;
    $scope.modifiedFlag = false;

    // Setup activeTab in case of importer
    if (panelConfig.importer) {
      $scope.activeTab = {};
      $scope.activeTab.importerPreviewTable = true;
      let activeTabFields = {};
      let activeTabEditableFields = [];
      columnDefs.forEach((columnDef) => {
        columnDef.label = columnDef.displayName;
        activeTabFields[columnDef.name] = columnDef;
        if (columnDef.enableCellEdit == null) {
          activeTabEditableFields.push(columnDef);
        }
      });
      $scope.activeTab.fields = activeTabFields;
      $scope.activeTab.editableFields = activeTabEditableFields;
    }

    if (panelName) {
      if (panelName.indexOf("'") != -1) {
        $scope.panelName = eval(panelName);
        $scope.originalPanelName = JSON.parse(JSON.stringify($scope.panelName));
        if ($scope.panelName.length > 99) {
          $scope.panelName = $scope.panelName.substring(0, 99) + "...";
        }
      } else {
        $scope.panelName = panelName;
      }
    } else {
      $scope.panelName = "Tabela de Avaliações Registadas";
    }

    if (columnDefs != null) {
      $scope.gridOptions = {
        enableHorizontalScrollbar: 0,
        enableHorizontalScrollbar: true,
        enableVerticalScrollbar: true,
        enableColumnResizing: true,
        enableFiltering: true,
        showColumnFooter: true,
        enableGridMenu: true,
        exporterMenuPdf: false,
        exporterMenuExcel: false,
        exporterOlderExcelCompatibility: false,
        flatEntityAccess: true,
        fastWatch: true,
        exporterMenuCsv: false,
        treeCustomAggregations: {
          sumAttendanceOptions: {
            aggregationFn: function (aggregation, fieldValue, numValue, row) {
              if (fieldValue != null) {
                if (aggregation.value == null) {
                  aggregation.value = 0;
                }
                if (!isNaN(Number(fieldValue))) {
                  fieldValue = Number(fieldValue);
                  aggregation.value += fieldValue;
                }
              }
            },
            finalizerFn: function (aggregation) {
              if (aggregation.value != null && !Number.isNaN(aggregation.value)) {
                aggregation.rendered = Math.round(aggregation.value * 100) / 100;
              }
            },
          },
          sumIncludingETICAFOptions: {
            aggregationFn: function (aggregation, fieldValue, numValue, row) {
              if (fieldValue != null) {
                if (!isNaN(Number(fieldValue))) {
                  fieldValue = Number(fieldValue);
                }
                if (aggregation.value == null) {
                  aggregation.value = 0;
                }
                if (fieldValue == "T" || fieldValue == "F") {
                  if (fieldValue) {
                    aggregation.value += 1;
                  }
                } else if (fieldValue == 0.5) {
                  if (fieldValue) {
                    aggregation.value += 0.5;
                  }
                } else {
                  if (fieldValue) {
                    aggregation.value += fieldValue;
                  }
                }
              }
            },
            finalizerFn: function (aggregation) {
              if (aggregation.value != null && !Number.isNaN(aggregation.value)) {
                aggregation.rendered = Math.round(aggregation.value * 100) / 100;
              }
            },
          },
        },
        enableColumnMenus: $scope.panelConfig.enableColumnMenus != null ? $scope.panelConfig.enableColumnMenus : true,
        gridMenuCustomItems: [
          {
            title: "Exportar todos os dados como Excel",
            action: function ($event) {
              exportUiGridService.exportToExcel("Registos", $scope.gridApi, "all", "all", $scope.panelName);
            },
            order: 110,
          },
          {
            title: "Exportar dados visíveis como Excel",
            action: function ($event) {
              exportUiGridService.exportToExcel("Registos", $scope.gridApi, "visible", "visible", $scope.panelName);
            },
            order: 111,
          },
        ],
      };
      if (!mobileAndTabletCheck()) {
        $scope.gridOptions.enableCellEditOnFocus = true;
        $scope.gridOptions.columnDefs = columnDefs;
      } else {
        $scope.gridOptions.columnDefs = columnDefs;
      }
    } else {
      $scope.gridOptions = {
        enableHorizontalScrollbar: 0,
        enableHorizontalScrollbar: true,
        enableVerticalScrollbar: true,
        enableColumnResizing: true,
        enableFiltering: true,
        enableGridMenu: true,
        exporterOlderExcelCompatibility: false,
        flatEntityAccess: true,
        fastWatch: true,
        exporterMenuPdf: false,
        exporterMenuCsv: false,
        exporterMenuExcel: false,
        gridMenuCustomItems: [
          {
            title: "Exportar todos os dados como Excel",
            action: function ($event) {
              exportUiGridService.exportToExcel("Registos", $scope.gridApi, "all", "all", $scope.panelName);
            },
            order: 110,
          },
          {
            title: "Exportar dados visíveis como Excel",
            action: function ($event) {
              exportUiGridService.exportToExcel("Registos", $scope.gridApi, "visible", "visible", $scope.panelName);
            },
            order: 111,
          },
        ],
      };
    }

    if (data.length && data[0].rowBackgroundColorID != null) {
      $scope.gridOptions.rowTemplate =
        '<div ng-class="{\'rowBackgroundColorZero\':row.entity.rowBackgroundColorID==0, \'rowBackgroundColorOne\':row.entity.rowBackgroundColorID==1 }"><div ng-repeat="(colRenderIndex, col) in colContainer.renderedColumns track by col.colDef.name" class="ui-grid-cell" ui-grid-cell></div></div>';
    }

    $scope.gridOptions.onRegisterApi = function (gridApi) {
      $scope.gridApi = gridApi;

      setInterval(
        () => {
          $scope.gridApi.core.handleWindowResize();
        },
        500,
        10
      );

      $scope.gridApi.edit.on.beginCellEdit($scope, function (rowEntity, colGridOptions, event) {
        if (
          event &&
          ($scope.panelConfig.disableKeyCodeRowSkip == null || $scope.panelConfig.disableKeyCodeRowSkip == false)
        ) {
          if (event.keyCode == "13" || event.keyCode == "38" || event.keyCode == "40") {
            let gridElement = document.getElementsByClassName("ui-grid-viewport")[3];
            if (event.keyCode == "38") {
              gridElement.scrollTop -= 30;
            }
            if (event.keyCode == "13" || event.keyCode == "40") {
              gridElement.scrollTop += 30;
            }
            setTimeout(() => {
              var rowCol = $scope.gridApi.cellNav.getFocusedCell();
              $scope.gridApi.cellNav.scrollToFocus(rowCol.row.entity, rowCol.col);
            }, 500);
          }
        }
        $scope.previewGridSelectedRow = rowEntity;
      });

      $scope.gridApi.edit.on.afterCellEdit($scope, function (rowEntity, colDef, newValue, oldValue) {
        let essenLearnWeightFlag = false;
        if (
          $scope.selected.correctionByEssenLearnWeightcheckbox &&
          $scope.selected.correctionByEssenLearnWeightcheckbox[""] &&
          $scope.selected.correctionByEssenLearnWeightcheckbox[""] == true &&
          rowEntity.weight
        ) {
          essenLearnWeightFlag = true;
        }
        if (!isNaN(newValue)) {
          if ((newValue < 0 || newValue > 200) && $scope.panelConfig.disableEvaluationLimitCheck == null) {
            if (!isNaN(oldValue) && oldValue >= 0 && oldValue <= 200) {
              rowEntity[colDef.name] = oldValue;
            } else {
              rowEntity[colDef.name] = undefined;
            }
            $scope.genericScope.hideLoad();

            if (essenLearnWeightFlag) {
              $scope.genericScope.parentScope.alertPreviewTableInvalidEvalEssenLearnWeight(rowEntity.weight);
            } else {
              $scope.genericScope.parentScope.alertPreviewTableInvalidEvalDefault();
            }
            return;
          } else if (newValue != null) {
            if (countDecimals(newValue) > 1) {
              rowEntity[colDef.name] = Math.round(newValue * 100) / 100;
            }
            if (essenLearnWeightFlag) {
              if (newValue > rowEntity.weight) {
                $scope.genericScope.parentScope.alertPreviewTableInvalidEvalEssenLearnWeight(rowEntity.weight);
                if (oldValue != null && !isNaN(oldValue) && oldValue >= 0 && oldValue <= rowEntity.weight) {
                  rowEntity[colDef.name] = oldValue;
                } else {
                  rowEntity[colDef.name] = undefined;
                }
              }
            }
          }
        }
        if (newValue == null || newValue == "(vazio)") {
          rowEntity[colDef.name] = undefined;
        }
        if (newValue != oldValue) {
          if (newValue == "(vazio)" && oldValue == undefined) {
            $scope.modifiedFlag = false;
          } else {
            $scope.modifiedFlag = true;
          }
        }
        if (columnDefs != null) {
          $scope.genericScope.showLoad();
          for (let i = 0; i < columnDefs.length; i++) {
            const column = columnDefs[i];
            if (column.checkAfterEditCell) {
              let revertEditFlag = eval(column.checkAfterEditCell.replace(new RegExp("{}", "g"), "$scope.selected"));
              if (revertEditFlag == true) {
                $scope.genericScope.hideLoad();
                return;
              }
            }
            if (column.calculatedFunction != null) {
              for (let w = 0; w < $scope.gridOptions.data.length; w++) {
                let previewGridEl = $scope.gridOptions.data[w];
                if (column.checkBeforeCalcFunc) {
                  let flag = eval(
                    column.checkBeforeCalcFunc
                      .replace(new RegExp("{}", "g"), "$scope.selected")
                      .replace(new RegExp("}{", "g"), "previewGridEl")
                  );
                  if (flag == true) {
                    continue;
                  }
                }
                let index = previewGridEl.dynamicIndex;
                let calculatedValue = eval(
                  column.calculatedFunction
                    .replace(new RegExp("{}", "g"), "$scope.selected")
                    .replace(new RegExp("><", "g"), "$scope")
                    .replace(new RegExp(">>", "g"), "index")
                    .replace(new RegExp("}{", "g"), "previewGridEl")
                );

                previewGridEl[column.name] = calculatedValue;
              }
            }
          }
          $scope.genericScope.hideLoad();
        }
      });
    };

    var refresh = function () {
      $scope.refresh = true;
      $timeout(function () {
        $scope.refresh = false;
      }, 0);
    };

    var parentScope = genericFactory.getParentScope();
    $scope.genericScope = genericScope;
    $scope.data = data;
    $scope.gridApi;

    if ($scope.data.length > 0) {
      $scope.gridOptions.data = parseData(genericScope, $scope.data);
      refresh();
    }

    $scope.cleanTable = function () {
      $scope.gridOptions.data = null;
      $scope.gridOptions.columnDefs = null;
      refresh();
    };

    $scope.closePanel = function (closeOnSaveFlag) {
      if ($scope.uploadPanelRef != null) {
        $scope.uploadPanelRef.close();
        $scope.uploadPanelRef = null;
      } else {
        if ($scope.panelConfig && $scope.panelConfig.checkChangesBeforeClose && $scope.modifiedFlag) {
          return new Promise((resolve, reject) => {
            var confirm = $scope.$mdDialog
              .confirm()
              .title("Deseja guardar as alterações efetuadas?")
              .ariaLabel("Deseja guardar as alterações efetuadas?")
              .ok("Sim")
              .cancel("Não")
              .clickOutsideToClose(false)
              .escapeToClose(false);

            $scope.$mdDialog.show(confirm).then(
              function () {
                $scope.save(closeOnSaveFlag);
              },
              function () {
                genericScope.closePanel();
                resolve(true);
              }
            );
          });
        } else {
          genericScope.closePanel();
        }
      }
    };

    $scope.save = function (closeOnSaveFlag) {
      $scope.modifiedFlag = false;
      $scope.selected["modifiedAt"] = Date.now();
      $scope.selected["modifiedBy"] = $scope.currentUser;

      let onSaveFunctions = $scope.onSaveFunctions;
      if (onSaveFunctions != null) {
        let onSaveFlag;
        for (let j = 0; j < onSaveFunctions.length; j++) {
          let functionName = onSaveFunctions[j];
          if (utilFunctions[functionName] !== null && utilFunctions[functionName] !== undefined) {
            onSaveFlag = utilFunctions[functionName]($scope, $scope.selected);
            if (onSaveFlag != undefined && !onSaveFlag) {
              return;
            }
          }
        }
      }

      genericFactory.modify($scope.selected.id, $scope.selected).then(() => {
        parentScope.$parent.successfulSave();
        if (closeOnSaveFlag) {
          genericScope.closePanel();
        }
      });
    };

    $scope.exportPreviewTablePanel = function () {
      let editableFieldLabels = JSON.parse(JSON.stringify($scope.activeTab.editableFields));
      editableFieldLabels = editableFieldLabels.map((field) => (field = field.label));
      parentScope.$parent.ptExportedPreviewPanel(editableFieldLabels);

      exportUiGridService.exportToExcel(
        "Registos",
        $scope.gridApi,
        "visible",
        "visible",
        $scope.originalPanelName ? $scope.originalPanelName : $scope.panelName
      );
    };

    $scope.openUploadFilePanel = function () {
      var position = $mdPanel.newPanelPosition().absolute().center();

      var config = {
        attachTo: angular.element(document.body),
        disableParentScroll: false,
        controller: "UploadFilePanelCtrl",
        controllerAs: "ctrl",
        templateUrl: "templates/uploadFilePanel.html",
        hasBackdrop: true,
        panelClass: "a-panel",
        position: position,
        trapFocus: true,
        zIndex: 1,
        animation: undefined,
        onDomRemoved: function () {
          if ($scope.uploadPanelRef != null) {
            $scope.uploadPanelRef.destroy();
          }
        },
        locals: {
          genericScope: $scope,
          data: $scope.gridOptions.data,
          importerArgs: null,
        },
      };

      $mdPanel.open(config).then(function (result) {
        $scope.uploadPanelRef = result;
      });
    };

    $scope.$on("$destroy", function () {
      if ($scope.uploadPanelRef != null) {
        $scope.uploadPanelRef.destroy();
        $scope.uploadPanelRef.close();
        $scope.uploadPanelRef = null;
      }
    });
  },
]);
