"use strict";

// import {
//   utilFunctions
// } from "./genericModule-Utils";

var app = angular.module("analytics");

app.controller("GenericModulePanelCtrl", [
  "$scope",
  "genericFactory",
  "genericScope",
  "clientsFactory",
  "activeTab",
  "activeTabName",
  "activeModule",
  "mode",
  "deps",
  "depsData",
  "fieldFilters",
  "dynamicFields",
  "currentUser",
  "data",
  "$mdDialog",
  "$mdPanel",
  "storageFactory",
  "localizationFactory",
  function (
    $scope,
    genericFactory,
    genericScope,
    clientsFactory,
    activeTab,
    activeTabName,
    activeModule,
    mode,
    deps,
    depsData,
    fieldFilters,
    dynamicFields,
    currentUser,
    data,
    $mdDialog,
    $mdPanel,
    storageFactory,
    localizationFactory
  ) {
    var parentScope = genericFactory.getParentScope();

    $scope.panelSearchTerms = {};
    $scope.selected = genericFactory.getSelected();
    $scope.genericScope = genericScope;
    $scope.activeTab = activeTab;
    $scope.activeTabName = activeTabName;
    $scope.module = activeModule;
    $scope.moduleName = $scope.module.name;
    $scope.deps = deps;
    $scope.depsData = depsData;
    $scope.data = data;
    $scope.dynamicFields = dynamicFields;
    $scope.currentUser = currentUser;
    $scope.fieldFilters = fieldFilters;
    $scope.$mdDialog = $mdDialog;
    $scope.searchTerm;
    $scope.genericFactory = genericFactory;
    $scope.parentScope = parentScope;

    $scope.hiddenFieldsShownInPanel = {};
    $scope.storageFactory = storageFactory;
    $scope.filesToUploadFlag = false;
    $scope.filesToUploadByField = {};
    $scope.numFilesToUpload = 0;
    $scope.numUploadedFiles = 0;
    $scope.dynamicFieldSearch = {};

    // Check only one concurrent editor. Invalidate control after 1 hour
    if ($scope.activeTab.onlyOneConcurrentEditorControl && $scope.selected && $scope.selected.id) {
      $scope.genericFactory.get($scope.selected.id).then(function (updatedSelected) {
        if (updatedSelected.rowAlreadyOpen && new Date().getTime() - updatedSelected.rowAlreadyOpenAt < 3600000) {
          $scope.mode = "view";
          genericFactory.setMode("view");
          parentScope.$parent.concurrentEditorAlert();
        } else if (
          updatedSelected.rowAlreadyOpen &&
          new Date().getTime() - updatedSelected.rowAlreadyOpenAt > 3600000
        ) {
          $scope.mode = mode;
          genericFactory.setMode(mode);
          updatedSelected.rowAlreadyOpenAt = new Date().getTime();
          genericFactory.modify(updatedSelected.id, updatedSelected).then((row) => {});
        } else {
          $scope.mode = mode;
          genericFactory.setMode(mode);
          updatedSelected.rowAlreadyOpen = true;
          updatedSelected.rowAlreadyOpenAt = new Date().getTime();
          genericFactory.modify(updatedSelected.id, updatedSelected).then((row) => {});
        }

        // Add event listener to prevent refreshes
        /* if ($scope.activeTab.onlyOneConcurrentEditorControl && $scope.mode != "view") {
          window.addEventListener("beforeunload", (event) => {
            // Cancel the event as stated by the standard.
            event.preventDefault();
            // Chrome requires returnValue to be set.
            event.returnValue = "";

            convertDatesOfObjectToMs($scope.originalSelected);

            $scope.originalSelected.rowAlreadyOpen = null;
            $scope.originalSelected.rowAlreadyOpenAt = null;
            genericFactory.modify($scope.originalSelected.id, $scope.originalSelected).then((row) => {});
          });
        } */
      });
    } else {
      $scope.mode = mode;
    }

    // Translate text

    $scope.translateText = function (text) {
      return localizationFactory.translateText(text);
    };

    $scope.localeSensitiveComparator = function (v1, v2) {
      // If we get numbers
      if (v1.type == "number" && v2.type == "number") {
        return v1.value < v2.value ? -1 : 1;
      }

      // If we get strings
      if (v1.type == "string" && v2.type == "string") {
        if (
          v1.value.normalize("NFD").replace(/[\u0300-\u036f]/g, "") <
          v2.value.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
        ) {
          return -1;
        }

        if (
          v1.value.normalize("NFD").replace(/[\u0300-\u036f]/g, "") >
          v2.value.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
        ) {
          return 1;
        }
      }

      // If we get neither, just compare indexes
      return v1.index < v2.index ? -1 : 1;
    };

    $scope.clearSearchTerm = function (field) {
      field.searchTerm = "";
    };

    $scope.getPanelModeDesc = function () {
      switch ($scope.mode) {
        case "create":
          return $scope.translateText("new");
        case "modify":
          return $scope.translateText("edit");
        case "view":
          return $scope.translateText("view");
        case "update":
          return $scope.translateText("update");
      }
    };

    if ($scope.selected == null) {
      $scope.selected = {};
    } else {
      $scope.originalSelected = JSON.parse(JSON.stringify($scope.selected));
      genericFactory.setOriginalSelected($scope.originalSelected);
    }

    $scope.onloadResult = utilFunctions["onloadPanel"]($scope, data);

    $scope.save = function () {
      if ($scope.selected.splitFlag && $scope.selected.splitFlag == true) {
        parentScope.$parent.invalidTabToSave();
        return;
      }
      if (mode == "update") {
        if ($scope.activeTab.checkDuplicate) {
          if (
            genericFactory.checkDuplicate(
              $scope.selected,
              $scope.originalSelected,
              $scope.data,
              $scope.activeTab.checkDuplicate
            )
          ) {
            parentScope.$parent.duplicatedEntry();
            return;
          }
        }
      }

      if (!$scope.customFieldValidation()) {
        return;
      }

      $scope.checkboxValidation();

      if (!$scope.checkRequiredFields($scope.selected)) {
        return;
      }

      let onSaveFunctions = $scope.activeTab.onSaveFunctions;
      if (onSaveFunctions != null) {
        let onSaveFlag;
        for (let j = 0; j < onSaveFunctions.length; j++) {
          let functionName = onSaveFunctions[j];
          if (utilFunctions[functionName] !== null && utilFunctions[functionName] !== undefined) {
            onSaveFlag = utilFunctions[functionName]($scope, $scope.selected, $scope.data);
            if (onSaveFlag != undefined && !onSaveFlag) {
              return;
            }
          }
        }
      }

      let onSavePromiseFunctions = $scope.activeTab.onSavePromiseFunctions;
      if (onSavePromiseFunctions != null) {
        let onSaveFlag = true;
        let promiseArr = [];
        for (let j = 0; j < onSavePromiseFunctions.length; j++) {
          let functionName = onSavePromiseFunctions[j];
          if (utilFunctions[functionName] !== null && utilFunctions[functionName] !== undefined) {
            promiseArr.push(
              utilFunctions[functionName]($scope, $scope.selected)
                .then(function whenOk(response) {
                  if (response != null) {
                    onSaveFlag = onSaveFlag && response;
                    return $scope.selected;
                  }
                })
                .catch(function notOk(err) {
                  console.catch(err);
                })
            );
          }
        }

        Promise.all(promiseArr).then(function (success) {
          if (onSaveFlag != undefined && onSaveFlag) {
            $scope.checkboxValidation();
            $scope.saveAfterCheck();
          } else {
            return;
          }
        });
      } else {
        $scope.saveAfterCheck();
      }
    };

    $scope.saveAfterCheck = function () {
      $scope.convertTypes($scope.selected, "date", "number");
      parentScope.$parent.showLoader();
      if ($scope.selected.id) {
        //modify
        if (mode == "modify") {
          //Replicate fields validation
          if (
            Object.keys($scope.onloadResult["replicateChangeFields"]).length !== 0 &&
            $scope.onloadResult["replicateChangeFields"].constructor === Object
          ) {
            var alreadyModified = false;
            for (let k = 0; k < data.length; k++) {
              const el = data[k];
              var modifiedFlag = false;
              var emptyField = false;
              for (const key in $scope.onloadResult["replicateChangeFields"]) {
                if ($scope.onloadResult["replicateChangeFields"].hasOwnProperty(key)) {
                  const element = $scope.onloadResult["replicateChangeFields"][key];
                  for (let i = 0; i < element.length; i++) {
                    const field = element[i];
                    if (
                      el[field] != $scope.selected[field] ||
                      $scope.selected[field] == null ||
                      $scope.selected[field] == undefined ||
                      $scope.selected[field] == ""
                    ) {
                      emptyField = true;
                      break;
                    }
                    if (i == element.length - 1 && el.id != $scope.selected.id) {
                      modifiedFlag = true;
                      el[key] = $scope.selected[key];
                    }
                  }
                  if (emptyField) {
                    if (!alreadyModified) {
                      alreadyModified = true;
                      genericFactory.modify($scope.selected.id, $scope.selected).then((row) => {
                        if ($scope.filesToUploadFlag) {
                          $scope.checkFilesToUpload(row, false);
                        }
                      });
                    }
                    continue;
                  }
                }
              }
              if (modifiedFlag == true) {
                genericFactory.modify(el.id, el).then((row) => {
                  if ($scope.filesToUploadFlag) {
                    $scope.checkFilesToUpload(row, false);
                  }
                });
                modifiedFlag = false;
              }
            }
          }

          $scope.selected["modifiedAt"] = Date.now();
          $scope.selected["modifiedBy"] = $scope.getCleanCreatedModifiedBy();

          genericFactory.modify($scope.selected.id, $scope.selected).then((row) => {
            if ($scope.filesToUploadFlag) {
              $scope.checkFilesToUpload(row);
            } else {
              parentScope.$parent.successfulSave();
              genericScope.closePanel();
            }
          });
        } else {
          //update
          delete $scope.selected.id;

          /* for (let w = 0; w < $scope.onloadResult["dynamicFields"].length; w++) {
            let dynField = $scope.onloadResult["dynamicFields"][w];
            if ($scope.activeTab.fields[dynField].saveOnUpdate != undefined && !$scope.activeTab.fields[dynField].saveOnUpdate) {
              $scope.selected[dynField] = [];
            }
          } */

          $scope.selected["updatedRow"] = true;
          $scope.selected["modifiedAt"] = null;
          $scope.selected["modifiedBy"] = null;
          $scope.selected["createdAt"] = Date.now();
          $scope.selected["createdBy"] = $scope.getCleanCreatedModifiedBy();

          genericFactory.update($scope.selected).then((row) => {
            if (
              $scope.filesToUploadFlag ||
              ($scope.uploadedFilesByField && Object.keys($scope.uploadedFilesByField).length > 0)
            ) {
              $scope.checkFilesToUpload(row);
            } else {
              parentScope.$parent.successfulSave();
              genericScope.closePanel();
            }
          });
        }
      } else {
        //create
        $scope.selected["organization"] = clientsFactory.getUser().user.organization;

        $scope.selected["createdAt"] = Date.now();
        $scope.selected["createdBy"] = $scope.getCleanCreatedModifiedBy();

        genericFactory.create($scope.selected).then((row) => {
          if ($scope.filesToUploadFlag) {
            $scope.checkFilesToUpload(row);
          } else {
            parentScope.$parent.successfulSave();
            genericScope.closePanel();
          }
        });
      }
    };

    $scope.getCleanCreatedModifiedBy = function () {
      let cleanCreatedModifiedBy = JSON.parse(JSON.stringify($scope.currentUser));
      let relevantCreatedModifiedByFields = ["organization", "name", "surname", "id", "profile"];
      for (const field in cleanCreatedModifiedBy) {
        if (Object.hasOwnProperty.call(cleanCreatedModifiedBy, field)) {
          if (relevantCreatedModifiedByFields.indexOf(field) == -1) {
            delete cleanCreatedModifiedBy[field];
          }
        }
      }
      return cleanCreatedModifiedBy;
    };

    $scope.keys = function (object) {
      return Object.keys(object).length;
    };

    $scope.objectKeys = function (object) {
      if (object) {
        return Object.keys(object);
      }
      return;
    };

    $scope.isArrayPanelOption = function (object) {
      if (object) {
        return Array.isArray(object);
      }
    };

    $scope.msToDate = function (num) {
      if (num != "null" && num != "undefined" && num != null) {
        var date = new Date(num);
        if (date === "Invalid Date") {
          return "";
        } else {
          return date;
        }
      } else {
        return "";
      }
    };

    $scope.convertTypes = function (selectedRow, a, b) {
      for (var selectedField in selectedRow) {
        if (
          $scope.activeTab.fields[selectedField] &&
          $scope.activeTab.fields[selectedField].viewType == "dynamicField"
        ) {
          for (let k = 0; k < selectedRow[selectedField].length; k++) {
            for (const dynamicField in selectedRow[selectedField][k]) {
              if (
                $scope.activeTab.fields[selectedField].dynamicFields[dynamicField] &&
                $scope.activeTab.fields[selectedField].dynamicFields[dynamicField].type == "number" &&
                $scope.activeTab.fields[selectedField].dynamicFields[dynamicField].viewType == "date" &&
                a == "number" &&
                b == "date"
              ) {
                if (!Number.isNaN(Number(selectedRow[selectedField][k][dynamicField]))) {
                  selectedRow[selectedField][k][dynamicField] = $scope.msToDate(
                    selectedRow[selectedField][k][dynamicField]
                  );
                } else if (
                  selectedRow[selectedField][k][dynamicField].indexOf("000Z") != -1 &&
                  selectedRow[selectedField][k][dynamicField].indexOf("T") != -1
                ) {
                  selectedRow[selectedField][k][dynamicField] = $scope.msToDate(
                    selectedRow[selectedField][k][dynamicField].replace(/-/g, "/").replace(/T.+/, "")
                  );
                } else {
                  selectedRow[selectedField][k][dynamicField] = null;
                }
              }
              if (
                $scope.activeTab.fields[selectedField].dynamicFields[dynamicField] &&
                selectedRow[selectedField][k][dynamicField] != null &&
                $scope.activeTab.fields[selectedField].dynamicFields[dynamicField].type == "number" &&
                $scope.activeTab.fields[selectedField].dynamicFields[dynamicField].viewType == "date" &&
                a == "date" &&
                b == "number"
              ) {
                if (selectedRow[selectedField][k][dynamicField] == "") {
                  selectedRow[selectedField][k][dynamicField] = null;
                } else {
                  if (!Number.isNaN(selectedRow[selectedField][k][dynamicField].getTime())) {
                    selectedRow[selectedField][k][dynamicField] = selectedRow[selectedField][k][dynamicField].getTime();
                  } else {
                    selectedRow[selectedField][k][dynamicField] = null;
                  }
                }
              }
            }
          }
        }

        if (
          $scope.activeTab.fields[selectedField] &&
          $scope.activeTab.fields[selectedField].type == "number" &&
          $scope.activeTab.fields[selectedField].viewType == "date" &&
          a == "number" &&
          b == "date"
        ) {
          if (!Number.isNaN(Number(selectedRow[selectedField]))) {
            selectedRow[selectedField] = $scope.msToDate(selectedRow[selectedField]);
          } else if (
            selectedRow[selectedField].indexOf("000Z") != -1 &&
            selectedRow[selectedField].indexOf("T") != -1
          ) {
            selectedRow[selectedField] = $scope.msToDate(
              selectedRow[selectedField].replace(/-/g, "/").replace(/T.+/, "")
            );
          } else {
            selectedRow[selectedField] = null;
          }
        }
        if (
          $scope.activeTab.fields[selectedField] &&
          selectedRow[selectedField] != null &&
          $scope.activeTab.fields[selectedField].type == "number" &&
          $scope.activeTab.fields[selectedField].viewType == "date" &&
          a == "date" &&
          b == "number"
        ) {
          if (selectedRow[selectedField] == "") {
            selectedRow[selectedField] = null;
          } else {
            if (!Number.isNaN(selectedRow[selectedField].getTime())) {
              selectedRow[selectedField] = selectedRow[selectedField].getTime();
            } else {
              selectedRow[selectedField] = null;
            }
          }
        }
      }
    };

    $scope.convertTypes($scope.selected, "number", "date");

    $scope.getCalculatedValue = function (field, calculatedExpression) {
      var fromTableData = null;
      if ($scope.activeTab.fields[field].fromTable != null) {
        fromTableData = $scope.getFromTableData($scope.activeTab.fields[field].fromTable);
      }
      var calculatedValue = eval(
        calculatedExpression
          .replace(new RegExp("{}", "g"), "$scope.selected")
          .replace(new RegExp("><", "g"), "$scope")
          .replace(new RegExp("<>", "g"), "fromTableData")
          .replace(new RegExp("##", "g"), "$scope.currentUser")
      );
      $scope.selected[field] = calculatedValue;
      return calculatedValue;
    };

    $scope.getDynamicCalculatedValue = function (dynFields, dynField, calculatedExpression, index) {
      //This if clause is necessary because this functions is included in a ng-repeat (thus creating a watch) of the FROMTABLE CALCULATED CHECKBOX form template
      if (calculatedExpression != null) {
        var calculatedValue = eval(
          calculatedExpression
            .replace(new RegExp("{}", "g"), "$scope.selected")
            .replace(new RegExp("><", "g"), "$scope")
            .replace(new RegExp(">>", "g"), "index")
        );
        $scope.selected[dynFields][index][dynField] = calculatedValue;
        return calculatedValue;
      }
    };

    $scope.getSubDynamicCalculatedValue = function (dynFields, dynField, calculatedExpression, index) {
      //This if clause is necessary because this functions is included in a ng-repeat (thus creating a watch) of the FROMTABLE CALCULATED CHECKBOX form template
      if (calculatedExpression != null) {
        eval(
          calculatedExpression
            .replace(new RegExp("{}", "g"), "$scope.selected")
            .replace(new RegExp("><", "g"), "$scope")
            .replace(new RegExp(">>", "g"), "index")
        );
      }
    };

    $scope.getDynamicCalculatedFromTableValue = function (dynFields, dynField, calculatedExpression, index) {
      //This if clause is necessary because this functions is included in a ng-repeat (thus creating a watch) of the FROMTABLE CALCULATED CHECKBOX form template
      if (calculatedExpression != null) {
        var fromTableData = $scope.getFromTableData(
          $scope.activeTab.fields[dynFields].dynamicFields[dynField].fromTable
        );
        if (fromTableData != null) {
          var calculatedValue = eval(
            calculatedExpression
              .replace(new RegExp("{}", "g"), "$scope.selected")
              .replace(new RegExp("><", "g"), "$scope")
              .replace(new RegExp(">>", "g"), "index")
              .replace(new RegExp("<>", "g"), "fromTableData")
          );
          $scope.selected[dynFields][index][dynField] = calculatedValue;
          return calculatedValue;
        }
      }
    };

    $scope.getDynamicDropdownFromTableValue = function (dynFields, dynField, calculatedExpression, index) {
      //This if clause is necessary because this functions is included in a ng-repeat (thus creating a watch) of the FROMTABLE CALCULATED CHECKBOX form template
      if (calculatedExpression != null) {
        var fromTableData = $scope.getFromTableData(
          $scope.activeTab.fields[dynFields].dynamicFields[dynField].fromTable
        );
        if (fromTableData != null) {
          var calculatedValue = eval(
            calculatedExpression
              .replace(new RegExp("{}", "g"), "$scope.selected")
              .replace(new RegExp("><", "g"), "$scope")
              .replace(new RegExp(">>", "g"), "index")
              .replace(new RegExp("<>", "g"), "fromTableData")
          );
          //$scope.selected[dynFields][index][dynField] = calculatedValue;
          if (JSON.stringify($scope.activeTab.fields[dynFields][dynField][index]) != JSON.stringify(calculatedValue)) {
            $scope.activeTab.fields[dynFields][dynField][index] = calculatedValue;
          }
          return calculatedValue;
        }
      }
    };

    $scope.getCalculatedFromTableValue = function (field, calculatedExpression) {
      //This if clause is necessary because this functions is included in a ng-repeat (thus creating a watch) of the FROMTABLE CALCULATED CHECKBOX form template
      if (calculatedExpression != null && $scope.activeTab.fields[field].filteredBy == null) {
        var fromTableData = $scope.getFromTableData($scope.activeTab.fields[field].fromTable);
        let auxFromTableData;
        if ($scope.activeTab.fields[field].auxFromTable != null) {
          auxFromTableData = $scope.getFromTableData($scope.activeTab.fields[field].auxFromTable);
        }
        var calculatedValue;
        if (fromTableData != null) {
          calculatedValue = eval(
            calculatedExpression
              .replace(new RegExp("{}", "g"), "$scope.selected")
              .replace(new RegExp("<>", "g"), "fromTableData")
              .replace(new RegExp("<<", "g"), "auxFromTableData")
              .replace(new RegExp("><", "g"), "$scope")
          );
          $scope.selected[field] = calculatedValue;
          return calculatedValue;
        }
      }
    };

    $scope.getFromTableData = function (fromTable, getAllFromTableFlag) {
      var i = $scope.deps.indexOf(fromTable);
      if (i != -1) {
        if (getAllFromTableFlag != null && getAllFromTableFlag == true) {
          return $scope.depsData[i];
        } else {
          return $scope.depsData[i].filter((o) => o.organization == $scope.currentUser.organization);
        }
      }
    };

    let getFromTableAndAuxData = {};

    $scope.getFromTableAndAuxData = function (field, fromTable, auxFromTable) {
      if (getFromTableAndAuxData[field] != null) {
        return getFromTableAndAuxData[field];
      } else {
        let i = $scope.deps.indexOf(fromTable);
        let j = $scope.deps.indexOf(auxFromTable);
        if (i != -1 && j != -1) {
          let fromTableData = $scope.depsData[i].filter((o) => o.organization == $scope.currentUser.organization);
          let auxFromTableData = $scope.depsData[j].filter((o) => o.organization == $scope.currentUser.organization);
          fromTableData.forEach((el) => {
            let parsedEl;
            if (el["parsedFlag" + field] == null) {
              parsedEl = auxFromTableData.filter((auxEl) => auxEl.id == el[field]);
              if (Array.isArray(parsedEl) && parsedEl.length > 0) {
                el[field] = parsedEl[0][field];
                el["parsedFlag" + field] = true;
              }
            }
          });
          getFromTableAndAuxData[field] = fromTableData;
          return fromTableData;
        }
      }
    };

    $scope.getFromTableDataElBydId = function (fromTable, id) {
      let data = $scope.getFromTableData(fromTable);
      for (let d = 0; d < data.length; d++) {
        let el = data[d];
        if (el.id == id) {
          return el;
        }
      }
      return null;
    };

    $scope.fastItems = [];
    $scope.filteredByFieldDependencies = {};

    $scope.getFastFromTableData = function (fastFlag, fromTable, fastFilterField, customLabelElements, field) {
      /* if ($scope.fastItems.length == 0 && $scope.selected[fastFilterField] != null) {
        var i = $scope.deps.indexOf(fromTable);
        if (i != -1) {
          $scope.fastItems = $scope.depsData[i].filter(o => o.organization == $scope.currentUser.organization && (o[fastFilterField] == $scope.selected[fastFilterField] || o.id == $scope.selected[fastFilterField]) );
          return $scope.fastItems;
        }
      } else {
        return $scope.fastItems;
      } */

      if (fastFlag) {
        if (customLabelElements != null) {
          if (fastFilterField == null) {
            return customLabelElements;
          } else if (fastFilterField != null && Array.isArray(fastFilterField) && fastFilterField.length > 0) {
            let filteredCustomLabelElements = [];

            if (
              $scope.filteredByFieldDependencies[field] &&
              $scope.filteredByFieldDependencies[field].filteredData &&
              !$scope.checkIfFastFilterFieldsChanged(field, fastFilterField)
            ) {
              return $scope.filteredByFieldDependencies[field].filteredData;
            }

            for (let f = 0; f < customLabelElements.length; f++) {
              let element = customLabelElements[f];
              let filteredByErrorFlag = false;
              for (let i = 0; i < fastFilterField.length; i++) {
                if ($scope.filteredByFieldDependencies[field] == null) {
                  $scope.filteredByFieldDependencies[field] = {};
                }
                if ($scope.filteredByFieldDependencies[field][fastFilterField[i]] == null) {
                  $scope.filteredByFieldDependencies[field][fastFilterField[i]] = {};
                }

                $scope.filteredByFieldDependencies[field][fastFilterField[i]] = $scope.selected[fastFilterField[i]];

                if ($scope.selected[fastFilterField[i]] != null) {
                  if ($scope.selected[fastFilterField[i]] == element.id) {
                    continue;
                  }
                  if ($scope.selected[fastFilterField[i]] == element[fastFilterField[i]]) {
                    continue;
                  }

                  if (Array.isArray(element[fastFilterField[i]])) {
                    if (element[fastFilterField[i]].indexOf($scope.selected[fastFilterField[i]]) != -1) {
                      continue;
                    }
                  }
                  if (Array.isArray($scope.selected[fastFilterField[i]])) {
                    if (
                      $scope.selected[fastFilterField[i]].indexOf(element.id) != -1 ||
                      $scope.selected[fastFilterField[i]].indexOf(element[fastFilterField[i]]) != -1
                    ) {
                      continue;
                    }
                  }
                  if (
                    Array.isArray($scope.selected[fastFilterField[i]]) &&
                    Array.isArray(element[fastFilterField[i]])
                  ) {
                    if ($scope.selected[fastFilterField[i]].some((r) => element[fastFilterField[i]].indexOf(r) >= 0)) {
                      continue;
                    }
                  }
                  let filteredData = $scope.getFromTableDataElBydId(
                    $scope.activeTab.fields[field].fromTable,
                    element.id
                  );
                  if (filteredData != null) {
                    if (!Array.isArray(filteredData[fastFilterField[i]])) {
                      if ($scope.selected[fastFilterField[i]] == filteredData[fastFilterField[i]]) {
                        continue;
                      }
                    } else {
                      if (filteredData[fastFilterField[i]].indexOf($scope.selected[fastFilterField[i]]) != -1) {
                        continue;
                      }
                    }
                  }
                  filteredByErrorFlag = true;
                  break;
                }
              }
              if (!filteredByErrorFlag) {
                filteredCustomLabelElements.push(element);
              }
            }

            $scope.filteredByFieldDependencies[field].filteredData = filteredCustomLabelElements;
            return filteredCustomLabelElements;
          }
        }
        if ($scope.fastItems.length == 0) {
          let i = $scope.deps.indexOf(fromTable);
          if (i != -1) {
            $scope.fastItems = $scope.depsData[i].filter((o) => o.organization == $scope.currentUser.organization);
          }
        } else {
          if (fastFilterField == null) {
            return $scope.fastItems;
          } else {
            return $scope.fastItems.filter(
              (o) =>
                o[fastFilterField] == $scope.selected[fastFilterField] ||
                o.id == $scope.selected[fastFilterField] ||
                (Array.isArray($scope.selected[fastFilterField]) &&
                  $scope.selected[fastFilterField].indexOf(o[fastFilterField]) != -1)
            );
          }
        }
      }
    };

    $scope.checkIfFastFilterFieldsChanged = function (field, fastFilterField) {
      for (let w = 0; w < fastFilterField.length; w++) {
        let filterField = fastFilterField[w];
        if ($scope.selected[filterField] != $scope.filteredByFieldDependencies[field][filterField]) {
          $scope.selected[field] = null;
          $scope.filteredByFieldDependencies[field][filterField] = $scope.selected[filterField];
          return true;
        }
      }
      return false;
    };

    $scope.getFilteredFromTableData = function (field, fromTable, selectFilterOptions, auxFromTable) {
      if (selectFilterOptions != null) {
        var i = $scope.deps.indexOf(fromTable);
        let filteredFromTableData = [];
        if (i != -1) {
          if (Array.isArray(selectFilterOptions) && selectFilterOptions.length > 0) {
            if (selectFilterOptions.length == 1) {
              filteredFromTableData = $scope.depsData[i].filter(
                (o) => o.organization == $scope.currentUser.organization && selectFilterOptions == o[field]
              );
            } else {
              filteredFromTableData = $scope.depsData[i].filter(
                (o) => o.organization == $scope.currentUser.organization && selectFilterOptions.indexOf(o[field]) != -1
              );
            }
            return filteredFromTableData;
          } else {
            for (const filterField in selectFilterOptions) {
              if (selectFilterOptions.hasOwnProperty(filterField)) {
                const filterValue = selectFilterOptions[filterField];
                if (auxFromTable != null) {
                  let filterValueIDs = $scope.depsData[$scope.deps.indexOf(auxFromTable)].filter(
                    (o) =>
                      o.organization == $scope.currentUser.organization && filterValue.indexOf(o[filterField]) != -1
                  );
                  filterValueIDs = filterValueIDs.map((el) => el.id);
                  if (filteredFromTableData.length == 0) {
                    filteredFromTableData = $scope.depsData[i].filter(
                      (o) =>
                        o.organization == $scope.currentUser.organization &&
                        filterValueIDs.indexOf(o[filterField]) != -1
                    );
                  }
                }
                /* else {
                  filteredFromTableData = filteredFromTableData.filter(o => o.organization == $scope.currentUser.organization && o[field]);
                } */
              }
            }
            return filteredFromTableData;
          }
        }
      }
    };

    $scope.getFilteredOptionsFromDynamicField = function (field, fromTable, dynamicField) {
      if (
        dynamicField != null &&
        $scope.selected[dynamicField] &&
        Array.isArray($scope.selected[dynamicField]) &&
        $scope.selected[dynamicField].length > 0
      ) {
        var i = $scope.deps.indexOf(fromTable);
        let filteredFromTableData = [];
        let dynamicFieldOptions = $scope.selected[dynamicField];
        if (i != -1) {
          filteredFromTableData = $scope.depsData[i].filter(
            (o) =>
              o.organization == $scope.currentUser.organization &&
              JSON.stringify(dynamicFieldOptions).indexOf(o.id) != -1
          );
          return filteredFromTableData;
        }
      }
    };

    $scope.getOptionsFromDynamicField = function (field, dynamicField) {
      if (
        dynamicField != null &&
        $scope.selected[dynamicField] &&
        Array.isArray($scope.selected[dynamicField]) &&
        $scope.selected[dynamicField].length > 0
      ) {
        let options = [];
        $scope.selected[dynamicField].forEach((dynEl) => {
          if (dynEl[field]) {
            options.push(dynEl[field]);
          }
        });
        return options;
      }
    };

    $scope.getOptionsFromCalculatedArray = function (calculatedArrayField) {
      if (
        calculatedArrayField != null &&
        $scope.selected[calculatedArrayField] &&
        Array.isArray($scope.selected[calculatedArrayField]) &&
        $scope.selected[calculatedArrayField].length > 0
      ) {
        return $scope.selected[calculatedArrayField];
      }
    };

    $scope.dynamicFieldSearchFilter = function (field, dynamicElToFilter) {
      let parsedEl = JSON.parse(JSON.stringify($scope.selected));
      return function (element) {
        parsedEl[field] = new Array(element);
        if (
          $scope.dynamicFieldSearch[field] != null &&
          $scope.dynamicFieldSearch[field] != "" &&
          JSON.stringify(parseData($scope.genericScope, [JSON.parse(JSON.stringify(parsedEl))]))
            .toLowerCase()
            .indexOf($scope.dynamicFieldSearch[field].toLowerCase()) == -1
        ) {
          return false;
        }
        return true;
      };
    };

    $scope.customFilterFunction = function (field) {
      return function (element) {
        if ($scope.onloadResult["calculatedFilteredByFields"]) {
          for (const key in $scope.onloadResult["calculatedFilteredByFields"]) {
            if (
              !eval(
                $scope.onloadResult["calculatedFilteredByFields"][key]
                  .replace(new RegExp("{}", "g"), "element")
                  .replace(new RegExp("><", "g"), "$scope")
              )
            )
              return false;
          }
        }

        if ($scope.fieldFilters != null) {
          var filteredBy = $scope.fieldFilters[field];
          if (filteredBy && filteredBy.length > 0) {
            for (let i = 0; i < filteredBy.length; i++) {
              if ($scope.selected[filteredBy[i]] != null) {
                if ($scope.selected[filteredBy[i]] == element.id) continue;
                if ($scope.selected[filteredBy[i]] == element[filteredBy[i]]) continue;
                let filteredData = $scope
                  .getFromTableData($scope.activeTab.fields[field].fromTable)
                  .filter((o) => o.organization == $scope.currentUser.organization && o.id == element)[0];
                if (filteredData != null) {
                  if (!Array.isArray(filteredData[filteredBy[i]])) {
                    if ($scope.selected[filteredBy[i]] == filteredData[filteredBy[i]]) continue;
                  } else {
                    if (filteredData[filteredBy[i]].indexOf($scope.selected[filteredBy[i]]) != -1) continue;
                  }
                }
                if (Array.isArray(element[filteredBy[i]])) {
                  if (element[filteredBy[i]].indexOf($scope.selected[filteredBy[i]]) != -1) continue;
                }
                if (Array.isArray($scope.selected[filteredBy[i]])) {
                  if (
                    $scope.selected[filteredBy[i]].indexOf(element.id) != -1 ||
                    $scope.selected[filteredBy[i]].indexOf(element[filteredBy[i]]) != -1
                  )
                    continue;
                }
                if (Array.isArray($scope.selected[filteredBy[i]]) && Array.isArray(element[filteredBy[i]])) {
                  if ($scope.selected[filteredBy[i]].some((r) => element[filteredBy[i]].indexOf(r) >= 0)) continue;
                }
                return false;
              }
            }
            return true;
          }
          return true;
        }
      };
    };

    $scope.customFilterByNonDynamicFields = function (field) {
      return function (element) {
        if ($scope.onloadResult["dynamicFilteredByFields"] != null) {
          for (var filter in $scope.onloadResult["dynamicFilteredByFields"]) {
            if (
              $scope.activeTab.fields[field].dynamicFields[filter] &&
              $scope.activeTab.fields[field].dynamicFields[filter].filteredByNonDynamicFields
            ) {
              var filteredBy = $scope.onloadResult["dynamicFilteredByFields"][filter];
              if (filteredBy.length > 0) {
                for (let i = 0; i < filteredBy.length; i++) {
                  if ($scope.selected[filteredBy[i]] != null) {
                    if ($scope.selected[filteredBy[i]] == element.id) continue;
                    if ($scope.selected[filteredBy[i]] == element[filteredBy[i]]) continue;
                    if (Array.isArray(element[filteredBy[i]])) {
                      if (element[filteredBy[i]].indexOf($scope.selected[filteredBy[i]]) != -1) continue;
                    }
                    if (Array.isArray($scope.selected[filteredBy[i]])) {
                      if (
                        $scope.selected[filteredBy[i]].indexOf(element.id) != -1 ||
                        $scope.selected[filteredBy[i]].indexOf(element[filteredBy[i]]) != -1
                      )
                        continue;
                    }
                    if (Array.isArray($scope.selected[filteredBy[i]]) && Array.isArray(element[filteredBy[i]])) {
                      if ($scope.selected[filteredBy[i]].some((r) => element[filteredBy[i]].indexOf(r) >= 0)) continue;
                    }
                    return false;
                  } else {
                    return false;
                  }
                }
                return true;
              }
              return true;
            }
          }
        }
      };
    };

    $scope.customDynamicFilterFunction = function (index, field, dynField) {
      return function (element) {
        if (field != null) {
          if ($scope.onloadResult["dynamicFilteredByFields"] != null) {
            var filteredBy = $scope.onloadResult["dynamicFilteredByFields"][dynField];
            if (filteredBy == null) {
              return false;
            }
            if (filteredBy.length > 0) {
              for (let i = 0; i < filteredBy.length; i++) {
                if ($scope.selected[field]) {
                  let dynaEl = $scope.selected[field][index];
                  if (dynaEl != null && dynaEl[filteredBy[i]] != null) {
                    if (dynaEl[filteredBy[i]] == element.id) continue;
                    if (dynaEl[filteredBy[i]] == element[filteredBy[i]]) continue;
                    if (Array.isArray(element[filteredBy[i]])) {
                      if (element[filteredBy[i]].indexOf(dynaEl[filteredBy[i]]) != -1) continue;
                    }
                    return false;
                  }
                }
              }
              return true;
            }
            return true;
          }
        }
      };
    };

    $scope.addDynamicField = function (f) {
      if ($scope.selected[f] == undefined || $scope.selected[f] == null) {
        $scope.selected[f] = [];
      }
      $scope.selected[f].push({});
      removeInputNumScrolls();
    };

    $scope.removeDynamicField = function (f, dynaField) {
      for (let i = 0; i < $scope.selected[f].length; i++) {
        let el = $scope.selected[f][i];
        let flag = true;
        for (const field in el) {
          if (el.hasOwnProperty(field)) {
            const dynaEl = el[field];
            if (dynaEl != dynaField[field]) {
              flag = false;
            }
          }
        }
        if (flag) {
          $scope.selected[f].splice(i, 1);
        }
      }
    };

    $scope.sortDynamicField = function (f, dynaField, index, direction) {
      if ($scope.selected[f] != null && $scope.selected[f].length > 0) {
        if ((direction == "up" && index > 0) || (direction == "down" && index + 1 < $scope.selected[f].length)) {
          let copyOfDynaField = Object.assign({}, dynaField);
          $scope.selected[f].splice(index, 1);
          let nextDynaFieldIndex = direction == "up" ? index - 1 : index + 1;
          $scope.selected[f].splice(nextDynaFieldIndex, 0, copyOfDynaField);
        }
      }
    };

    $scope.duplicateDynamicField = function (f, dynaField, index) {
      let newDuplicateObj = {};
      for (const field in dynaField) {
        if (dynaField.hasOwnProperty(field) && field != "$$hashKey") {
          let dynaEl = dynaField[field];
          newDuplicateObj[field] = dynaEl;
        }
      }
      if (index != null) {
        $scope.selected[f].splice(index, 0, newDuplicateObj);
      } else {
        $scope.selected[f].push(newDuplicateObj);
      }
    };

    $scope.getSelectedCheckbox = function (checkboxFields, fieldName) {
      if (checkboxFields && checkboxFields.length > 0 && fieldName != null && $scope.selected[fieldName + "checkbox"]) {
        return checkboxFields.filter((cF) => $scope.selected[fieldName + "checkbox"][cF.id] == true);
      }
    };

    $scope.getDataSourceData = function (data, field, subEl) {
      if (data != null && field != null && $scope.selected[data] != null) {
        return $scope.selected[data][subEl[field]];
      }
    };

    $scope.getHiddenIf = function (el, hiddenIfExpression, dynField, selected, currUser) {
      if (el != null && hiddenIfExpression != null) {
        return eval(hiddenIfExpression);
      } else {
        return true;
      }
    };

    $scope.checkboxValidation = function () {
      // Normal Checkbox Fields Validation
      if ($scope.onloadResult["checkboxFields"].length > 0) {
        for (let i = 0; i < $scope.onloadResult["checkboxFields"].length; i++) {
          $scope.selected[$scope.onloadResult["checkboxFields"][i]] = [];
          for (const key in $scope.selected[$scope.onloadResult["checkboxFields"][i] + "checkbox"]) {
            if ($scope.selected[$scope.onloadResult["checkboxFields"][i] + "checkbox"][key] == true)
              $scope.selected[$scope.onloadResult["checkboxFields"][i]].push(key);
          }
        }
      }

      // Dynamic Checkbox Fields Validation
      for (let j = 0; j < $scope.onloadResult["dynamicFields"].length; j++) {
        let dynField = activeTab.fields[$scope.onloadResult["dynamicFields"][j]];
        for (const key in dynField.dynamicFields) {
          if (dynField.dynamicFields.hasOwnProperty(key)) {
            let dynEl = dynField.dynamicFields[key];
            if (dynEl.viewType == "checkbox") {
              if ($scope.selected[$scope.onloadResult["dynamicFields"][j]] != undefined) {
                for (let y = 0; y < $scope.selected[$scope.onloadResult["dynamicFields"][j]].length; y++) {
                  const dynamicFieldsEl = $scope.selected[$scope.onloadResult["dynamicFields"][j]][y];
                  dynamicFieldsEl[key] = [];
                  for (const field in dynamicFieldsEl[key + "checkbox"]) {
                    if (dynamicFieldsEl[key + "checkbox"].hasOwnProperty(field)) {
                      const el = dynamicFieldsEl[key + "checkbox"][field];
                      if (el == true) {
                        dynamicFieldsEl[key].push(field);
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    };

    $scope.customFieldValidation = function () {
      let customValidators = {};

      for (const field in $scope.activeTab.fields) {
        if ($scope.activeTab.fields.hasOwnProperty(field)) {
          let f = $scope.activeTab.fields[field];
          if (f.viewType == "dynamicField") {
            for (const dynamicField in f.dynamicFields) {
              if (f.dynamicFields.hasOwnProperty(dynamicField)) {
                let dynField = f.dynamicFields[dynamicField];
                if (dynField.customValidator != null) {
                  customValidators[field + "." + dynamicField] = dynField.customValidator;
                }
              }
            }
          } else {
            if (f.customValidator != null) {
              customValidators[field] = f.customValidator;
            }
          }
        }
      }

      for (const toValidateField in customValidators) {
        if (customValidators.hasOwnProperty(toValidateField)) {
          let validationFunction = customValidators[toValidateField].validationFunction;

          if (toValidateField.indexOf(".") != -1) {
            let fields = toValidateField.split(".");
            let dynamicField = fields[0];
            let dynamicSubField = fields[1];

            for (let j = 0; j < $scope.selected[dynamicField].length; j++) {
              let selectedToValidateField = $scope.selected[dynamicField][j][dynamicSubField];
              if (
                selectedToValidateField != null &&
                selectedToValidateField != "" &&
                !utilFunctions[validationFunction](
                  selectedToValidateField,
                  $scope.selected[dynamicField][j],
                  $scope.activeTab.fields
                )
              ) {
                eval(customValidators[toValidateField].validationDialog);
                return false;
              }
            }
          } else {
            if (
              $scope.selected[toValidateField] != null &&
              $scope.selected[toValidateField] != "" &&
              !utilFunctions[validationFunction](
                $scope.selected[toValidateField],
                $scope.selected,
                $scope.activeTab.fields
              )
            ) {
              eval(customValidators[toValidateField].validationDialog);
              return false;
            }
          }
        }
      }
      return true;
    };

    $scope.customPanelButtonFunction = function (functionName, functionArgs) {
      utilFunctions[functionName]($scope, $scope.selected, $scope.data, functionArgs);
    };

    $scope.customButtonFunction = function (functionName, functionArgs) {
      if (utilFunctions[functionName] !== null && utilFunctions[functionName] !== undefined) {
        utilFunctions[functionName]($scope, functionArgs);
      } else {
        window[functionName]($scope, $mdPanel, functionArgs, $scope.selected, genericScope);
      }
    };

    $scope.disableRequiredFieldCheck = function (requiredFields) {
      if (requiredFields != null && Array.isArray(requiredFields) && requiredFields.length > 0) {
        for (let i = 0; i < requiredFields.length; i++) {
          const requiredField = requiredFields[i];
          if (
            $scope.selected[requiredField] == null ||
            $scope.selected[requiredField] == undefined ||
            $scope.selected[requiredField] == "" ||
            $scope.selected[requiredField] == []
          ) {
            return true;
          }
        }
        return false;
      }
    };

    $scope.checkRequiredFields = function (selected) {
      let emptyRequiredFields = [];

      for (const fieldName in $scope.activeTab.fields) {
        if ($scope.activeTab.fields.hasOwnProperty(fieldName)) {
          let field = $scope.activeTab.fields[fieldName];
          if (field.viewType == "dynamicField") {
            if (selected[fieldName] != null && selected[fieldName] != []) {
              for (let i = 0; i < selected[fieldName].length; i++) {
                const selectedDynamicFieldValue = selected[fieldName][i];
                for (const dynamicFieldName in field.dynamicFields) {
                  if (field.dynamicFields.hasOwnProperty(dynamicFieldName)) {
                    let dynField = field.dynamicFields[dynamicFieldName];

                    if (dynField.required && $scope.isFieldValueEmpty(selectedDynamicFieldValue[dynamicFieldName])) {
                      if (emptyRequiredFields.indexOf(field.label + " - " + dynField.label) == -1) {
                        emptyRequiredFields.push(field.label + " - " + dynField.label);
                      }
                    }
                  }
                }
              }
            }
          } else {
            if (field.requiredExcludedProfiles) {
              if (
                field.required &&
                $scope.getRequiredByProfile(field.required, field.requiredExcludedProfiles) &&
                $scope.isFieldValueEmpty(selected[fieldName])
              ) {
                if (emptyRequiredFields.indexOf(field.label) == -1) {
                  emptyRequiredFields.push(field.label);
                }
              }
              continue;
            }
            if (field.required && $scope.isFieldValueEmpty(selected[fieldName])) {
              if (emptyRequiredFields.indexOf(field.label) == -1) {
                emptyRequiredFields.push(field.label);
              }
            }
          }
        }
      }

      if (emptyRequiredFields.length > 0) {
        parentScope.$parent.alertEmptyRequiredFields(emptyRequiredFields);
        return false;
      } else {
        return true;
      }
    };

    $scope.isFieldValueEmpty = function (fieldValue) {
      if (
        fieldValue == null ||
        fieldValue == undefined ||
        (typeof fieldValue == "string" && fieldValue == "") ||
        fieldValue.length == 0
      ) {
        return true;
      } else {
        return false;
      }
    };

    $scope.selectedItemChange = function selectedItemChange(item, field) {
      if (item != null) {
        $scope.selected[field] = item.id;
      }
    };

    $scope.getFiltered = function getFiltered(data, searchTerm, field) {
      if (searchTerm != null && searchTerm != "") {
        let filteredData = data.filter((o) => o[field].match(searchTerm) != null);
        return filteredData;
      }
    };

    $scope.getRequiredByProfile = function getRequiredByProfile(requiredValue, requiredExcludedProfiles) {
      if (requiredValue != null && requiredExcludedProfiles != null) {
        if (requiredValue == true && requiredExcludedProfiles.indexOf($scope.currentUser.profile) == -1) {
          return true;
        } else {
          return false;
        }
      }
    };

    $scope.getDynamicFieldControlByProfile = function getDynamicFieldControlByProfile(
      dynamicFieldControlByProfile,
      control
    ) {
      if (dynamicFieldControlByProfile != null && control != null) {
        if (dynamicFieldControlByProfile[control] != null) {
          return dynamicFieldControlByProfile[control].indexOf($scope.currentUser.profile) != -1;
        } else {
          return true;
        }
      }
    };

    $scope.onFileUpload = function onFileUpload(event, field) {
      if (event.target.files.length > 0) {
        $scope.filesToUploadFlag = true;
        let excludedFiles = [];
        let filesToUpload = [];
        let filesTotalSize = 0;
        for (let f = 0; f < event.target.files.length; f++) {
          let file = event.target.files[f];
          // Remove files which are > 10mb
          if (file.size > 10485760) {
            excludedFiles.push(event.target.files[f].name);
          } else {
            filesTotalSize += file.size;
            filesToUpload.push(file);
          }
        }
        if (filesTotalSize > 10485760 || excludedFiles.length) {
          parentScope.$parent.filesHaveMoreThan10MBWarning();
          // Clean specific input if no files can be uploaded
          let uploadInput = document.getElementById("fileUpload" + field);
          uploadInput.value = null;
        } else if (filesToUpload.length) {
          $scope.filesToUploadByField[field] = filesToUpload;
          $scope.numFilesToUpload += filesToUpload.length;
        } else {
          // Clean specific input if no files can be uploaded
          let uploadInput = document.getElementById("fileUpload" + field);
          uploadInput.value = null;
        }
      } else {
        $scope.filesToUploadFlag = false;
        $scope.filesToUploadByField[field] = null;
      }
    };

    $scope.downloadFile = function downloadFile(file) {
      let containerName = $scope.currentUser.organization + $scope.module.collection;
      const linkSource = "/api/containers/" + containerName + "/download/" + file.name;
      const downloadLink = document.createElement("a");
      const fN = $scope.parseFileName(file);
      downloadLink.href = linkSource;
      downloadLink.download = fN;
      downloadLink.click();
    };

    $scope.deletedUploadedFiles = function deletedUploadedFiles(field, specificFile) {
      if ($scope.uploadedFilesByField) {
        $scope.uploadedFilesByField[field] = [];
      }
      let containerName = $scope.currentUser.organization + $scope.module.collection;
      storageFactory.getContainer(containerName).then((ct) => {
        storageFactory.listFilesInContainer(containerName).then((fileList) => {
          //Delete all files related to selected id
          for (let k = 0; k < fileList.data.length; k++) {
            let storedFile = fileList.data[k];
            if (specificFile != null && storedFile.name == specificFile.name) {
              storageFactory.deleteFile(containerName, storedFile.name);
            } else if (specificFile == null && storedFile.name.indexOf($scope.selected.id) != -1) {
              storageFactory.deleteFile(containerName, storedFile.name);
            } else {
              if (storedFile.name.indexOf($scope.selected.id) != -1) {
                $scope.uploadedFilesByField[field].push(storedFile);
              }
            }
          }
        });
      });
    };

    $scope.parseFileName = parseFileName;

    $scope.checkFilesToUpload = function checkFilesToUpload(selected, closePanelFlag) {
      for (let field in $scope.activeTab.fields) {
        if ($scope.activeTab.fields.hasOwnProperty(field)) {
          let fieldDesc = $scope.activeTab.fields[field];
          if (fieldDesc.viewType == "fileUpload") {
            //Container name
            let containerName = $scope.currentUser.organization + $scope.module.collection;
            storageFactory
              .getContainer(containerName)
              .then((ct) => {
                storageFactory.listFilesInContainer(containerName).then((fileList) => {
                  //Delete all files related to selected id if necessary
                  if (fieldDesc.replaceFiles) {
                    for (let k = 0; k < fileList.data.length; k++) {
                      let storedFile = fileList.data[k];
                      if (storedFile.name.indexOf(selected.id) != -1) {
                        storageFactory.deleteFile(containerName, storedFile.name);
                      }
                    }
                  }
                  if ($scope.filesToUploadByField[field]) {
                    for (let k = 0; k < $scope.filesToUploadByField[field].length; k++) {
                      let file = $scope.filesToUploadByField[field][k];
                      let fileExtension = file.name.split(".");
                      fileExtension = fileExtension[fileExtension.length - 1];
                      let blob;
                      if (
                        fileExtension == "jpg" ||
                        fileExtension == "jpeg" ||
                        fileExtension == "JPEG" ||
                        fileExtension == "JPG" ||
                        fileExtension == "png" ||
                        fileExtension == "PNG"
                      ) {
                        blob = file.slice(0, file.size, "image/" + fileExtension);
                        //Create new file with selected id + original name as new file name
                        file = new Array(
                          new File([blob], selected.id + "-" + file.name, {
                            type: "image/" + fileExtension,
                          })
                        );
                      } else {
                        blob = file.slice(0, file.size);
                        //Create new file with selected id + original name as new file name
                        file = new Array(new File([blob], selected.id + "-" + file.name));
                      }
                      $scope.checkIfCanReduceBeforeUpload(
                        selected,
                        containerName,
                        file,
                        fileExtension,
                        closePanelFlag,
                        fieldDesc.reduceUploadedImage
                      );
                    }
                  } else if ($scope.uploadedFilesByField[field]) {
                    let fileExtension = $scope.uploadedFilesByField[field][0].name.split(".");
                    let img = document.createElement("img");
                    img.src =
                      "/api/containers/" + containerName + "/download/" + $scope.uploadedFilesByField[field][0].name;
                    //img.src = 'http://0.0.0.0:3000/api/containers/' + containerName + '/download/' + $scope.uploadedFilesByField[field][0].name;
                    img.onload = function () {
                      let c = document.createElement("canvas");
                      c.width = img.width;
                      c.height = img.height;
                      let ctx = c.getContext("2d");
                      ctx.drawImage(img, 0, 0);
                      let imgDataURL = c.toDataURL();
                      let imgBase64Data = imgDataURL.slice(imgDataURL.indexOf(",") + 1);
                      let file = new File(
                        [b64toBlob(imgBase64Data, "image/" + fileExtension[fileExtension.length - 1])],
                        selected.id + "." + fileExtension[fileExtension.length - 1],
                        {
                          type: "image/" + fileExtension[fileExtension.length - 1],
                        }
                      );
                      storageFactory.uploadFile(containerName, [file]);
                    };
                  }
                });
              })
              .catch((response) => {
                if (response.status === 404) {
                  if ($scope.filesToUploadByField[field]) {
                    for (let k = 0; k < $scope.filesToUploadByField[field].length; k++) {
                      let file = $scope.filesToUploadByField[field][k];
                      let fileExtension = file.name.split(".");
                      fileExtension = fileExtension[fileExtension.length - 1];
                      let blob;
                      if (
                        fileExtension == "jpg" ||
                        fileExtension == "jpeg" ||
                        fileExtension == "JPEG" ||
                        fileExtension == "JPG" ||
                        fileExtension == "png" ||
                        fileExtension == "PNG"
                      ) {
                        blob = file.slice(0, file.size, "image/" + fileExtension);
                        //Create new file with selected id + original name as new file name
                        file = new Array(
                          new File([blob], selected.id + "-" + file.name, {
                            type: "image/" + fileExtension,
                          })
                        );
                      } else {
                        blob = file.slice(0, file.size);
                        //Create new file with selected id + original name as new file name
                        file = new Array(new File([blob], selected.id + "-" + file.name));
                      }
                      $scope.checkIfCanReduceBeforeUpload(
                        selected,
                        containerName,
                        file,
                        fileExtension,
                        closePanelFlag,
                        fieldDesc.reduceUploadedImage
                      );
                    }
                  }
                }
              });
          }
        }
      }
    };

    $scope.checkIfCanReduceBeforeUpload = function checkIfCanReduceBeforeUpload(
      selected,
      container,
      file,
      fileExtension,
      closePanelFlag,
      reduceUploadedImageFlag
    ) {
      if (
        reduceUploadedImageFlag != null &&
        (fileExtension == "jpg" ||
          fileExtension == "jpeg" ||
          fileExtension == "JPEG" ||
          fileExtension == "JPG" ||
          fileExtension == "png" ||
          fileExtension == "PNG")
      ) {
        var reader = new FileReader();
        reader.readAsBinaryString(file[0]);
        reader.onloadend = function () {
          getReducedImage($scope, reader.result)
            .then(function (response) {
              return response.json();
            })
            .then(function (response) {
              let responseData = response.$data;
              let f = new File([b64toBlob(responseData, "image/" + fileExtension)], file[0].name, {
                type: "image/" + fileExtension,
              });
              storageFactory.uploadFile(container, [f]);
              /* const linkSource = `data:application/jpg;base64,${responseData}`;
            const downloadLink = document.createElement("a");
            const fileName = "test.jpg";
            downloadLink.href = linkSource;
            downloadLink.download = fileName;
            downloadLink.click(); */
            });
        };
      } else {
        storageFactory.uploadFile(container, file);
      }
      $scope.numUploadedFiles += 1;
      if (
        (closePanelFlag == null || (closePanelFlag != null && closePanelFlag == true)) &&
        $scope.numUploadedFiles == $scope.numFilesToUpload
      ) {
        parentScope.$parent.successfulSave();
        genericScope.closePanel();
      }
    };
  },
]);
