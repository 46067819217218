var app = angular.module("analytics");

app.controller("MainCtrl", [
  "$scope",
  "clientsFactory",
  "clientPreferencesFactory",
  "localizationFactory",
  "categoryFactory",
  "organizationFactory",
  "reportFactory",
  "permissionsFactory",
  "$mdSidenav",
  "$state",
  "$stateParams",
  "exportUiGridService",
  "uiGridGroupingConstants",
  "moduleFactory",
  "$mdToast",
  "$mdDialog",
  "logsFactory",
  "$http",
  "smartContentsFactory",
  "storageFactory",
  "$timeout",
  function (
    $scope,
    clientsFactory,
    clientPreferencesFactory,
    localizationFactory,
    categoryFactory,
    organizationFactory,
    reportFactory,
    permissionsFactory,
    $mdSidenav,
    $state,
    $stateParams,
    exportUiGridService,
    uiGridGroupingConstants,
    moduleFactory,
    $mdToast,
    $mdDialog,
    logsFactory,
    $http,
    smartContentsFactory,
    storageFactory,
    $timeout
  ) {
    var self = this;

    self.gotMenuData = false;
    self.gotCategoryData = false;
    self.gotOrganizationData = false;

    $scope.showMainLoader = false;
    $scope.showDeterminateMainLoaderFlag = false;
    $scope.determinateMainLoaderProcessedItems = 0;
    $scope.determinateMainLoaderTotalItems = 0;
    $scope.determinateMainLoaderPercentageProgress = 0;
    $scope.showFLoader = false;

    $scope.client = clientsFactory;
    $scope.report = reportFactory;
    $scope.userModules = [];
    $scope.title = "Indicadores";
    $scope.clientLogo = "";
    $scope.clientOrgName = "";
    $scope.clientHasSponsorsLogo = false;
    $scope.clientHasOrganizationLogo = false;
    $scope.hideCat = [];

    $scope.reportData;

    $scope.currentModuleReport = null;

    $scope.clientPreferences = null;

    $scope.refreshSmartContentsControllers = function () {
      $scope.refreshSCControllers = true;
      $timeout(function () {
        $scope.refreshSCControllers = false;
      }, 0);
    };

    $scope.refreshSmartContents = function () {
      $scope.refreshSC = true;
      $timeout(function () {
        $scope.refreshSC = false;
      }, 0);
    };

    $scope.openCloseSmartContents = function (mode) {
      if ($scope.user.hasToResetPassword) {
        return;
      }
      if (mode) {
        if ($scope.smartContentsMode != mode) {
          $scope.openSmartContents = true;
          $scope.smartContentsMode = mode;
        } else {
          $scope.openSmartContents = false;
          $scope.smartContentsMode = null;
        }
      } else {
        if ($scope.openSmartContents) {
          $scope.openSmartContents = !$scope.openSmartContents;
        } else {
          $scope.openSmartContents = true;
        }
      }
      $scope.refreshSmartContents();
    };

    // Handle cat module report click

    $scope.lastSelectedCat;
    $scope.lastSelectedModuleReport;

    $scope.catModuleReportClick = function (elementId, mode, arg1, arg2) {
      if ($scope.user.hasToResetPassword) {
        return;
      }
      if (arg1 == "home") {
        if ($scope.lastSelectedCat != null) {
          document.getElementById($scope.lastSelectedCat).classList.remove("kstkSelectedSideMenuEntry");
          $scope.lastSelectedCat = null;
        }
        if ($scope.lastSelectedModuleReport != null) {
          document.getElementById($scope.lastSelectedModuleReport).classList.remove("kstkSelectedSideMenuEntry");
          $scope.lastSelectedModuleReport = null;
        }
        $scope.currentModuleReport = null;
        $scope.smartContentsMode = null;
        $scope.atHomepage = true;
        $state.go("home");
        return;
      }

      $scope.atHomepage = false;
      if ($scope.smartContentsMode != null) {
        $scope.openSmartContents = true;
      } else {
        $scope.openSmartContents = false;
      }

      let catElementId;
      let subItemElementId;

      // Get cat and subItem IDs
      if (elementId.length > 30) {
        catElementId = elementId.substring(0, 30);
        subItemElementId = elementId;
      } else {
        catElementId = elementId;
      }

      // Activate cat html element
      if (catElementId != null && catElementId != $scope.lastSelectedCat) {
        let element = document.getElementById(catElementId);
        element.classList.add("kstkSelectedSideMenuEntry");
        if ($scope.lastSelectedCat != null) {
          document.getElementById($scope.lastSelectedCat).classList.remove("kstkSelectedSideMenuEntry");
        }
        $scope.lastSelectedCat = catElementId;
      }

      // Activate subItem html element
      if (subItemElementId != $scope.lastSelectedModuleReport) {
        if (subItemElementId != null) {
          let element = document.getElementById(subItemElementId);
          if (element) {
            element.classList.add("kstkSelectedSideMenuEntry");
            if ($scope.lastSelectedModuleReport != null) {
              document.getElementById($scope.lastSelectedModuleReport).classList.remove("kstkSelectedSideMenuEntry");
            }
            $scope.lastSelectedModuleReport = subItemElementId;
          }
        } else {
          if ($scope.lastSelectedModuleReport != null) {
            document.getElementById($scope.lastSelectedModuleReport).classList.remove("kstkSelectedSideMenuEntry");
          }
          $scope.lastSelectedModuleReport = null;
        }
      }

      // Call the right function to change page or go to module/report
      if (mode == "changePage") {
        $scope.currentModuleReport = null;
        $scope.changePage(arg1, arg2);
      } else if (mode == "moduleReportClick") {
        $scope.currentModuleReport = arg1;
        $scope.moduleReportClick(arg1);
      }
      $scope.refreshSmartContentsControllers();
    };

    // Menu click function

    $scope.moduleReportClick = function (menuEntry) {
      $scope.addRecentlyViewed(menuEntry);
      if (menuEntry.tabs != null && typeof menuEntry.tabs == "object") {
        $scope.changePage("genericModule", {
          moduleCollection: menuEntry.collection,
          moduleValue: menuEntry.value,
        });
      } else if (menuEntry.src == null && menuEntry.reportBuilderSrc == null) {
        $scope.changePage(menuEntry.value);
      } else if (menuEntry.tabs == null) {
        $scope.changePage(menuEntry);
      }
    };

    // Change page function

    $scope.changePage = function (newPage, args) {
      if (typeof newPage == "string") {
        if (newPage != "home") {
          $scope.atHomepage = false;
        }
        if (args == null) {
          $state.go(newPage);
        } else {
          $state.go(newPage, args);
        }
      } else if (newPage.tabs != null) {
        if (args == null) {
          $state.go(newPage.destination);
        } else {
          $state.go(newPage.destination, args);
        }
      } else {
        reportFactory.setSelectedReport(newPage);
        $state.transitionTo($state.current, $stateParams, {
          reload: true,
          inherit: false,
          notify: true,
        });

        $state.go("report", null, {
          reload: true,
        });
      }
    };

    // Change language

    $scope.changeLanguage = function (lang, arg, footerLang) {
      localizationFactory.changeLanguage(lang, arg, footerLang);
    };

    // Translate text

    $scope.translateText = function (text) {
      return localizationFactory.translateText(text);
    };

    // Get language

    $scope.getLanguage = function () {
      return localizationFactory.getLanguage();
    };

    // Change platform language based on the users language field

    if ($scope.getLanguage() == null) {
      if (navigator.language == "pt" || navigator.language == "pt-PT") {
        $scope.changeLanguage("pt-PT");
      } else {
        $scope.changeLanguage("en-GB");
      }
    }

    // Add module/report to recently viewed

    $scope.addRecentlyViewed = function (menuEntry) {
      if ($scope.clientPreferences) {
        if ($scope.clientPreferences.recentlyViewed == null) {
          $scope.clientPreferences.recentlyViewed = [];
        }
        // Check if module/report was already added
        if ($scope.clientPreferences.recentlyViewed.length > 0) {
          for (let i = 0; i < $scope.clientPreferences.recentlyViewed.length; i++) {
            const recentlyViewed = $scope.clientPreferences.recentlyViewed[i];
            if (recentlyViewed.id == menuEntry.id) {
              if (i == 0) {
                return;
              }
              $scope.clientPreferences.recentlyViewed.splice(i, 1);
            }
          }
        }
        // Restrict list to 3 elements
        if ($scope.clientPreferences.recentlyViewed.length == 3) {
          $scope.clientPreferences.recentlyViewed.pop();
        }
        $scope.clientPreferences.recentlyViewed.unshift($scope.cleanUpMenuEntry(menuEntry));
        clientPreferencesFactory.modify($scope.clientPreferences.id, $scope.clientPreferences).then(function () {});
      }
    };

    // Add module/report to favorites

    $scope.getFavoriteStatus = function (menuEntry) {
      if (
        menuEntry != null &&
        $scope.clientPreferences &&
        $scope.clientPreferences.favorites &&
        $scope.clientPreferences.favorites.length > 0
      ) {
        for (let i = 0; i < $scope.clientPreferences.favorites.length; i++) {
          const favorite = $scope.clientPreferences.favorites[i];
          if (favorite.id == menuEntry.id) {
            return true;
          }
        }
      }
      return false;
    };

    // Add module/report to favorites
    $scope.addRemoveFavorites = function (menuEntry) {
      if ($scope.clientPreferences.favorites == null) {
        $scope.clientPreferences.favorites = [];
      }
      // Remove favorite
      if ($scope.clientPreferences.favorites.length > 0) {
        for (let i = 0; i < $scope.clientPreferences.favorites.length; i++) {
          const favorite = $scope.clientPreferences.favorites[i];
          if (favorite.id == menuEntry.id) {
            $scope.clientPreferences.favorites.splice(i, 1);
            clientPreferencesFactory.modify($scope.clientPreferences.id, $scope.clientPreferences).then(function () {});
            return;
          }
        }
      }
      // Add favorite
      if ($scope.clientPreferences.favorites.length == 10) {
        $mdDialog.show(
          $mdDialog
            .alert()
            .clickOutsideToClose(true)
            .title("Limite de Favoritos")
            .textContent("Para adicionar mais favoritos deve remover um dos existentes.")
            .ariaLabel("Limite de Favoritos")
            .ok("Ok")
        );
      } else {
        $scope.clientPreferences.favorites.push($scope.cleanUpMenuEntry(menuEntry));
        clientPreferencesFactory.modify($scope.clientPreferences.id, $scope.clientPreferences).then(function () {
          $scope.favoriteAdded();
        });
      }
    };

    $scope.cleanUpMenuEntry = function (menuEntry) {
      let cleanMenuEntry = {};
      cleanMenuEntry.id = menuEntry.id;
      cleanMenuEntry.name = menuEntry.name;
      cleanMenuEntry.category = menuEntry.category;
      cleanMenuEntry.collection = menuEntry.collection;
      cleanMenuEntry.value = menuEntry.value;
      if (menuEntry.reportBuilderSrc != null) {
        cleanMenuEntry.reportBuilderSrc = menuEntry.reportBuilderSrc;
      } else {
        cleanMenuEntry.src = menuEntry.src;
      }
      cleanMenuEntry.icon = menuEntry.icon;
      if (menuEntry.tabs != null && typeof menuEntry.tabs == "object") {
        cleanMenuEntry.tabs = {};
      }
      return cleanMenuEntry;
    };

    $scope.cleanUpRecentlyViewedAndFavorites = function () {
      let reloadFlag = false;
      // Remove Favorites elements that user has no access
      if (
        $scope.clientPreferences &&
        $scope.clientPreferences.favorites &&
        $scope.clientPreferences.favorites.length > 0
      ) {
        let favorites = [];
        $scope.clientPreferences.favorites.forEach((favortie) => {
          favorites.push(favortie.id);
        });
        if (favorites.length > 0) {
          let indexOfFavoritesToRemove = [];
          for (let i = 0; i < favorites.length; i++) {
            let favorite = favorites[i];
            let favoriteOptionInMenuList = $scope.menuList.filter((el) => el.id == favorite);
            if (favoriteOptionInMenuList.length == 0) {
              indexOfFavoritesToRemove.push(i);
            }
          }
          if (indexOfFavoritesToRemove.length > 0) {
            indexOfFavoritesToRemove.forEach((index) => {
              $scope.clientPreferences.favorites.splice(index, 1);
            });
            reloadFlag = true;
          }
        }
      }
      // Remove Recently Viewed elements that user has no access
      if (
        $scope.clientPreferences &&
        $scope.clientPreferences.recentlyViewed &&
        $scope.clientPreferences.recentlyViewed.length > 0
      ) {
        let recentlyVieweds = [];
        $scope.clientPreferences.recentlyViewed.forEach((recentlyViewed) => {
          recentlyVieweds.push(recentlyViewed.id);
        });
        if (recentlyVieweds.length > 0) {
          let indexOfRecentlyViewedToRemove = [];
          for (let i = 0; i < recentlyVieweds.length; i++) {
            let recentlyViewed = recentlyVieweds[i];
            let recentlyViewedOptionInMenuList = $scope.menuList.filter((el) => el.id == recentlyViewed);
            if (recentlyViewedOptionInMenuList.length == 0) {
              indexOfRecentlyViewedToRemove.push(i);
            }
          }
          if (indexOfRecentlyViewedToRemove.length > 0) {
            indexOfRecentlyViewedToRemove.forEach((index) => {
              $scope.clientPreferences.recentlyViewed.splice(index, 1);
            });
            reloadFlag = true;
          }
        }
      }
      if (reloadFlag) {
        clientPreferencesFactory.modify($scope.clientPreferences.id, $scope.clientPreferences).then(function () {
          window.location.reload();
        });
      }
    };

    $scope.getSmartContents = function () {
      if ($scope.org && $scope.org.orgProducts) {
        smartContentsFactory.getAll().then(function (smartContents) {
          $scope.smartContents = smartContents;
          $scope.smartContents = $scope.smartContents.filter(function getUserSmartContents(sG) {
            // Filter hidden smart contents
            if (sG.hiddencheckbox && sG.hiddencheckbox[""] && sG.hiddencheckbox[""] == true) {
              return false;
            }
            // Accept smart contents are for all organizations
            if (
              (sG.envolvedOrganizations == null ||
                (Array.isArray(sG.envolvedOrganizations) && sG.envolvedOrganizations.length == 0)) &&
              (sG.product == null || (Array.isArray(sG.product) && sG.product.length == 0)) &&
              (sG.smartContentModulesEnvolved == null ||
                (Array.isArray(sG.smartContentModulesEnvolved) && sG.smartContentModulesEnvolved.length == 0))
            ) {
              return true;
            }
            // Filter smart contents by org
            if (
              sG.envolvedOrganizations &&
              Array.isArray(sG.envolvedOrganizations) &&
              sG.envolvedOrganizations.length > 0
            ) {
              let filteredSmartContentsByEnvolvedOrgs = sG.envolvedOrganizations.filter(
                (org) => $scope.client.getUser().user.organization == org
              );
              if (filteredSmartContentsByEnvolvedOrgs.length > 0) {
                return true;
              } else {
                return false;
              }
            }
            // Filter smart contents by org products
            if (
              sG.product &&
              Array.isArray(sG.product) &&
              sG.product.length > 0 &&
              Array.isArray($scope.org.orgProducts) &&
              $scope.org.orgProducts.length > 0
            ) {
              let filteredProductSmartContent = sG.product.filter((prod) => $scope.org.orgProducts.indexOf(prod) != -1);
              if (filteredProductSmartContent.length > 0) {
                return true;
              } else {
                return false;
              }
            }
            // Filter smart contents by modules envolved
            if (
              sG.smartContentModulesEnvolved &&
              Array.isArray(sG.smartContentModulesEnvolved) &&
              sG.smartContentModulesEnvolved.length > 0
            ) {
              let smartContentModulesEnvolved = [];
              sG.smartContentModulesEnvolved.forEach((module) => {
                smartContentModulesEnvolved.push(module.name);
              });
              let menuListModules = [];
              menuListModules = $scope.menuList.filter((el) => el.tabs != null && el.tabs != 0);
              if (smartContentModulesEnvolved.length > 0) {
                let filteredMenuList = $scope.menuList.filter((el) => smartContentModulesEnvolved.indexOf(el.id) != -1);
                // Include smart content if the user has exactly the envolved modules
                if (
                  sG.modulesEnvolvedExclusivecheckbox &&
                  sG.modulesEnvolvedExclusivecheckbox[""] &&
                  sG.modulesEnvolvedExclusivecheckbox[""] == true
                ) {
                  if (menuListModules.length != smartContentModulesEnvolved.length) {
                    return false;
                  } else {
                    return true;
                  }
                }
                // Include smart content if the user has all of the envolved modules
                else {
                  if (filteredMenuList.length != smartContentModulesEnvolved.length) {
                    return false;
                  } else {
                    return true;
                  }
                }
              }
            }

            return true;
          });

          if ($scope.smartContents.length > 0) {
            $scope.getSmartContentsAttachedFiles();
          }
        });
      }
    };

    $scope.getSmartContentsAttachedFiles = function () {
      let containerName = "organizationKSTK_Smart_Contents";
      storageFactory
        .getContainer(containerName)
        .then((ct) => {
          storageFactory.listFilesInContainer(containerName).then((fileList) => {
            $scope.smartContents.forEach((smartContent) => {
              //Get files by smartContent id
              smartContent.uploadedFiles = [];
              for (let k = 0; k < fileList.data.length; k++) {
                let storedFile = fileList.data[k];
                if (storedFile.name.indexOf(smartContent.id) != -1) {
                  smartContent.uploadedFiles.push(storedFile);
                }
              }
            });
            $scope.refreshSmartContents();
          });
        })
        .catch((response) => {
          if (response.status === 404) {
            //catch error
          }
        });
    };

    $scope.downloadSmartContentsAttachedFiles = function (event, smartContentId) {
      let containerName = "organizationKSTK_Smart_Contents";
      storageFactory
        .getContainer(containerName)
        .then((ct) => {
          storageFactory.listFilesInContainer(containerName).then((fileList) => {
            //Download files by smartContent id
            for (let k = 0; k < fileList.data.length; k++) {
              let storedFile = fileList.data[k];
              if (storedFile.name.indexOf(smartContentId) != -1) {
                const linkSource = "/api/containers/" + containerName + "/download/" + storedFile.name;
                const downloadLink = document.createElement("a");
                const fN = parseFileName(storedFile);
                downloadLink.href = linkSource;
                downloadLink.download = fN;
                downloadLink.click();
              }
            }
          });
        })
        .catch((response) => {
          if (response.status === 404) {
            //catch error
          }
        });
    };

    $scope.profileMenuList = [
      {
        destination: "products",
        icon: "folder",
        name: "Produtos",
        admin: true,
        order: 1,
      },
      {
        destination: "categories",
        icon: "folder",
        name: "Categorias",
        admin: true,
        order: 2,
      },
      {
        destination: "organizations",
        icon: "business",
        name: "Organizações",
        admin: true,
        order: 3,
      },
      {
        destination: "reports",
        icon: "pie_chart_outline",
        name: "Reports",
        admin: true,
        order: 4,
      },
      {
        destination: "genericModule",
        args: { moduleCollection: "KSTK_Report_Permissions", moduleValue: "kstk_report_permissions" },
        icon: "pie_chart_outline",
        name: "Permissões de Reports",
        admin: true,
        order: 5,
      },
      {
        destination: "modules",
        icon: "pie_chart_outline",
        name: "Módulos",
        admin: true,
        order: 6,
      },
      {
        destination: "genericModule",
        args: { moduleCollection: "KSTK_Smart_Contents", moduleValue: "kstk_smart_contents" },
        icon: "pie_chart_outline",
        name: "Smart Contents",
        admin: true,
        order: 7,
      },
      /* {
        destination: "genericModule",
        args: { moduleCollection: "Clients", moduleValue: "userList" },
        icon: "share",
        name: "Utilizadores",
        admin: true,
        order: 7,
      }, */
      {
        destination: "permissions",
        icon: "share",
        name: $scope.translateText("permissions"),
        admin: true,
        orgAdmin: true,
        cpAdmin: true,
        vfxEduAdmin: true,
        order: 8,
      },
      /* {
        destination: "logs",
        icon: "book",
        name: "Logs",
        admin: true,
        order: 0,
      }, */
      {
        destination: "genericModule",
        args: { moduleCollection: "Logs", moduleValue: "kstk_logs" },
        icon: "book",
        name: "Logs",
        admin: true,
        order: 0,
      },
      {
        destination: "client",
        icon: "perm_identity",
        name: $scope.translateText("settings"),
        admin: false,
        order: 98,
      },
      {
        action: "logout",
        icon: "logout",
        name: $scope.translateText("logout"),
        admin: false,
        order: 99,
      },
    ];

    // if ($scope.client.getUser().user) {

    //     categoryFactory.getAll().then(function (categoryData) {
    //         $scope.categoryData = categoryData;
    //         self.gotCategoryData = true;

    //         $scope.getMenuData($scope);
    //     });

    //     organizationFactory.getAll().then(function (organizationData) {
    //         $scope.organizationData = organizationData;
    //         self.gotOrganizationData = true;

    //         $scope.getMenuData($scope);
    //     });

    // }

    clientsFactory.loginCallback = function (data) {
      if (data) {
        $scope.clientData = data;
      }

      // Send as props to the React header
      $scope.user = $scope.client.getUser().user;

      // Get user favorites, recently viewed...
      clientPreferencesFactory.getByProperty("user", $scope.user.id).then(function (preferencesData) {
        if (preferencesData.length > 0) {
          $scope.clientPreferences = preferencesData[0];
        }
      });

      self.gotMenuData = true;

      //$scope.getMenuData($scope);
      categoryFactory.getAll().then(function (categoryData) {
        $scope.categoryData = categoryData;
        self.gotCategoryData = true;

        $scope.getMenuData($scope);
      });

      organizationFactory.getAll().then(function (organizationData) {
        $scope.organizationData = organizationData;
        self.gotOrganizationData = true;

        $scope.getMenuData($scope);

        var clientOrg = $scope.client.getUser().user.organization;
        organizationData.forEach((org) => {
          if (org.id === clientOrg) {
            // Org info object to send to React
            $scope.org = {};

            $scope.clientLogo = org.logo;
            $scope.clientOrgName = org.name;
            $scope.clientHasSponsorsLogo = org.hasSponsorsLogo;
            $scope.clientHasOrganizationLogo = org.hasOrganizationLogo;

            $scope.org.orgName = $scope.clientOrgName;
            $scope.org.orgProducts = org.orgProducts;
            $scope.org.orgType = org.orgType;
            if ($scope.user) {
              $scope.user.orgName = org.name;
              if ($scope.clientHasOrganizationLogo) {
                $scope.org.orgLogoSrc = "/api/containers/" + $scope.user.organization + "-logos/download/orgLogo.svg";
              }
              if ($scope.clientHasSponsorsLogo) {
                $scope.org.sponsorsLogoSrc =
                  "/api/containers/" + $scope.user.organization + "-logos/download/sponsorsLogo.png";
              }
            }
          }
        });
      });

      reportFactory.getAll();

      /* permissionsFactory.getByProperty('user', $scope.client.getUser().user.id).then(function (permissionData) {
        if (permissionData.length) {
          var permModules = permissionData[0].modules;

          var userModules = [];

          for (var i = 0; i < permModules.length; i++) {
            var currMod = permModules[i];

            var tempMod = $scope.modulesData.filter(el => el.value === currMod);

            if (tempMod.length > 0) {
              userModules.push(tempMod[0]);
            }
          }

          //$scope.profileMenuList = $scope.profileMenuList.concat(permModules);
          $scope.permModules = userModules;

        }
      }); */
    };

    $scope.getMenuData = function ($scope) {
      if (self.gotCategoryData && self.gotOrganizationData && self.gotMenuData) {
        var org = $scope.client.getUser().user.organization;

        reportFactory.getByOrganizationId(org).then(function (reportData) {
          $scope.reportData = reportData;
          moduleFactory.getAll().then(function (modulesData) {
            $scope.modulesData = modulesData;
            let menu = {};
            let menuList = [];

            permissionsFactory.getByProperty("user", $scope.client.getUser().user.id).then(function (permissionData) {
              if (permissionData.length) {
                $scope.userPermissions = permissionData[0];
                let permReports = permissionData[0].reports;
                let permModules = permissionData[0].modules;

                for (let j = 0; j < permModules.length; j++) {
                  let permMod = permModules[j];
                  $scope.modulesData.forEach((mod) => {
                    if (mod.value == permMod && !mod.disabled) {
                      $scope.userModules.push(mod);
                    }
                  });
                }

                $scope.categoryData
                  .sort(function compareCategories(a, b) {
                    if (a.order < b.order) {
                      return -1;
                    }

                    if (a.order > b.order) {
                      return 1;
                    }

                    return 0;
                  })
                  .forEach((cat) => {
                    menu[cat.id] = cat;
                    menu[cat.id].reports = [];

                    // menuList.push({
                    //     name: cat.name,
                    //     isCat: null
                    // });

                    let tempRepMenu = [];

                    $scope.reportData
                      .sort(function compareReports(a, b) {
                        if (a.order < b.order) {
                          return -1;
                        }

                        if (a.order > b.order) {
                          return 1;
                        }

                        return 0;
                      })
                      .forEach((rep) => {
                        if (rep.organization === org && rep.category === cat.id && permReports.indexOf(rep.id) > -1) {
                          let tempRep = rep;

                          tempRep.href = "#/report";
                          if (cat.icon) {
                            tempRep.icon = cat.icon;
                          }
                          menu[cat.id].reports.push(tempRep);

                          tempRepMenu.push(tempRep);
                        }
                      });

                    if (tempRepMenu.length) {
                      menuList.push({
                        name: cat.name,
                        isCat: null,
                        id: cat.id,
                        reportCat: true,
                        icon: cat.icon,
                      });

                      menuList = menuList.concat(tempRepMenu);
                    }
                  });

                $scope.categoryData
                  .sort(function compareCategories(a, b) {
                    if (a.moduleOrder == null) {
                      a.moduleOrder = 99;
                    }

                    if (b.moduleOrder == null) {
                      b.moduleOrder = 99;
                    }

                    if (a.moduleOrder < b.moduleOrder) {
                      return -1;
                    }

                    if (a.moduleOrder > b.moduleOrder) {
                      return 1;
                    }

                    /* if (a.order < b.order) {
                      return -1;
                    }

                    if (a.order > b.order) {
                      return 1;
                    } */

                    return 0;
                  })
                  .forEach((cat) => {
                    menu[cat.id] = cat;
                    menu[cat.id].modules = [];

                    // menuList.push({
                    //     name: cat.name,
                    //     isCat: null
                    // });

                    let tempModMenu = [];

                    $scope.modulesData
                      .sort(function compareModules(a, b) {
                        if (
                          a.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "") <
                          b.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
                        ) {
                          return -1;
                        }

                        if (
                          a.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "") >
                          b.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
                        ) {
                          return 1;
                        }

                        return 0;
                      })
                      .forEach((mod) => {
                        if (mod.category === cat.id && permModules.indexOf(mod.value) > -1 && !mod.disabled) {
                          let tempMod = mod;

                          tempMod.href = "/#/" + mod.destination;
                          if (cat.icon) {
                            tempMod.icon = cat.icon;
                          }
                          menu[cat.id].modules.push(tempMod);

                          tempModMenu.push(tempMod);
                        }
                      });

                    if (tempModMenu.length) {
                      menuList.push({
                        name: cat.name,
                        isCat: null,
                        id: cat.id,
                        moduleCat: true,
                        icon: cat.icon,
                      });

                      menuList = menuList.concat(tempModMenu);
                    }
                  });

                var tempMenu = {};
                var tempMenuList = [];

                for (var entry in menu) {
                  if (menu[entry].reports.length > 0) {
                    tempMenu[entry] = menu[entry];
                  }
                  if (menu[entry].modules.length > 0) {
                    tempMenu[entry] = menu[entry];
                  }
                }

                $scope.menuData = tempMenu;
                $scope.menuList = menuList;
                $scope.menuDataLength = Object.keys($scope.menuData).length;
                // Cleanup recently viewed and favorites that the user has no permission
                $scope.cleanUpRecentlyViewedAndFavorites();
                // Smart contents
                $scope.getSmartContents();
              }
            });
          });
        });
      }
    };

    $scope.menuClick = function (menuEntry) {
      reportFactory.setSelectedReport(menuEntry);
      $scope.toggleMenu();
      $state.transitionTo($state.current, $stateParams, {
        reload: true,
        inherit: false,
        notify: true,
      });

      $state.go("report", null, {
        reload: true,
      });
    };

    $scope.moduleClick = function (module) {
      $scope.toggleMenu();

      if (module.dest != null) {
        $state.go(module.dest);
      } else {
        $state.go("genericModule", {
          moduleCollection: module.collection,
          moduleValue: module.value,
        });
      }
    };

    $scope.toggleMenu = function () {
      $mdSidenav("left").toggle();
    };

    $scope.menuGroupToggle = function (that) {
      if ($scope.hideCat.indexOf(that.cat.id) === -1) {
        $scope.hideCat.push(that.cat.id);
      } else {
        var i = $scope.hideCat.indexOf(that.cat.id);
        $scope.hideCat.splice(i, 1);
      }
    };

    $scope.showMenuEntry = function (that) {
      return !($scope.hideCat.indexOf(that.entry.category) > -1);
    };

    $scope.logout = function () {
      $scope.client.logout().then(function (data) {
        //$state.go("login");
        window.location = "/#!/login";
        window.location.reload();
      });
    };

    $scope.showLoader = function () {
      $scope.showMainLoader = true;
    };

    $scope.hideLoader = function () {
      $scope.showMainLoader = false;
      $scope.showDeterminateMainLoaderFlag = false;
      $scope.determinateMainLoaderPercentageProgress = 0;
      $scope.determinateMainLoaderTotalItems = 0;
    };

    $scope.showDeterminateLoader = function (totalItems) {
      $scope.showDeterminateMainLoaderFlag = true;
      $scope.determinateMainLoaderPercentageProgress = 0;
      $scope.determinateMainLoaderProcessedItems = 0;
      $scope.determinateMainLoaderTotalItems = totalItems;
    };

    $scope.updateDeterminateLoader = function () {
      $scope.determinateMainLoaderProcessedItems += 1;
      $scope.determinateMainLoaderPercentageProgress =
        Math.round(($scope.determinateMainLoaderProcessedItems / $scope.determinateMainLoaderTotalItems) * 100 * 10) /
        10;
    };

    $scope.hideDeterminateLoader = function () {
      $scope.showDeterminateMainLoaderFlag = false;
      $scope.determinateMainLoaderPercentageProgress = 0;
      $scope.determinateMainLoaderTotalItems = 0;
    };

    $scope.showFileLoader = function () {
      $scope.showFileLoader = true;
    };

    $scope.hideFileLoader = function () {
      $scope.showFileLoader = false;
    };

    $scope.setTitle = function (title) {
      $scope.title = title;
    };

    $scope.log = function (el) {
      el.who = clientsFactory.getUser().user.email;
      el.organization = clientsFactory.getUser().user.organization;

      logsFactory.create(el);
    };

    $scope.alertLoginFailed = function () {
      $mdDialog.show(
        $mdDialog
          .alert()
          .clickOutsideToClose(true)
          .title("")
          .textContent("E-mail ou password inválida. Por favor tente novamente.")
          .ariaLabel("Erro de Login")
          .ok("Ok")
      );
    };

    $scope.successfulSave = function () {
      $mdToast.show({
        template:
          "<md-toast>" +
          '<div class="md-toast-content">' +
          "<p>" +
          $scope.translateText("successfulSave") +
          " " +
          $scope.getRandomSuccessEmoji() +
          "</p></div>" +
          "</md-toast>",
        controllerAs: "toast",
        bindToController: true,
        position: "top right",
        hideDelay: 3000,
        theme: "success-toast",
      });
    };

    $scope.unsuccessfulChange = function () {
      $mdToast.show(
        $mdToast.simple().textContent("Ocorreu um erro.").position("top right").hideDelay(3000).theme("fail-toast")
      );
    };

    $scope.successfulDelete = function () {
      $mdToast.show(
        $mdToast
          .simple()
          .textContent($scope.translateText("successfulDelete"))
          .position("top right")
          .hideDelay(3000)
          .theme("success-toast")
      );
    };

    $scope.invalidTabToSave = function () {
      $mdDialog.show(
        $mdDialog
          .alert()
          .clickOutsideToClose(true)
          .title($scope.translateText("invalidSave"))
          .textContent($scope.translateText("invalidTabToSave"))
          .ariaLabel($scope.translateText("invalidSave"))
          .ok("Ok")
      );
    };

    $scope.duplicatedEntry = function (fields) {
      $mdDialog.show(
        $mdDialog
          .alert()
          .clickOutsideToClose(true)
          .title($scope.translateText("duplicateRecord"))
          .textContent($scope.translateText("duplicateRecordText") + fields + ".")
          .ariaLabel($scope.translateText("duplicateRecordText") + fields + ".")
          .ok("Ok")
      );
    };

    $scope.thenfulFileUpload = function () {
      $mdToast.show(
        $mdToast
          .simple()
          .textContent("Ficheiros carregados com sucesso!")
          .position("top right")
          .hideDelay(3000)
          .theme("success-toast")
      );
    };

    $scope.favoriteAdded = function () {
      var toast = $mdToast
        .simple()
        .textContent($scope.translateText("newAddedFavorite"))
        .action($scope.translateText("view"))
        .highlightAction(true)
        .highlightClass("kstkToastAccent")
        .position("top right")
        .hideDelay(5000);

      $mdToast
        .show(toast)
        .then(function (response) {
          if (response === "ok") {
            $state.go("home");
          } else {
          }
        })
        .catch(function () {});
    };

    $scope.concurrentEditorAlert = function () {
      $mdDialog.show(
        $mdDialog
          .alert()
          .clickOutsideToClose(true)
          .title("Controlo de Edição")
          .textContent(
            "O registo selecionado está a ser editado por outro utilizador. Por favor tente editá-lo mais tarde."
          )
          .ariaLabel(
            "O registo selecionado está a ser editado por outro utilizador. Por favor tente editá-lo mais tarde."
          )
          .ok("Ok")
      );
    };

    $scope.filesHaveMoreThan10MBWarning = function (excludedFiles) {
      $mdDialog.show(
        $mdDialog
          .alert()
          .clickOutsideToClose(true)
          .title("Limite de Espaço")
          .textContent("Apenas é possível carregar ficheiros até 10mb.")
          .ariaLabel("Apenas é possível carregar ficheiros até 10mb.")
          .ok("Ok")
      );
    };

    $scope.storageOverflow = function () {
      $mdToast.show(
        $mdToast
          .simple()
          .textContent("Impossível carregar os ficheiros por falta de espaço.")
          .position("top right")
          .hideDelay(3000)
          .theme("fail-toast")
      );
    };

    $scope.alertRequiredTopFiltersWithoutData = function (requiredTopFields) {
      $mdDialog.show(
        $mdDialog
          .alert()
          .clickOutsideToClose(true)
          .title("Filtros obrigatórios")
          .textContent(
            "Por favor preencha os seguintes filtros para poder consultar informação: " +
              requiredTopFields.toString().replace(/\,/gi, ", ") +
              "."
          )
          .ariaLabel("Filtros obrigatórios")
          .ok("Ok")
      );
    };

    $scope.alertFilteredModuleTab = function () {
      $mdDialog.show(
        $mdDialog
          .alert()
          .clickOutsideToClose(true)
          .title("Consulta de Registos")
          .textContent(
            "Selecione a informação a consultar usando os filtros, e carregue no botão 'Consultar Registos'."
          )
          .ariaLabel("Consultar Registos")
          .ok("Ok")
      );
    };

    $scope.alertVFXEmptyChangeDate = function () {
      $mdDialog.show(
        $mdDialog
          .alert()
          .clickOutsideToClose(true)
          .title("Campo 'Data Alteração Escalão' Vazio")
          .textContent(
            "Visto que preencheu o campo 'Alteração Escalão ASE', deve preencher o campo 'Data Alteração Escalão'."
          )
          .ariaLabel(
            "Visto que preencheu o campo 'Alteração Escalão ASE', deve preencher o campo 'Data Alteração Escalão'."
          )
          .ok("Ok")
      );
    };

    $scope.alertVFXETIUpdateComparticipations = function (numUpdatedRows) {
      $mdDialog.show(
        $mdDialog
          .alert()
          .clickOutsideToClose(true)
          .title("Atualização de Comparticipações")
          .textContent("Foram atualizadas as comparticipações de " + numUpdatedRows + " registos.")
          .ariaLabel("Foram atualizadas as comparticipações de " + numUpdatedRows + " registos.")
          .ok("Ok")
      );
    };

    $scope.alertKSTKMunModulesUpdatedStudentsInfo = function (numUpdatedRows) {
      $mdDialog.show(
        $mdDialog
          .alert()
          .clickOutsideToClose(true)
          .title("Atualização de Informação de Alunos")
          .textContent("Foi atualizada a informação de " + numUpdatedRows + " alunos.")
          .ariaLabel("Foi atualizada a informação de " + numUpdatedRows + " alunos.")
          .ok("Ok")
      );
    };

    $scope.alertNoRowsToImport = function () {
      $mdDialog.show(
        $mdDialog
          .alert()
          .clickOutsideToClose(true)
          .title("Resultado de Importação")
          .textContent("Não existem registos únicos para importação.")
          .ariaLabel("Não existem registos únicos para importação.")
          .ok("Ok")
      );
    };

    $scope.alertFieldsWithoutData = function (fieldsWithoutData) {
      $mdDialog.show(
        $mdDialog
          .alert()
          .clickOutsideToClose(true)
          .title("Colunas sem Informação")
          .textContent(
            "Não existe informação a carregar para as seguintes colunas da tabela destino: " +
              fieldsWithoutData.toString().replace(/\,/gi, ", ") +
              ". Verifique o conteúdo do ficheiro para confirmar se este está correcto."
          )
          .ariaLabel(
            "Não existe informação a carregar para as seguintes colunas da tabela destino: " +
              fieldsWithoutData.toString().replace(/\,/gi, ", ") +
              ". Verifique o conteúdo do ficheiro para confirmar se este está correcto."
          )
          .ok("Ok")
      );
    };

    $scope.toastFinishRowsImport = function (uploadedRows, toUploadRows) {
      $mdToast.show(
        $mdToast
          .simple()
          .textContent(
            "Foram importados " +
              uploadedRows +
              " (de um total de " +
              toUploadRows +
              ")" +
              " registos únicos com sucesso."
          )
          .position("top right")
          .hideDelay(10000)
          .theme("success-toast")
      );
    };

    $scope.vouchersExported = function (numVouchers) {
      $mdToast.show(
        $mdToast
          .simple()
          .textContent("Foram emitidas " + numVouchers + " requisições.")
          .position("top right")
          .hideDelay(3000)
          .theme("success-toast")
      );
    };

    $scope.entriesExported = function (numEntries) {
      $mdToast.show(
        $mdToast
          .simple()
          .textContent(numEntries + " " + $scope.translateText("entriesExportedDialogDesc"))
          .position("top right")
          .hideDelay(3000)
          .theme("success-toast")
      );
    };

    $scope.entriesExportFail = function () {
      $mdToast.show(
        $mdToast
          .simple()
          .textContent("Ocorreu um erro na exportação dos registos.")
          .position("top right")
          .hideDelay(3000)
          .theme("fail-toast")
      );
    };

    $scope.noSeletedEntryToExport = function () {
      $mdDialog.show(
        $mdDialog
          .alert()
          .clickOutsideToClose(true)
          .title("Nenhum registo selecionado")
          .textContent("Para exportar este relatório em formato PDF deve selecionar um registo da tabela.")
          .ariaLabel("Para exportar este relatório em formato PDF deve selecionar um registo da tabela.")
          .ok("Ok")
      );
    };

    $scope.ptEvalResetWarning = function () {
      $mdToast.show(
        $mdToast
          .simple()
          .textContent("Se pretender reverter a eliminação de avaliações basta fechar o formulário sem guardar.")
          .position("top right")
          .hideDelay(10000)
          .theme("fail-toast")
      );
    };

    $scope.ptMeasureResetWarning = function () {
      $mdToast.show(
        $mdToast
          .simple()
          .textContent(
            "Se pretender reverter o recarregamento de medidas atribuídas a alunos basta fechar o formulário sem guardar."
          )
          .position("top right")
          .hideDelay(10000)
          .theme("fail-toast")
      );
    };

    $scope.ptPreviewPanelWithoutData = function () {
      $mdDialog.show(
        $mdDialog
          .alert()
          .clickOutsideToClose(true)
          .title("Grelha Sem Dados")
          .textContent("Não existem dados registados para esta tabela.")
          .ariaLabel("Grelha Sem Dados")
          .ok("Ok")
      );
    };

    $scope.ptExportedPreviewPanel = function (editableFieldLabels) {
      $mdDialog.show(
        $mdDialog
          .alert()
          .clickOutsideToClose(true)
          .title("Grelha Exportada")
          .textContent(
            "Para que volte a importar a grelha exportada, por favor não altere o nome nem o número de colunas. As colunas onde estritamente pode editar/inserir dados são: " +
              editableFieldLabels.toString().replace(/\,/gi, ", ") +
              "."
          )
          .ariaLabel(
            "Para que volte a importar a grelha exportada, por favor não altere o nome nem o número de colunas. As colunas onde estritamente pode editar/inserir dados são: " +
              editableFieldLabels.toString().replace(/\,/gi, ", ") +
              "."
          )
          .ok("Ok")
      );
    };

    $scope.ptPreviewPanelInvalidImportFile = function (fileValidColumns) {
      $mdDialog.show(
        $mdDialog
          .alert()
          .clickOutsideToClose(true)
          .title("Ficheiro com formato inválido")
          .textContent(
            "Por favor valide se o ficheiro a importar não foi alterado quanto ao número e ao nome das colunas. O ficheiro deve ter as seguintes colunas: " +
              fileValidColumns.toString().replace(/\,/gi, ", ") +
              "."
          )
          .ariaLabel(
            "Por favor valide se o ficheiro a importar não foi alterado quanto ao número e ao nome das colunas. O ficheiro deve ter as seguintes colunas: " +
              fileValidColumns.toString().replace(/\,/gi, ", ") +
              "."
          )
          .ok("Ok")
      );
    };

    $scope.alertIncorrectPercentages = function (fieldsIncorrectPercentages) {
      $mdDialog.show(
        $mdDialog
          .alert()
          .clickOutsideToClose(true)
          .title("Percentagens incorrectas")
          .textContent(
            "As percentagens dos seguintes campos não somam 100% ou não estão correctamente atribuídas: " +
              fieldsIncorrectPercentages.toString().replace(/\,/gi, ", ") +
              "."
          )
          .ariaLabel("Campos com percentagens que não somam 100")
          .ok("Ok")
      );
    };

    $scope.alertIPTClientInvalidPercentages = function () {
      $mdDialog.show(
        $mdDialog
          .alert()
          .clickOutsideToClose(true)
          .title("Percentagens incorrectas")
          .textContent("As percentagens atribuídas aos campos de Repartição por Datacenter têm de somar 100%.")
          .ariaLabel("As percentagens atribuídas aos campos de Repartição por Datacenter têm de somar 100%.")
          .ok("Ok")
      );
    };

    $scope.alertIPTAssessmentNotConcluded = function () {
      $mdDialog.show(
        $mdDialog
          .alert()
          .clickOutsideToClose(true)
          .title("Existem linhas por ratear")
          .textContent("Termine o rateio antes de enviar o aviso.")
          .ariaLabel("Termine o rateio antes de enviar o aviso.")
          .ok("Ok")
      );
    };

    $scope.alertIPTAssessmentConcludedSuccessAlert = function () {
      $mdDialog.show(
        $mdDialog
          .alert()
          .clickOutsideToClose(true)
          .title("Aviso enviado")
          .textContent("Foi enviado um aviso de conclusão de rateio à KSTK.")
          .ariaLabel("Foi enviado um aviso de conclusão de rateio à KSTK.")
          .ok("Ok")
      );
    };

    $scope.alertIPTAssessmentConcludedErrorAlert = function () {
      $mdDialog.show(
        $mdDialog
          .alert()
          .clickOutsideToClose(true)
          .title("Aviso não enviado")
          .textContent("Aviso não enviado devido a um erro de servidor, por favor tente mais tarde.")
          .ariaLabel("Aviso não enviado devido a um erro de servidor, por favor tente mais tarde.")
          .ok("Ok")
      );
    };

    $scope.alertCMSBuildingManagementSpaceAreaField = function () {
      $mdDialog.show(
        $mdDialog
          .alert()
          .clickOutsideToClose(true)
          .title("Campo de espaço não preenchido")
          .textContent("Por favor preencha ou o campo 'Espaço' ou o campo 'Outro espaço'.")
          .ariaLabel("Por favor preencha ou o campo 'Espaço' ou o campo 'Outro espaço'.")
          .ok("Ok")
      );
    };

    $scope.alertStudentsAlreadyInVirtualClass = function () {
      $mdDialog.show(
        $mdDialog
          .alert()
          .clickOutsideToClose(true)
          .title("Nenhum aluno a inserir na Turma Virtual")
          .textContent("Os alunos a inserir já estão associados à Turma Virtual selecionada.")
          .ariaLabel("Os alunos a inserir já estão associados à Turma Virtual selecionada.")
          .ok("Ok")
      );
    };

    $scope.alertNoValidStudentsToInsertInVirtualClass = function () {
      $mdDialog.show(
        $mdDialog
          .alert()
          .clickOutsideToClose(true)
          .title("Nenhum aluno a inserir na Turma Virtual")
          .textContent("Os alunos a associar à Turma Virtual não têm NIFs válidos.")
          .ariaLabel("Os alunos a associar à Turma Virtual não têm NIFs válidos.")
          .ok("Ok")
      );
    };

    $scope.alertStudentsInsertedInVirtualClass = function (insertedStudents) {
      $mdDialog.show(
        $mdDialog
          .alert()
          .clickOutsideToClose(true)
          .title("Alunos inseridos na Turma Virtual")
          .textContent("Foram inseridos " + insertedStudents + " alunos na Turma Virtual selecionada.")
          .ariaLabel("Foram inseridos " + insertedStudents + " alunos na Turma Virtual selecionada.")
          .ok("Ok")
      );
    };

    $scope.alertUpdatedClusterCharges = function (numCharges, expenseType) {
      $mdDialog.show(
        $mdDialog
          .alert()
          .clickOutsideToClose(true)
          .title("Encargos Atualizados")
          .textContent(
            "Foram inseridos/atualizados " + numCharges + " registos do tipo de encargo " + expenseType + "."
          )
          .ariaLabel("Foram inseridos/atualizados " + numCharges + " registos do tipo de encargo " + expenseType + ".")
          .ok("Ok")
      );
    };

    $scope.alertInvalidTwelfth = function () {
      $mdDialog.show(
        $mdDialog
          .alert()
          .clickOutsideToClose(true)
          .title("Registo de Duodécimo Inválido")
          .textContent("Preencha por favor os campos desde 'Rubrica' a 'Doc Pago'.")
          .ariaLabel("Preencha por favor os campos desde 'Rubrica' a 'Doc Pago'.")
          .ok("Ok")
      );
    };

    $scope.alertDuplicateInvoicedTwelfth = function () {
      $mdDialog.show(
        $mdDialog
          .alert()
          .clickOutsideToClose(true)
          .title("Fatura Inválida")
          .textContent("Campo 'Nº Doc' duplicado.")
          .ariaLabel("Campo 'Nº Doc' duplicado.")
          .ok("Ok")
      );
    };

    $scope.alertNoClusterChargesToUpdate = function (numCharges, expenseType) {
      $mdDialog.show(
        $mdDialog.alert().clickOutsideToClose(true).title("Nenhum encargo para inserir ou atualizar").ok("Ok")
      );
    };

    $scope.alertPTEssentialLearningInvalidName = function () {
      $mdDialog.show(
        $mdDialog
          .alert()
          .clickOutsideToClose(true)
          .title("Designação de domínio inválida")
          .textContent(
            "A designação do domínio não deve terminar num caractere especial (por exemplo: espaço vazio, ponto final ou ponto e vírgula)."
          )
          .ariaLabel(
            "A designação do domínio não deve terminar num caractere especial (por exemplo: espaço vazio, ponto final ou ponto e vírgula)."
          )
          .ok("Ok")
      );
    };

    $scope.alertPTEvalInstrBlankFields = function () {
      $mdDialog.show(
        $mdDialog
          .alert()
          .clickOutsideToClose(true)
          .title("Campos Vazios")
          .textContent("Existem Domínios ou Ponderações que não estão preenchidas.")
          .ariaLabel("Existem Domínios ou Ponderações que não estão preenchidas.")
          .ok("Ok")
      );
    };

    $scope.alertPTDupliEssenLearn = function () {
      $mdDialog.show(
        $mdDialog
          .alert()
          .clickOutsideToClose(true)
          .title("Domínio Duplicado")
          .textContent("Existem domínios duplicados.")
          .ariaLabel("Existem domínios duplicados.")
          .ok("Ok")
      );
    };

    $scope.alertPTNotSelectedEssenLearn = function () {
      $mdDialog.show(
        $mdDialog
          .alert()
          .clickOutsideToClose(true)
          .title("Domínio Não Escolhido")
          .textContent("Só pode atribuir domínios que escolheu previamente.")
          .ariaLabel("Só pode atribuir domínios que escolheu previamente.")
          .ok("Ok")
      );
    };

    $scope.alertPTInstrInvalidQuestionsDomainTotalWeight = function (
      essentialLearningsWithInvalidQuestionsDomainTotalWeight
    ) {
      $mdDialog.show(
        $mdDialog
          .alert()
          .clickOutsideToClose(true)
          .title("Ponderação Total Inválida por Domínio")
          .textContent(
            "A soma de todas as ponderações dos seguintes domínios atribuídos às questões deve ser 100%: " +
              essentialLearningsWithInvalidQuestionsDomainTotalWeight.toString().replace(/\,/gi, ", ") +
              "."
          )
          .ariaLabel(
            "A soma de todas as ponderações dos seguintes domínios atribuídos às questões deve ser 100%: " +
              essentialLearningsWithInvalidQuestionsDomainTotalWeight.toString().replace(/\,/gi, ", ") +
              "."
          )
          .ok("Ok")
      );
    };

    $scope.alertPTInvaTotalWeight = function () {
      $mdDialog.show(
        $mdDialog
          .alert()
          .clickOutsideToClose(true)
          .title("Ponderação Total Inválida")
          .textContent("A soma de todas as ponderações deve ser 100 (%).")
          .ariaLabel("A soma de todas as ponderações deve ser 100 (%).")
          .ok("Ok")
      );
    };

    $scope.alertPTInvalidBlankEssenLearn = function () {
      $mdDialog.show(
        $mdDialog
          .alert()
          .clickOutsideToClose(true)
          .title("Domínios Inválidos")
          .textContent(
            "Por favor valide os domínios selecionados nos campos 'Domínios de Aprendizagens Essenciais' e 'Questões de Instrumento (associação com domínios)'."
          )
          .ariaLabel(
            "Por favor valide os domínios selecionados nos campos 'Domínios de Aprendizagens Essenciais' e 'Questões de Instrumento (associação com domínios)'."
          )
          .ok("Ok")
      );
    };

    $scope.alertPTEvalInstrZeroWeight = function () {
      $mdDialog.show(
        $mdDialog
          .alert()
          .clickOutsideToClose(true)
          .title("Ponderação Inválida")
          .textContent("As ponderações não devem ser 0.")
          .ariaLabel("As ponderações não devem ser 0.")
          .ok("Ok")
      );
    };

    $scope.alertPTMissingEssenLearnQuestions = function () {
      $mdDialog.show(
        $mdDialog
          .alert()
          .clickOutsideToClose(true)
          .title("Domínio em Falta")
          .textContent("Deve associar cada um dos domínios a pelo menos uma questão.")
          .ariaLabel("Deve associar cada um dos domínios a pelo menos uma questão.")
          .ok("Ok")
      );
    };

    $scope.alertPTFreeQuotationMissingEssenLearnQuestions = function () {
      $mdDialog.show(
        $mdDialog
          .alert()
          .clickOutsideToClose(true)
          .title("Questões em Falta")
          .textContent(
            "Se selecionar a opção 'Instrumento de avaliação com cotação livre por questão' deve associar pelo menos uma questão ao instrumento de avaliação."
          )
          .ariaLabel(
            "Se selecionar a opção 'Instrumento de avaliação com cotação livre por questão' deve associar pelo menos uma questão ao instrumento de avaliação."
          )
          .ok("Ok")
      );
    };

    $scope.alertPTMissingEssenLearnQuestionsWithPerformanceDesc = function () {
      $mdDialog.show(
        $mdDialog
          .alert()
          .clickOutsideToClose(true)
          .title("Questões em Falta")
          .textContent(
            "Se selecionar uma opção no campo 'Rubrica de Avaliação' deve associar pelo menos uma questão ao instrumento de avaliação."
          )
          .ariaLabel(
            "Se selecionar uma opção no campo 'Rubrica de Avaliação' deve associar pelo menos uma questão ao instrumento de avaliação."
          )
          .ok("Ok")
      );
    };

    $scope.alertPTEvalInstrNoSelectedExpField = function () {
      $mdDialog.show(
        $mdDialog
          .alert()
          .clickOutsideToClose(true)
          .title("Nenhum Descritor de Área de Competência Selecionado")
          .textContent("Deve associar pelo menos um descritor de Área de Competência ao instrumento que está a criar.")
          .ariaLabel("Deve associar pelo menos um descritor de Área de Competência ao instrumento que está a criar.")
          .ok("Ok")
      );
    };

    $scope.alertPTEvalInstrNoAttCrit = function () {
      $mdDialog.show(
        $mdDialog
          .alert()
          .clickOutsideToClose(true)
          .title("Instrumento de Avaliação Incompleto")
          .textContent("Deve associar pelo menos um critério de Atitudes e Valores")
          .ariaLabel("Deve associar pelo menos um critério de Atitudes e Valores")
          .ok("Ok")
      );
    };

    $scope.alertPTEvalInstrNoExptFieldAndEssenLearn = function () {
      $mdDialog.show(
        $mdDialog
          .alert()
          .clickOutsideToClose(true)
          .title("Instrumento de Avaliação Incompleto")
          .textContent(
            "Deve associar pelo menos um descritor de Área de Competência ou um Domínio ao instrumento que está a criar."
          )
          .ariaLabel(
            "Deve associar pelo menos um descritor de Área de Competência ou um Domínio ao instrumento que está a criar."
          )
          .ok("Ok")
      );
    };

    $scope.alertPTNoSelectedEssenLearn = function () {
      $mdDialog.show(
        $mdDialog
          .alert()
          .clickOutsideToClose(true)
          .title("Nenhum Domínio Selecionado")
          .textContent("Deve associar pelo menos um domínio.")
          .ariaLabel("Deve associar pelo menos um domínio.")
          .ok("Ok")
      );
    };

    $scope.alertPTEvalCritNoDomains = function () {
      $mdDialog.show(
        $mdDialog
          .alert()
          .clickOutsideToClose(true)
          .title("Nenhum Critério de Avaliação Definido")
          .textContent("Deve associar pelo menos um domínio de Critério de Avaliação.")
          .ariaLabel("Deve associar pelo menos um domínio de Critério de Avaliação.")
          .ok("Ok")
      );
    };

    $scope.alertEmptyRequiredFields = function (emptyRequiredFields) {
      $mdDialog.show(
        $mdDialog
          .alert()
          .clickOutsideToClose(true)
          .title("Campos Obrigatórios Vazios")
          .textContent(
            "Os seguintes campos obrigatórios estão vazios: " +
              emptyRequiredFields.toString().replace(/\,/gi, ", ") +
              "."
          )
          .ariaLabel(
            "Os seguintes campos obrigatórios estão vazios: " +
              emptyRequiredFields.toString().replace(/\,/gi, ", ") +
              "."
          )
          .ok("Ok")
      );
    };

    $scope.alertSubjectCodesAreEmpty = function () {
      $mdDialog.show(
        $mdDialog
          .alert()
          .clickOutsideToClose(true)
          .title("Código em falta")
          .textContent("Por favor preencha um dos seguintes campos: 'Código Disciplina' ou 'Código Disciplina ANQEP'.")
          .ariaLabel("Por favor preencha um dos seguintes campos: 'Código Disciplina' ou 'Código Disciplina ANQEP'.")
          .ok("Ok")
      );
    };

    $scope.alertNIFFieldIsEmpty = function () {
      $mdDialog.show(
        $mdDialog
          .alert()
          .clickOutsideToClose(true)
          .title("Campo 'NIF' Vazio")
          .textContent("O campo 'NIF' está vazio, por favor preencha-o.")
          .ariaLabel("O campo 'NIF' está vazio, por favor preencha-o.")
          .ok("Ok")
      );
    };

    $scope.alertIdDocNISSAlreadyInserted = function (selectedHouseholdNumber, otherHouseholdNumber, caseNumber) {
      $mdDialog.show(
        $mdDialog
          .alert()
          .clickOutsideToClose(true)
          .title("Verificação Nº Doc Identificação/NISS - utente já inserido")
          .textContent(
            "O utente já se encontra inserido na plataforma (elemento agregado familiar nº " +
              selectedHouseholdNumber +
              "). Nº de processo: " +
              caseNumber +
              " (elemento agregado familiar nº " +
              otherHouseholdNumber +
              ")."
          )
          .ariaLabel(
            "O utente já se encontra inserido na plataforma (elemento agregado familiar nº " +
              selectedHouseholdNumber +
              "). Nº de processo: " +
              caseNumber +
              " (elemento agregado familiar nº " +
              otherHouseholdNumber +
              ")."
          )
          .ok("Ok")
      );
    };

    $scope.alertInvalidAEPAHonorBoard = function () {
      $mdDialog.show(
        $mdDialog
          .alert()
          .clickOutsideToClose(true)
          .title("QVE Inválido")
          .textContent(
            "Deve apenas adicionar 1 registo no campo 'Quadro de Valor e Excelência' referente ao 2º semestre."
          )
          .ariaLabel(
            "Deve apenas adicionar 1 registo no campo 'Quadro de Valor e Excelência' referente ao 2º semestre."
          )
          .ok("Ok")
      );
    };

    $scope.alertPTEvalCritEssenLearn = function () {
      $mdDialog.show(
        $mdDialog
          .alert()
          .clickOutsideToClose(true)
          .title("Critério associado aos Domínios")
          .textContent("Deve associar apenas um Critério de Avaliação às ponderações dos Domínios.")
          .ariaLabel("Deve associar apenas um Critério de Avaliação às ponderações dos Domínios.")
          .ok("Ok")
      );
    };

    $scope.alertPTUpdateEvalInstrumentQualitativeLevels = function () {
      $mdDialog.show(
        $mdDialog
          .alert()
          .clickOutsideToClose(true)
          .title("Atualização de Rubrica")
          .textContent("A rubrica associada ao evento de avaliação foi atualizada.")
          .ariaLabel("A rubrica associada ao evento de avaliação foi atualizada.")
          .ok("Ok")
      );
    };

    $scope.alertPTAlreadyInstrEval = function () {
      $mdDialog.show(
        $mdDialog
          .alert()
          .clickOutsideToClose(true)
          .title("Tipo de Avaliação")
          .textContent(
            "Já tem avaliações de instrumento carregadas, não pode proceder a avaliações finais no mesmo formulário."
          )
          .ariaLabel("Só pode escolher um tipo de avaliação, ou de instrumento ou final.")
          .ok("Ok")
      );
    };

    $scope.alertPTMissingEvalCriteria = function () {
      $mdDialog.show(
        $mdDialog
          .alert()
          .clickOutsideToClose(true)
          .title("Grelha de Critérios de Avaliação em Falta")
          .textContent(
            "Não foi definida uma grelha de critérios de avaliação para a disciplina no ano de escolaridade selecionado."
          )
          .ariaLabel(
            "Não foi definida uma grelha de critérios de avaliação para a disciplina no ano de escolaridade selecionado."
          )
          .ok("Ok")
      );
    };

    $scope.alertPTBottomGTTopEvalLimit = function (a, b) {
      $mdDialog.show(
        $mdDialog
          .alert()
          .clickOutsideToClose(true)
          .title("Limite Superior e Inferior")
          .textContent("O limite superior '" + a + "' deve ser maior que o limite inferior '" + b + "'.")
          .ariaLabel("O limite superior '" + a + "' deve ser maior que o limite inferior '" + b + "'.")
          .ok("Ok")
      );
    };

    $scope.alertPTExceedingEvalLimits = function () {
      $mdDialog.show(
        $mdDialog
          .alert()
          .clickOutsideToClose(true)
          .title("Limites Com Valores Incorrectos")
          .textContent("Os valores percentuais dos limites devem estar entre 0 e 100.")
          .ariaLabel("Os valores percentuais dos limites devem estar entre 0 e 100.")
          .ok("Ok")
      );
    };

    $scope.alertPTDuplicateLevel = function () {
      $mdDialog.show(
        $mdDialog
          .alert()
          .clickOutsideToClose(true)
          .title("Níveis de Avaliação Duplicados")
          .textContent("Não pode definir intervalos com o mesmo nível de avaliação associado.")
          .ariaLabel("Não pode definir intervalos com o mesmo nível de avaliação associado.")
          .ok("Ok")
      );
    };

    $scope.alertPTExceedingEvalLevel = function () {
      $mdDialog.show(
        $mdDialog
          .alert()
          .clickOutsideToClose(true)
          .title("Níveis Com Valores Incorrectos")
          .textContent("Os níveis devem estar entre 0 e 5.")
          .ariaLabel("Os níveis devem estar entre 0 e 5.")
          .ok("Ok")
      );
    };

    $scope.alertPTWrongEvalLimitDefinition = function () {
      $mdDialog.show(
        $mdDialog
          .alert()
          .clickOutsideToClose(true)
          .title("Intervalos de Limites Incorrectos")
          .textContent("Deve definir intervalos que não se sobreponham e que cubram todos os valores de 0 a 100.")
          .ariaLabel("Deve definir intervalos que não se sobreponham e que cubram todos os valores de 0 a 100.")
          .ok("Ok")
      );
    };

    $scope.alertPTTransversalMeasureClassNotDir = function () {
      $mdDialog.show(
        $mdDialog
          .alert()
          .clickOutsideToClose(true)
          .title("Grelha de Medidas de Suporte Transversal")
          .textContent(
            "Não pode criar uma grelha de medidas de suporte transversal às disciplinas em turmas da qual não é DT."
          )
          .ariaLabel(
            "Não pode criar uma grelha de medidas de suporte transversal às disciplinas em turmas da qual não é DT."
          )
          .ok("Ok")
      );
    };

    $scope.alertPTMissingEmail = function (studsWithMissingEmails) {
      $mdDialog.show(
        $mdDialog
          .alert()
          .clickOutsideToClose(true)
          .title("Alunos com E-mail em Falta")
          .textContent(
            "Os seguintes alunos têm os e-mails de EEs ou pessoais (necessário se mensagem também for endereçada a alunos) em falta: " +
              studsWithMissingEmails +
              "."
          )
          .ariaLabel(
            "Os seguintes alunos têm os e-mails de EEs ou pessoais (necessário se mensagem também for endereçada a alunos) em falta: " +
              studsWithMissingEmails +
              "."
          )
          .ok("Ok")
      );
    };

    $scope.alertPTCantSendEvalsToManyClasses = function () {
      $mdDialog.show(
        $mdDialog
          .alert()
          .clickOutsideToClose(true)
          .title("Não é possível gerar a comunicação")
          .textContent(
            "Para enviar comunicações relativas a avaliações, só pode selecionar uma turma. Nestes casos, por favor crie uma comunicação por turma."
          )
          .ariaLabel(
            "Para enviar comunicações relativas a avaliações, só pode selecionar uma turma. Nestes casos, por favor crie uma comunicação por turma."
          )
          .ok("Ok")
      );
    };

    $scope.alertPTNoValidParentEmailsToSend = function () {
      $mdDialog.show(
        $mdDialog
          .alert()
          .clickOutsideToClose(true)
          .title("Nenhum E-mail de EE Válido")
          .textContent(
            "Não é possível enviar o(s) e-mail(s) pois o(s) aluno(s) selecionado(s) não tem(têm) e-mail(s) de EE válido(s)."
          )
          .ariaLabel(
            "Não é possível enviar o(s) e-mail(s) pois o(s) aluno(s) selecionado(s) não tem(têm) e-mail(s) de EE válido(s)."
          )
          .ok("Ok")
      );
    };

    $scope.alertPTNoDataToSend = function () {
      $mdDialog.show(
        $mdDialog
          .alert()
          .clickOutsideToClose(true)
          .title("Não existem avaliações para enviar")
          .textContent("Não existem avaliações registadas ou as avaliações existentes ainda não estão completas.")
          .ariaLabel("Não existem avaliações registadas ou as avaliações existentes ainda não estão completas.")
          .ok("Ok")
      );
    };

    $scope.alertPTDocsWithNoData = function (docsWithNoData) {
      $mdDialog.show(
        $mdDialog
          .alert()
          .clickOutsideToClose(true)
          .title("Impossível Exportar Documentos")
          .textContent(
            "Não é possível exportar o(s) seguinte(s) documento(s) " +
              docsWithNoData.sort().toString().replace(/\,/gi, ", ") +
              " pois não existe informação registada relativa ao(s) aluno(s) selecionado(s) no período/semestre selecionado."
          )
          .ariaLabel(
            "Não é possível exportar o(s) seguinte(s) documento(s) " +
              docsWithNoData.sort().toString().replace(/\,/gi, ", ") +
              " pois não existe informação registada relativa ao(s) aluno(s) selecionado(s) no período/semestre selecionado."
          )
          .ok("Ok")
      );
    };

    $scope.alertPTInvalidEmailAfterSend = function (studsWithInvalidEmails) {
      $mdDialog.show(
        $mdDialog
          .alert()
          .clickOutsideToClose(true)
          .title("E-mails Inválidos")
          .textContent(
            "Não foi possível enviar a comunicação para o(s) aluno(s) " +
              studsWithInvalidEmails +
              " devido a e-mails de EEs ou pessoais (necessário se mensagem também for endereçada a alunos) inválidos. Esta situação deve ser corrigida no módulo de administração 'Alunos' para que seja possível proceder ao envio."
          )
          .ariaLabel(
            "Não foi possível enviar a comunicação para o(s) aluno(s) " +
              studsWithInvalidEmails +
              " devido a e-mails de EEs ou pessoais (necessário se mensagem também for endereçada a alunos) inválidos. Esta situação deve ser corrigida no módulo de administração 'Alunos' para que seja possível proceder ao envio."
          )
          .ok("Ok")
      );
    };

    $scope.alertPTSentEmailsWithSuccess = function (sentEmails, totalEmails) {
      $mdDialog.show(
        $mdDialog
          .alert()
          .clickOutsideToClose(true)
          .title("Sucesso no Envio")
          .textContent("E-mail(s) enviado(s) com sucesso: " + sentEmails + " em " + totalEmails + ".")
          .ariaLabel("E-mail(s) enviado(s) com sucesso: " + sentEmails + " em " + totalEmails + ".")
          .ok("Ok")
      );
    };

    $scope.alertPTEvalInstrumentSentEmails = function (sentEmails, totalEmails) {
      $mdDialog.show(
        $mdDialog
          .alert()
          .clickOutsideToClose(true)
          .title("Relatório de envio de e-mails")
          .textContent("E-mail(s) enviado(s) com sucesso: " + sentEmails + " em " + totalEmails + ".")
          .ariaLabel("E-mail(s) enviado(s) com sucesso: " + sentEmails + " em " + totalEmails + ".")
          .ok("Ok")
      );
    };

    $scope.alertPTDownloadedDocs = function () {
      $mdDialog.show(
        $mdDialog
          .alert()
          .clickOutsideToClose(true)
          .title("Documentos Transferidos")
          .textContent("Os documentos a enviar foram transferidos para a sua pasta de Transferências/Downloads.")
          .ariaLabel("Os documentos a enviar foram transferidos para a sua pasta de Transferências/Downloads.")
          .ok("Ok")
      );
    };

    $scope.alertPTNoEmailsWereSentErrors = function () {
      $mdDialog.show(
        $mdDialog
          .alert()
          .clickOutsideToClose(true)
          .title("E-mails Não Enviados")
          .textContent(
            "Não foram enviados e-mails devido a erros associados aos endereços de e-mail dos alunos ou dos EEs."
          )
          .ariaLabel(
            "Não foram enviados e-mails devido a erros associados aos endereços de e-mail dos alunos ou dos EEs."
          )
          .ok("Ok")
      );
    };

    $scope.alertPTProfNoModuleEvalInfo = function () {
      $mdDialog.show(
        $mdDialog
          .alert()
          .clickOutsideToClose(true)
          .title("Avaliações em Falta")
          .textContent("Não existem avaliações em módulos registadas para esta disciplina.")
          .ariaLabel("Não existem avaliações em módulos registadas para esta disciplina.")
          .ok("Ok")
      );
    };

    $scope.alertPTProfNoSubjectEvalInfo = function () {
      $mdDialog.show(
        $mdDialog
          .alert()
          .clickOutsideToClose(true)
          .title("Avaliações em Falta")
          .textContent("Não existem avaliações em disciplinas registadas para este curso profissional.")
          .ariaLabel("Não existem avaliações em disciplinas registadas para este curso profissional.")
          .ok("Ok")
      );
    };

    $scope.alertPTProfCourseMissingCoursePlan = function () {
      $mdDialog.show(
        $mdDialog
          .alert()
          .clickOutsideToClose(true)
          .title("Plano de Curso Profissional em Falta")
          .textContent("Não foi possível obter o Plano de Curso Profissional selecionado.")
          .ariaLabel("Não foi possível obter o Plano de Curso Profissional selecionado.")
          .ok("Ok")
      );
    };

    $scope.alertPTProfSubsMissingCoursePlan = function () {
      $mdDialog.show(
        $mdDialog
          .alert()
          .clickOutsideToClose(true)
          .title("Plano de Curso Profissional em Falta")
          .textContent(
            "Não foi possível obter o Plano de Curso Profissional devido ao 'Ano Curricular' ou 'Ano de Escolaridade' ou à 'Disciplina' selecionada."
          )
          .ariaLabel(
            "Não foi possível obter o Plano de Curso Profissional devido ao 'Ano Curricular' ou 'Ano de Escolaridade' ou à 'Disciplina' selecionada."
          )
          .ok("Ok")
      );
    };

    $scope.alertPermissionsMissingClassDir = function () {
      $mdDialog.show(
        $mdDialog
          .alert()
          .clickOutsideToClose(true)
          .title("Direção de Turma")
          .textContent("Deve associar pelo menos uma turma no campo 'Direção de Turma'.")
          .ariaLabel("Deve associar pelo menos uma turma no campo 'Direção de Turma'.")
          .ok("Ok")
      );
    };

    $scope.alertPermissionsNormProfWithClassDir = function () {
      $mdDialog.show(
        $mdDialog
          .alert()
          .clickOutsideToClose(true)
          .title("Direção de Turma")
          .textContent(
            "Só pode associar turmas no campo 'Direção de Turma' se o perfil 'Diretor de Turma' estiver selecionado."
          )
          .ariaLabel(
            "Só pode associar turmas no campo 'Direção de Turma' se o perfil 'Diretor de Turma' estiver selecionado."
          )
          .ok("Ok")
      );
    };

    $scope.alertPermissionsCreateError = function () {
      $mdDialog.show(
        $mdDialog
          .alert()
          .clickOutsideToClose(true)
          .title("Erro ao Criar Utilizador")
          .textContent("Verifique se já existe um utilizador com o mesmo e-mail ou se definiu uma password.")
          .ariaLabel("Verifique se já existe um utilizador com o mesmo e-mail ou se definiu uma password.")
          .ok("Ok")
      );
    };

    $scope.alertPermissionsModifyError = function () {
      $mdDialog.show(
        $mdDialog
          .alert()
          .clickOutsideToClose(true)
          .title("Erro ao Editar Utilizador")
          .textContent("Verifique se já existe um utilizador com o mesmo e-mail.")
          .ariaLabel("Verifique se já existe um utilizador com o mesmo e-mail.")
          .ok("Ok")
      );
    };

    $scope.alertPermissionsMappingInvalidSelectedItems = function (items) {
      $mdDialog.show(
        $mdDialog
          .alert()
          .clickOutsideToClose(true)
          .title("Mapeamento Inválido")
          .textContent("Assegure que mapeou corretamente " + items + " que selecionou nos 'Campos de Plano de Turma'.")
          .ariaLabel("Assegure que mapeou corretamente " + items + " que selecionou nos 'Campos de Plano de Turma'.")
          .ok("Ok")
      );
    };

    $scope.alertPermissionsCPNoProfile = function (items) {
      $mdDialog.show(
        $mdDialog
          .alert()
          .clickOutsideToClose(true)
          .title("Perfil de Plano de Turma em Falta")
          .textContent("Deve selecionar um Perfil de Plano de Turma.")
          .ariaLabel("Deve selecionar um Perfil de Plano de Turma.")
          .ok("Ok")
      );
    };

    $scope.alertPermissionsCPNoModuleSelected = function (items) {
      $mdDialog.show(
        $mdDialog
          .alert()
          .clickOutsideToClose(true)
          .title("Nenhum Módulo da Plataforma Selecionado")
          .textContent("Selecione os módulos a disponibilizar ao utilizador.")
          .ariaLabel("Selecione os módulos a disponibilizar ao utilizador.")
          .ok("Ok")
      );
    };

    $scope.alertPermissionsEmptyUserRequiredFields = function () {
      $mdDialog.show(
        $mdDialog
          .alert()
          .clickOutsideToClose(true)
          .title("Campos de Plano de Turma em Falta")
          .textContent(
            "Os Campos de Plano de Turma obrigatórios para o perfil 'Professor' são: Ano Letivo, Agrupamento, Escola, Tipo de Curso, Curso, Ano de Escolaridade, Disciplina/Módulo e Turma."
          )
          .ariaLabel(
            "Os Campos de Plano de Turma obrigatórios para o perfil 'Professor' são: Ano Letivo, Agrupamento, Escola, Tipo de Curso, Curso, Ano de Escolaridade, Disciplina/Módulo e Turma."
          )
          .ok("Ok")
      );
    };

    $scope.alertPermissionsEmptyClassDirectorRequiredFields = function () {
      $mdDialog.show(
        $mdDialog
          .alert()
          .clickOutsideToClose(true)
          .title("Campos de Plano de Turma em Falta")
          .textContent(
            "Os Campos de Plano de Turma obrigatórios para o perfil 'Diretor de Turma' são: Ano Letivo, Agrupamento, Escola, Tipo de Curso, Curso, Ano de Escolaridade, Disciplina/Módulo, Turma e Direção de Turma."
          )
          .ariaLabel(
            "Os Campos de Plano de Turma obrigatórios para o perfil 'Diretor de Turma' são: Ano Letivo, Agrupamento, Escola, Tipo de Curso, Curso, Ano de Escolaridade, Disciplina/Módulo, Turma e Direção de Turma."
          )
          .ok("Ok")
      );
    };

    $scope.alertPermissionsEmptySupervisorRequiredFields = function () {
      $mdDialog.show(
        $mdDialog
          .alert()
          .clickOutsideToClose(true)
          .title("Campos de Plano de Turma em Falta")
          .textContent(
            "Os Campos de Plano de Turma obrigatórios para o perfil 'Coordenador' são: Ano Letivo, Agrupamento, Tipo de Curso e Curso."
          )
          .ariaLabel(
            "Os Campos de Plano de Turma obrigatórios para o perfil 'Coordenador' são: Ano Letivo, Agrupamento, Tipo de Curso e Curso."
          )
          .ok("Ok")
      );
    };

    $scope.alertShowTempCPPasswords = function () {
      $mdDialog.show(
        $mdDialog
          .alert()
          .clickOutsideToClose(true)
          .title("Aviso")
          .textContent(
            "A comunicação das passwords temporárias aos utilizadores é da inteira responsabilidade do administrador. Pode selecionar os e-mails e as passwords temporárias com duplo clique."
          )
          .ariaLabel(
            "A comunicação das  temporárias temporárias aos utilizadores é da inteira responsabilidade do administrador. Pode selecionar os e-mails e as passwords temporárias com duplo clique."
          )
          .ok("Ok")
      );
    };

    $scope.alertUserHasNoInstitutionalMail = function () {
      $mdDialog.show(
        $mdDialog
          .alert()
          .clickOutsideToClose(true)
          .title($scope.translateText("Aviso"))
          .textContent($scope.translateText("userHasNoInstitutionalEmailDialogDesc"))
          .ariaLabel($scope.translateText("userHasNoInstitutionalEmailDialogDesc"))
          .ok("Ok")
      );
    };

    $scope.alertUserHasNoNeedToChangePassword = function () {
      $mdDialog.show(
        $mdDialog
          .alert()
          .clickOutsideToClose(true)
          .title("Aviso")
          .textContent(
            "O utilizador selecionado já definiu previamente uma password pessoal. Se desejar alterar esta password, por favor edite o registo."
          )
          .ariaLabel(
            "O utilizador selecionado já definiu previamente uma password pessoal. Se desejar alterar esta password, por favor edite o registo."
          )
          .ok("Ok")
      );
    };

    $scope.welcomeMessageSentSuccessFully = function () {
      $mdToast.show(
        $mdToast
          .simple()
          .textContent($scope.translateText("credentialsSentWithSuccess"))
          .position("top right")
          .hideDelay(3000)
          .theme("success-toast")
      );
    };

    $scope.welcomeMessageNotSent = function () {
      $mdToast.show(
        $mdToast
          .simple()
          .textContent("Ocorreu um erro ao enviar as credenciais.")
          .position("top right")
          .hideDelay(3000)
          .theme("success-toast")
      );
    };

    $scope.alertNoSelectedRow = function () {
      $mdDialog.show(
        $mdDialog
          .alert()
          .clickOutsideToClose(true)
          .title("Nenhum Registo Selecionado")
          .textContent("Por favor selecione primeiro um registo na tabela abaixo.")
          .ariaLabel("Por favor selecione primeiro um registo na tabela abaixo.")
          .ok("Ok")
      );
    };

    $scope.alertCPInvalidSelectedPerformanceLevelDesc = function () {
      $mdDialog.show(
        $mdDialog
          .alert()
          .clickOutsideToClose(true)
          .title("Rubrica Inválida")
          .textContent("Por favor selecione uma Rubrica de Avaliação válida.")
          .ariaLabel("Por favor selecione uma Rubrica de Avaliação válida.")
          .ok("Ok")
      );
    };

    $scope.alertCPValidSelectedPerformanceLevelDesc = function () {
      $mdDialog.show(
        $mdDialog
          .alert()
          .clickOutsideToClose(true)
          .title("Critérios de Avaliação carregados")
          .textContent(
            "Critérios de avaliação carregados com sucesso a partir da Rubrica de Avaliação selecionada. Estes já estão disponíveis para serem associados a questões do Instrumento de Avaliação em causa."
          )
          .ariaLabel(
            "Critérios de avaliação carregados com sucesso a partir da Rubrica de Avaliação selecionada. Estes já estão disponíveis para serem associados a questões do Instrumento de Avaliação em causa."
          )
          .ok("Ok")
      );
    };

    $scope.alertCPInvalidSelectedEvaluationCrits = function () {
      $mdDialog.show(
        $mdDialog
          .alert()
          .clickOutsideToClose(true)
          .title("Grelha de Domínios AEs inválida")
          .textContent(
            "Por favor selecione uma Grelha de Domínios AEs válida. Esta necessita de ter domínios válidos associados."
          )
          .ariaLabel(
            "Por favor selecione uma Grelha de Domínios AEs válida. Esta necessita de ter domínios válidos associados."
          )
          .ok("Ok")
      );
    };

    $scope.alertCPValidSelectedEvaluationCrits = function () {
      $mdDialog.show(
        $mdDialog
          .alert()
          .clickOutsideToClose(true)
          .title("Domínios de AEs carregados")
          .textContent("Domínios de AEs carregados com sucesso a partir da Grelha de Domínios AEs selecionada.")
          .ariaLabel("Domínios de AEs carregados com sucesso a partir da Grelha de Domínios AEs selecionada.")
          .ok("Ok")
      );
    };

    $scope.alertCPInvalidSelectedSubject = function () {
      $mdDialog.show(
        $mdDialog
          .alert()
          .clickOutsideToClose(true)
          .title("Disciplina Inválida")
          .textContent(
            "A disciplina selecionada não é válida. Por favor escolha uma disciplina de acordo com o ano de escolaridade selecionado."
          )
          .ariaLabel(
            "A disciplina selecionada não é válida. Por favor escolha uma disciplina de acordo com o ano de escolaridade selecionado."
          )
          .ok("Ok")
      );
    };

    $scope.alertCPInvalidSelectedEvalCritGridDesc = function () {
      $mdDialog.show(
        $mdDialog
          .alert()
          .clickOutsideToClose(true)
          .title("Grelha Domínios AEs Inválida")
          .textContent(
            "A grelha domínios AEs selecionada não é válida. Por favor escolha uma grelha de acordo com a disciplina e o ano de escolaridade selecionados."
          )
          .ariaLabel(
            "A grelha domínios AEs selecionada não é válida. Por favor escolha uma grelha de acordo com a disciplina e o ano de escolaridade selecionados."
          )
          .ok("Ok")
      );
    };

    $scope.alertCPEssentialLearningRefresh = function () {
      $mdDialog.show(
        $mdDialog
          .alert()
          .clickOutsideToClose(true)
          .title("Domínios de AEs Atualizados")
          .textContent(
            "Os domínios de AEs foram atualizados com sucesso. Por favor valide os domínios selecionados nos campos do registo."
          )
          .ariaLabel(
            "Os domínios de AEs foram atualizados com sucesso. Por favor valide os domínios selecionados nos campos do registo."
          )
          .ok("Ok")
      );
    };

    $scope.alertCAFETIRecordStudentsMissing = function () {
      $mdDialog.show(
        $mdDialog
          .alert()
          .clickOutsideToClose(true)
          .title("Alunos em Falta")
          .textContent("Não foi possível carregar alunos segundo os campos selecionados.")
          .ariaLabel("Não foi possível carregar alunos segundo os campos selecionados.")
          .ok("Ok")
      );
    };

    $scope.alertCAFETIRecordStudentsWithoutETIRecords = function () {
      $mdDialog.show(
        $mdDialog
          .alert()
          .clickOutsideToClose(true)
          .title("Alunos sem registo ETI")
          .textContent("Nenhum dos alunos a carregar tem registo de ETI (Prolongamento e/ou Almoço) ativo.")
          .ariaLabel("Nenhum dos alunos a carregar tem registo de ETI (Prolongamento e/ou Almoço) ativo.")
          .ok("Ok")
      );
    };

    $scope.alertKSTKTransportChargesNoNewChargedMonths = function () {
      $mdDialog.show(
        $mdDialog
          .alert()
          .clickOutsideToClose(true)
          .title("Campo 'Histórico de Utilizações' vazio")
          .textContent(
            "Por favor registe novas utilizações no campo 'Histórico de Utilizações' se desejar replicar estas para outros registos."
          )
          .ariaLabel(
            "Por favor registe novas utilizações no campo 'Histórico de Utilizações' se desejar replicar estas para outros registos."
          )
          .ok("Ok")
      );
    };

    $scope.alertKSTKTransportChargesUpdatedCharges = function (numUpdatedRows) {
      $mdDialog.show(
        $mdDialog
          .alert()
          .clickOutsideToClose(true)
          .title("Replicação de Utilizações")
          .textContent("Foram atualizadas as utilizações de " + numUpdatedRows + " registos.")
          .ariaLabel("Foram atualizadas as utilizações de " + numUpdatedRows + " registos.")
          .ok("Ok")
      );
    };

    $scope.alertCMSUpdatedInvoicesCosts = function (numUpdatedRows) {
      $mdDialog.show(
        $mdDialog
          .alert()
          .clickOutsideToClose(true)
          .title("Faturas importadas e atualizadas")
          .textContent(
            "Foram importadas as faturas do módulo 'Encargos AE/ENA'. As faturas previamente inseridas não foram alteradas."
          )
          .ariaLabel(
            "Foram importadas as faturas do módulo 'Encargos AE/ENA'. As faturas previamente inseridas não foram alteradas."
          )
          .ok("Ok")
      );
    };

    $scope.alertCMSUpdatedBuildingRequests = function (numUpdatedRows) {
      $mdDialog.show(
        $mdDialog
          .alert()
          .clickOutsideToClose(true)
          .title("Requisições importadas e atualizadas")
          .textContent(
            "Foram importadas as requisições do módulo Controlo de Gestão. As requisições previamente inseridas não foram alteradas."
          )
          .ariaLabel(
            "Foram importadas as requisições do módulo Controlo de Gestão. As requisições previamente inseridas não foram alteradas."
          )
          .ok("Ok")
      );
    };

    $scope.alertLeanGameSaveBeforeSendingComplaints = function () {
      $mdDialog.show(
        $mdDialog
          .alert()
          .clickOutsideToClose(true)
          .title("Save before sending complaints")
          .textContent("Please save the record before sending complaints.")
          .ariaLabel("Please save the record before sending complaints.")
          .ok("Ok")
      );
    };

    $scope.alertLeanGameAllComplaintsSent = function () {
      $mdDialog.show(
        $mdDialog
          .alert()
          .clickOutsideToClose(true)
          .title("Complaints already sent")
          .textContent("All selected complaints where already sent.")
          .ariaLabel("All selected complaints where already sent.")
          .ok("Ok")
      );
    };

    $scope.alertLeanGameNumberOfAvailableComplaints = function (availableComplaints) {
      $mdDialog.show(
        $mdDialog
          .alert()
          .clickOutsideToClose(true)
          .title("Check available complaints")
          .textContent(
            "There are " +
              availableComplaints +
              " available complaints. Please change the number of complaints to send."
          )
          .ariaLabel(
            "There are " +
              availableComplaints +
              " available complaints. Please change the number of complaints to send."
          )
          .ok("Ok")
      );
    };

    $scope.alertLeanGameComplaintsSent = function (sentComplaints) {
      $mdDialog.show(
        $mdDialog
          .alert()
          .clickOutsideToClose(true)
          .title("Complaints sent")
          .textContent(sentComplaints + " sent complaints to the corresponding teams.")
          .ariaLabel(sentComplaints + " sent complaints to the corresponding teams.")
          .ok("Ok")
      );
    };

    $scope.alertLeanGameGameFinished = function () {
      $mdDialog.show(
        $mdDialog
          .alert()
          .clickOutsideToClose(true)
          .title("Game already ended")
          .textContent("Please close the record and get ready for the results!")
          .ariaLabel("Please close the record and get ready for the results!")
          .ok("Ok")
      );
    };

    $scope.alertLeanGameRegisteredWrongRole = function () {
      $mdDialog.show(
        $mdDialog
          .alert()
          .clickOutsideToClose(true)
          .title("No permission")
          .textContent(
            "Only players with the 'Employee Customer Service' role can save complaints with the 'Registered' status."
          )
          .ariaLabel(
            "Only players with the 'Employee Customer Service' role can save complaints with the 'Registered' status."
          )
          .ok("Ok")
      );
    };

    $scope.alertLeanGameInternalFeedbackMandatory = function () {
      $mdDialog.show(
        $mdDialog
          .alert()
          .clickOutsideToClose(true)
          .title("Internal feedback is mandatory")
          .textContent(
            "Due to the chosen response category ('Escalated to Board of Directors'), the field 'Internal Feedback' must be filled."
          )
          .ariaLabel(
            "Due to the chosen response category ('Escalated to Board of Directors'), the field 'Internal Feedback' must be filled."
          )
          .ok("Ok")
      );
    };

    $scope.alertLeanGameFeedbackMandatoryWhenClosing = function () {
      $mdDialog.show(
        $mdDialog
          .alert()
          .clickOutsideToClose(true)
          .title("Customer feedback is mandatory")
          .textContent(
            "Before closing the complaint it is necessary to provide feedback to the customer by filling the field 'Customer Feedback'."
          )
          .ariaLabel(
            "Before closing the complaint it is necessary to provide feedback to the customer by filling the field 'Customer Feedback'."
          )
          .ok("Ok")
      );
    };

    $scope.alertMaintenance = function () {
      $mdDialog.show(
        $mdDialog
          .alert()
          .clickOutsideToClose(true)
          .title("Aviso de Manutenção")
          .textContent(
            "Hoje, dia 25 de agosto de 2023, a plataforma KSTK Predictive Mindset estará indisponível das 20h às 23h30, por motivos relacionados com a atualização e otimização de componentes de software. Pedimos desculpa pelos eventuais incómodos."
          )
          .ariaLabel(
            "Hoje, dia 25 de agosto de 2023, a plataforma KSTK Predictive Mindset estará indisponível das 20h às 23h30, por motivos relacionados com a atualização e otimização de componentes de software. Pedimos desculpa pelos eventuais incómodos."
          )
          .ok("Ok")
      );
    };

    $scope.alertPreviewTableInvalidEvalDefault = function () {
      $mdDialog.show(
        $mdDialog
          .alert()
          .clickOutsideToClose(true)
          .title("Avaliação Inválida")
          .textContent("Por favor introduza um valor entre 0 e 200.")
          .ariaLabel("Por favor introduza um valor entre 0 e 200.")
          .ok("Ok")
      );
    };

    $scope.alertPreviewTableInvalidEvalEssenLearnWeight = function (essenLearnWeight) {
      $mdDialog.show(
        $mdDialog
          .alert()
          .clickOutsideToClose(true)
          .title("Avaliação Inválida")
          .textContent("Por favor introduza um valor entre 0 e " + essenLearnWeight + ".")
          .ariaLabel("Por favor introduza um valor entre 0 e " + essenLearnWeight + ".")
          .ok("Ok")
      );
    };

    $scope.getRandomSuccessEmoji = function () {
      let successEmojisArray = [
        "&#127881;",
        "&#127882;",
        "&#128076;",
        "&#128077;",
        "&#128079;",
        "&#128521;",
        "&#128578;",
      ];
      let max = successEmojisArray.length - 1 + 0.4;
      let emojiToReturnIndex = Math.floor(Math.random() * max);
      return successEmojisArray[emojiToReturnIndex];
    };

    (function () {
      var lsAuth = getAuthentication();
      if (lsAuth != null) {
        if (new Date().getTime() < lsAuth.timestamp + lsAuth.ttl * 1000) {
          $http.defaults.headers.common["Authorization"] = lsAuth.value.id;
          clientsFactory.loginCallback();
        } else {
          deleteAuthentication();
        }
      }
    })();
  },
]);
