"use strict";

var app = angular.module("analytics");

app.controller("UpdateClusterChargesPanelCtrl", [
  "$scope",
  "$mdDialog",
  "genericScope",
  "genericFactory",
  function ($scope, $mdDialog, genericScope, genericFactory) {
    var parentScope = genericFactory.getParentScope();
    $scope.genericScope = genericScope;
    $scope.updateConfig = {};

    $scope.updateExpenses = function () {
      if (Array.isArray($scope.updateConfig["civilYear"]) && $scope.updateConfig["civilYear"].length == 0) {
        return null;
      }
      genericScope.parentScope.$parent.showLoader();

      // Get the months to update

      let monthsToUpdate = [];

      for (let y = 0; y < $scope.updateConfig.civilYear.length; y++) {
        let civilYear = $scope.updateConfig.civilYear[y];
        for (let m = 1; m < 13; m++) {
          let refDateMs = new Date(civilYear + "-" + m + "-01").getTime();
          monthsToUpdate.push({
            year: m < 9 ? (Number(civilYear) - 1).toString() : civilYear,
            civilYear: civilYear,
            month: getMonthFromDateMs(refDateMs),
            date: refDateMs,
          });
        }
      }

      // Get the school to update

      let schoolToUpdate = genericScope.getFromTableData("Transp_CMS_Schos").filter((el) => el.ratioSchool == "Sim");

      // Get base rows to insert

      let rowsToInsertUpdate = [];
      let nowMs = new Date().getTime();
      schoolToUpdate.forEach((school) => {
        monthsToUpdate.forEach((month) => {
          let obj = {};
          obj.organization = school.organization;
          obj.year = month.year;
          obj.civilYear = month.civilYear;
          obj.supplyMonth = month.month;
          obj.date = month.date;
          obj.cluster = school.cluster;
          obj.school = school.id;
          obj.educationLevel = school.educationLevel;
          obj.parsedEducationLevel = genericScope.parseValue(
            school.educationLevel,
            "educationLevel",
            "Transp_Education_Levels"
          );
          // Code IGEFE
          if (school.educationLevel != null) {
            let filteredEduLevel = genericScope
              .getFromTableData("Transp_Education_Levels")
              .filter((l) => l.id === school.educationLevel[0]);
            if (filteredEduLevel.length > 0) {
              obj.codeIGEFE = filteredEduLevel[0].codeIGEFE;
            }
          }
          // Rubricas
          obj.supplyInvoiceDateMs = month.date;
          obj.documentType = "Fatura";
          rowsToInsertUpdate.push(obj);
        });
      });

      // Remove rows
      if ($scope.updateConfig.expenseType == "Leite Escolar") {
        let rowsToDelete =
          genericScope.gridOptions.data.length > 0
            ? genericScope.gridOptions.data.filter(
                (el) =>
                  $scope.updateConfig.civilYear.indexOf(el.civilYear) != -1 &&
                  el.chargeType == "6495c47e9de1a0009670b0de" &&
                  el.chargeSubType == "6495c4b89de1a0009670b0e9"
              )
            : [];
        if (rowsToDelete.length > 0) {
          let numDeletedRows = 0;
          async.eachSeries(
            rowsToDelete,
            function iteratee(item, callback) {
              genericFactory.delete(item.id).then((el) => {
                numDeletedRows += 1;
                callback();
              });
            },
            function done() {
              if (numDeletedRows == rowsToDelete.length) {
                continueExpensesUpdate();
              }
            },
            function (err) {
              if (err) {
                console.log(err);
              } else {
                //Done
                console.log("done");
              }
            }
          );
        } else {
          continueExpensesUpdate();
        }
      } else if (
        $scope.updateConfig.expenseType == "PND" &&
        $scope.updateConfig.nonTeachStaffExpenseType == "Despesa"
      ) {
        let rowsToDelete =
          genericScope.gridOptions.data.length > 0
            ? genericScope.gridOptions.data.filter(
                (el) =>
                  ($scope.updateConfig.civilYear.indexOf(el.civilYear) != -1 &&
                    el.chargeType == "64a829ba2bbdd5007cf28d11" &&
                    el.chargeSubType == "64a82a052bbdd5007cf28d14") ||
                  ($scope.updateConfig.civilYear.indexOf(el.civilYear) != -1 &&
                    el.chargeType == "64a829ba2bbdd5007cf28d11" &&
                    el.chargeSubType == "64a82a902bbdd5007cf28d18") ||
                  ($scope.updateConfig.civilYear.indexOf(el.civilYear) != -1 &&
                    el.chargeType == "64a829ba2bbdd5007cf28d11" &&
                    el.chargeSubType == "64a82aa02bbdd5007cf28d19")
              )
            : [];
        if (rowsToDelete.length > 0) {
          let numDeletedRows = 0;
          async.eachSeries(
            rowsToDelete,
            function iteratee(item, callback) {
              genericFactory.delete(item.id).then((el) => {
                numDeletedRows += 1;
                callback();
              });
            },
            function done() {
              if (numDeletedRows == rowsToDelete.length) {
                continueExpensesUpdate();
              }
            },
            function (err) {
              if (err) {
                console.log(err);
              } else {
                //Done
                console.log("done");
              }
            }
          );
        } else {
          continueExpensesUpdate();
        }
      } else if (
        $scope.updateConfig.expenseType == "PND" &&
        $scope.updateConfig.nonTeachStaffExpenseType == "Seguro AT"
      ) {
        let rowsToDelete =
          genericScope.gridOptions.data.length > 0
            ? genericScope.gridOptions.data.filter(
                (el) =>
                  $scope.updateConfig.civilYear.indexOf(el.civilYear) != -1 &&
                  el.chargeType == "64a829ba2bbdd5007cf28d11" &&
                  el.chargeSubType == "64a82aa82bbdd5007cf28d1a"
              )
            : [];
        if (rowsToDelete.length > 0) {
          let numDeletedRows = 0;
          async.eachSeries(
            rowsToDelete,
            function iteratee(item, callback) {
              genericFactory.delete(item.id).then((el) => {
                numDeletedRows += 1;
                callback();
              });
            },
            function done() {
              if (numDeletedRows == rowsToDelete.length) {
                continueExpensesUpdate();
              }
            },
            function (err) {
              if (err) {
                console.log(err);
              } else {
                //Done
                console.log("done");
              }
            }
          );
        } else {
          continueExpensesUpdate();
        }
      } else if (
        $scope.updateConfig.expenseType == "PND" &&
        $scope.updateConfig.nonTeachStaffExpenseType == "Prolongamento"
      ) {
        let rowsToDelete =
          genericScope.gridOptions.data.length > 0
            ? genericScope.gridOptions.data.filter(
                (el) =>
                  $scope.updateConfig.civilYear.indexOf(el.civilYear) != -1 &&
                  el.chargeType == "6495c47e9de1a0009670b0df" &&
                  el.chargeSubType == "6495c4b89de1a0009670b0e3" &&
                  el.expenditureGroup == "6512dc093a4d9203b1f8b542"
              )
            : [];
        if (rowsToDelete.length > 0) {
          let numDeletedRows = 0;
          async.eachSeries(
            rowsToDelete,
            function iteratee(item, callback) {
              genericFactory.delete(item.id).then((el) => {
                numDeletedRows += 1;
                callback();
              });
            },
            function done() {
              if (numDeletedRows == rowsToDelete.length) {
                continueExpensesUpdate();
              }
            },
            function (err) {
              if (err) {
                console.log(err);
              } else {
                //Done
                console.log("done");
              }
            }
          );
        } else {
          continueExpensesUpdate();
        }
      } else {
        continueExpensesUpdate();
      }

      function continueExpensesUpdate() {
        if ($scope.updateConfig.expenseType == "Leite Escolar") {
          for (let l = 0; l < rowsToInsertUpdate.length; ) {
            let row = rowsToInsertUpdate[l];
            if (row.civilYear != null && row.school != null) {
              let schoolMilkValue = getCMSSchoolMonthSupplyCost(
                row,
                genericScope.getFromTableData("School_Milk_CMS_Provisions")
              );

              if (schoolMilkValue != null && !isNaN(Number(schoolMilkValue)) && schoolMilkValue > 0) {
                row.value = schoolMilkValue;
                row.schoolMilkValue = schoolMilkValue;
                // Rubrica Apoios Alimentares
                row.chargeType = "6495c47e9de1a0009670b0de";
                // Sub-rubrica Leite Escolar
                row.chargeSubType = "6495c4b89de1a0009670b0e9";
                l++;
              } else {
                rowsToInsertUpdate.splice(l, 1);
              }
            }
          }
        } else if ($scope.updateConfig.expenseType == "PND") {
          if ($scope.updateConfig.nonTeachStaffExpenseType == "Despesa") {
            for (let l = 0; l < rowsToInsertUpdate.length; ) {
              let row = rowsToInsertUpdate[l];
              /* let rowEdited = false; */
              if (row.parsedRow) {
                break;
              }

              if (row.civilYear != null && row.supplyInvoiceDateMs != null && row.school != null) {
                // PRE
                let valuePrePrimary = getCMSNonTeachingStaffMonthExpenses(
                  row,
                  genericScope.getFromTableData("Non_Teach_Staff_Descriptions"),
                  "prePrimary",
                  "costs",
                  "costsTotal"
                );
                if (valuePrePrimary != null && !isNaN(Number(valuePrePrimary)) && valuePrePrimary > 0) {
                  let copyRow = JSON.parse(JSON.stringify(row));

                  copyRow.value = valuePrePrimary;
                  copyRow.nonTeachingStaffPrePrimary = valuePrePrimary;
                  // Rubrica Pessoal Não Docente
                  copyRow.chargeType = "64a829ba2bbdd5007cf28d11";
                  // Sub-rubrica Pré-Escolar
                  copyRow.chargeSubType = "64a82a052bbdd5007cf28d14";
                  copyRow.parsedRow = true;
                  rowsToInsertUpdate.push(copyRow);
                }

                // Primary
                let valuePrimary = getCMSNonTeachingStaffMonthExpenses(
                  row,
                  genericScope.getFromTableData("Non_Teach_Staff_Descriptions"),
                  "primary",
                  "costs",
                  "costsTotal"
                );
                if (valuePrimary != null && !isNaN(Number(valuePrimary)) && valuePrimary > 0) {
                  let copyRow = JSON.parse(JSON.stringify(row));

                  copyRow.value = valuePrimary;
                  copyRow.nonTeachingStaffPrimary = valuePrimary;
                  // Rubrica Pessoal Não Docente
                  copyRow.chargeType = "64a829ba2bbdd5007cf28d11";
                  // Sub-rubrica 1º Ciclo
                  copyRow.chargeSubType = "64a82a902bbdd5007cf28d18";
                  copyRow.parsedRow = true;
                  rowsToInsertUpdate.push(copyRow);
                }

                // 2 3 SEC

                let valueSecondThirdSec = getCMSNonTeachingStaffMonthExpenses(
                  row,
                  genericScope.getFromTableData("Non_Teach_Staff_Descriptions"),
                  "secondThirdSecondary",
                  "costs",
                  "costsTotal"
                );
                if (valueSecondThirdSec != null && !isNaN(Number(valueSecondThirdSec)) && valueSecondThirdSec > 0) {
                  let copyRow = JSON.parse(JSON.stringify(row));

                  copyRow.value = valueSecondThirdSec;
                  copyRow.nonTeachingStaffSecondThirdSecondary = valueSecondThirdSec;
                  // Rubrica Pessoal Não Docente
                  copyRow.chargeType = "64a829ba2bbdd5007cf28d11";
                  // Sub-rubrica 2º 3º Ciclo
                  copyRow.chargeSubType = "64a82aa02bbdd5007cf28d19";
                  copyRow.parsedRow = true;
                  rowsToInsertUpdate.push(copyRow);
                }
              }

              rowsToInsertUpdate.splice(l, 1);
            }
          } else if ($scope.updateConfig.nonTeachStaffExpenseType == "Seguro AT") {
            for (let l = 0; l < rowsToInsertUpdate.length; ) {
              let row = rowsToInsertUpdate[l];
              let rowEdited = false;

              // All education levels
              if (row.civilYear != null && row.supplyInvoiceDateMs != null && row.school != null) {
                let value = getCMSNonTeachingStaffMonthExpenses(
                  row,
                  genericScope.getFromTableData("Non_Teach_Staff_Descriptions"),
                  null,
                  "insuranceCosts",
                  "insuranceCharge"
                );

                if (value != null && !isNaN(Number(value)) && value > 0) {
                  row.value = value;
                  row.nonTeachingStaffInsurances = value;
                  // Rubrica PND
                  row.chargeType = "64a829ba2bbdd5007cf28d11";
                  // Sub-rubrica Seguros AT
                  row.chargeSubType = "64a82aa82bbdd5007cf28d1a";
                  rowEdited = true;
                }
              }

              if (rowEdited) {
                l++;
              } else {
                rowsToInsertUpdate.splice(l, 1);
              }
            }
          }
          if ($scope.updateConfig.nonTeachStaffExpenseType == "Prolongamento") {
            for (let l = 0; l < rowsToInsertUpdate.length; ) {
              let row = rowsToInsertUpdate[l];
              let rowEdited = false;

              // All education levels
              if (row.civilYear != null && row.supplyInvoiceDateMs != null && row.school != null) {
                let value = getCMSNonTeachingStaffMonthExpenses(
                  row,
                  genericScope.getFromTableData("Non_Teach_Staff_Descriptions"),
                  null,
                  "extensionCosts",
                  "costsTotal",
                  "Extensions"
                );

                if (value != null && !isNaN(Number(value)) && value > 0) {
                  row.value = value;
                  row.nonTeachingStaffInsurances = value;
                  // Rubrica Escola A Tempo Inteiro
                  row.chargeType = "6495c47e9de1a0009670b0df";
                  // Sub-rubrica AAAF
                  row.chargeSubType = "6495c4b89de1a0009670b0e3";
                  // Grupo Despesa Prolongamento
                  row.expenditureGroup = "6512dc093a4d9203b1f8b542";
                  rowEdited = true;
                }
              }

              if (rowEdited) {
                l++;
              } else {
                rowsToInsertUpdate.splice(l, 1);
              }
            }
          }
        }

        // Check for duplicates
        /* let rowsToUpdate = [];

        for (let m = 0; m < rowsToInsertUpdate.length; ) {
          let row = rowsToInsertUpdate[m];
          let filteredDataRow;
          if ($scope.updateConfig.nonTeachStaffExpenseType != "Prolongamento") {
            filteredDataRow = genericScope.gridOptions.data.filter(
              (el) =>
                el.civilYear == row.civilYear &&
                el.school != null &&
                el.school == row.school &&
                el.supplyMonth == row.supplyMonth &&
                el.chargeSubType == row.chargeSubType
            );
          } else {
            filteredDataRow = genericScope.gridOptions.data.filter(
              (el) =>
                el.civilYear == row.civilYear &&
                el.school != null &&
                el.school == row.school &&
                el.supplyMonth == row.supplyMonth &&
                el.chargeSubType == row.chargeSubType &&
                el.expenditureGroup == row.expenditureGroup
            );
          }
          if (filteredDataRow != null && Array.isArray(filteredDataRow) && filteredDataRow.length > 0) {
            filteredDataRow = filteredDataRow[0];
            // Only update rows that have different values
            if (row.value != filteredDataRow.value) {
              filteredDataRow.value = row.value;
              rowsToUpdate.push(filteredDataRow);
            }
            rowsToInsertUpdate.splice(m, 1);
          } else {
            m++;
          }
        } */

        // Concat rows to insert with rows to update
        /* rowsToInsertUpdate = rowsToInsertUpdate.concat(rowsToUpdate); */

        if (rowsToInsertUpdate.length > 0) {
          let numInsertedUpdatedRows = 0;
          async.eachSeries(
            rowsToInsertUpdate,
            function iteratee(item, callback) {
              if (item.id != null) {
                item.modifiedBy = "admin";
                item.modifiedAt = nowMs;
                genericFactory.modify(item.id, item).then((el) => {
                  numInsertedUpdatedRows += 1;
                  callback();
                });
              } else {
                item.createdBy = "admin";
                item.createdAt = nowMs;
                genericFactory.create(item).then((el) => {
                  numInsertedUpdatedRows += 1;
                  callback();
                });
              }
            },
            function done() {
              if (numInsertedUpdatedRows == rowsToInsertUpdate.length) {
                genericScope.closePanel();
                genericScope.parentScope.alertUpdatedClusterCharges(
                  numInsertedUpdatedRows,
                  $scope.updateConfig.expenseType
                );
                genericScope.parentScope.$parent.hideLoader();
                genericScope.getModuleData();
              }
            },
            function (err) {
              if (err) {
                console.log(err);
                genericScope.closePanel();
                genericScope.parentScope.$parent.hideLoader();
                genericScope.getModuleData();
              } else {
                //Done
                console.log("done");
                genericScope.parentScope.$parent.hideLoader();
                genericScope.getModuleData();
              }
            }
          );
        } else {
          genericScope.parentScope.alertNoClusterChargesToUpdate();
          genericScope.parentScope.$parent.hideLoader();
        }
      }
    };
  },
]);
